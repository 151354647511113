import { Grid, Typography } from "@material-ui/core";

interface IconStatProps {
    icon: JSX.Element
    amount: string
    label: string
}

function IconStat({ icon, label, amount }: IconStatProps) {

    return (<Grid item direction="row" justify="center" container alignItems="center">
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
            {icon}
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
            <Typography component="h5" variant="h5" gutterBottom>
                <strong>{amount}</strong>
                {` ${label}`}
            </Typography>
        </Grid>
    </Grid>
    );
}

export default IconStat;
