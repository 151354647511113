import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

interface ContainerWithActionButtonProps {
    main: JSX.Element
    button: JSX.Element
}

function ContainerWithActionButton({ main, button }: ContainerWithActionButtonProps) {
    return <Grid direction="column" container spacing={4} alignItems="center">
        <Grid item xs={12}>
            {main}
        </Grid>
        <Grid item xs={12}>
            {button}
        </Grid>

    </Grid>;
}

export default ContainerWithActionButton;
