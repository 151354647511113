import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { IArticle } from "../../../apis/generated/react";
import { Link } from "@material-ui/core";
import FeedItem from "./FeedItem";

interface ArticleFeedItemProps {
    article: IArticle
}

function ArticleFeedItem({ article }: ArticleFeedItemProps) {
    return <FeedItem
        type={article.__typename}
        title={{ label: article.label, link: { type: 'external', href: article.link } }}
        avatar={{ src: article.avatar }}
        tags={article.tags.map((tag: any) => ({ label: tag.label, avatar: tag.avatar, link: `/${tag.__typename}/${tag.objectiveID}` }))}
        item={<> {article.description} </>}
        dateTime={article.datePublished} />
}

export default ArticleFeedItem;
