import React from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Divider, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        // paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    divider: {
        margin: 5
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      inline: {
        display: 'inline',
      },
      media: {
        height: 200,
      },
  }));


interface MaterialUIContainerProps {
    children: React.ReactNode
    height?: number
}

export default function MaterialUIContainer({ children, height }: MaterialUIContainerProps): JSX.Element {
    
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, height || classes.fixedHeight);


    return (
        <Container maxWidth="lg" className={classes.container}>
           <Paper className={fixedHeightPaper}>
                {children}
            </Paper>
        </Container>
    );
}