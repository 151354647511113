import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router';
import { useGetUsersQuery } from '../../apis/generated/react';
import { IUser } from '../../apis/generated/sdk';
import { gaphqlClientManager } from '../../graphql/clientManager';
import Analyst from './Analyst';
import Leaderboard from './Leaderboard';

export default function Analysts() {
    const [user, setUser] = useState<IUser>(null)

    const { status, data } = useGetUsersQuery(gaphqlClientManager.client, {});

    const navigate = useNavigate()
    let users: IUser[] = []
    if (status === "success") {
        users = (data.queryUser as IUser[])
    }

    return (<>
        <h1> Analysts </h1>
        <Leaderboard analysts={users} selectUser={(u) => {
                setUser(u)
                navigate(`/analysts/${u.objectiveID}`)
            }}/>
        <Routes>
            <Route path="/:userRef" element={<Analyst showScore={false} user={user} />}/>
        </Routes>
        
    </>);
}