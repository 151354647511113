import * as dgraph from 'dgraph-js-http';
import { CONSTANTS } from '../constants';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || CONSTANTS.GRAPHQL_ENDPOINT;
const DGRAPH_CLIENT_KEY = process.env.REACT_APP_DGRAPH_CLIENT_KEY || CONSTANTS.DGRAPH_CLIENT_KEY;

function makeClient(endpoint: string, apiKey: string): dgraph.DgraphClient {
    const clientStub = new dgraph.DgraphClientStub(endpoint);
    const dgraphClient = new dgraph.DgraphClient(clientStub);
    dgraphClient.setSlashApiKey(apiKey);
    return dgraphClient;
}

class DqlClientManager {
    apiKey: string;
    endpoint: string;
    client: dgraph.DgraphClient;

    constructor (apiKey: string, endpoint: string) {
        this.endpoint = endpoint;
        this.apiKey = apiKey;
        this.client = makeClient(endpoint, apiKey)
    }
}

export const dqlClientManager = new DqlClientManager(
    DGRAPH_CLIENT_KEY,
    GRAPHQL_ENDPOINT
);

export { DqlClientManager };
