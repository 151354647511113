import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PollIcon from '@material-ui/icons/Poll';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TodayIcon from '@material-ui/icons/Today';
import Header from './Header';
import Sider from './Sider';
import { UserContext } from '../../providers/UserProvider';
import { Home, HomeOutlined, Person } from '@material-ui/icons';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const MENU_ITEMS = [
  // {
  //     name: 'Home',
  //     key: 'home',
  //     route: '/dashboard/home',
  //     icon: <Home />
  // },
  {
    name: 'Home',
    key: 'home',
    route: '/',
    icon: <HomeOutlined />
  },
  {
    name: 'Events',
    key: 'events',
    route: '/events',
    icon: <EventIcon />
  },
  {
    name: 'Analysts',
    key: 'analysts',
    route: '/analysts',
    icon: <EmojiEventsIcon />
  },
  {
    name: 'Feed',
    key: 'feed',
    route: '/feed',
    icon: <DynamicFeedIcon />
  },
  // {
  //   name: 'Takes',
  //   key: 'takes',
  //   route: '/takes',
  //   icon: <PostAddIcon />
  // },
  {
    name: 'Account',
    key: 'account',
    route: '/user/me',
    icon: <Person />
  },
]

export default function Navigation(): JSX.Element {

  const [open, setOpen] = React.useState(false)
  const { user } = useContext(UserContext);

  return (
    <>
      <Header open={open} expandable={user ? true : false} handleDrawerOpen={() => setOpen(true)} />
      {user && <Sider open={open} menuItems={MENU_ITEMS} handleDrawerClose={() => setOpen(false)} />}
    </>
  );
}