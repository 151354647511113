import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ListItemIcon, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  }
}));

interface SideDrawerProps {
    open: boolean
    handleDrawerClose: () => void
    menuItems: {
      name: string;
      key: string;
      route: string;
      icon: JSX.Element;
    }[]
}



export default function SideDrawer(props: SideDrawerProps): JSX.Element {
  
  const navigate = useNavigate()

  const classes = useStyles();
  const { open, handleDrawerClose, menuItems } = props

  return (
      <Drawer
        variant="permanent"
        // className={classes.drawer}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
            {menuItems.map((val, i) =>  <ListItem key={i} button onClick={() => navigate(val.route)}>
            {val.icon && <ListItemIcon>
                {val.icon}
            </ListItemIcon>}
          <ListItemText primary={val.name} />
        </ListItem>)}
        </List>
      </Drawer>
  );
}