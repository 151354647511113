import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
}));

interface SplitContainerProps {
    left: JSX.Element
    right: JSX.Element
}

function SplitContainer({ left, right }: SplitContainerProps) {
    const classes = useStyles();

    return <Grid direction="row" container className={classes.root} spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
            {left}
        </Grid>
        <Grid item xs={12} md={6}>
            {right}
        </Grid>

    </Grid>;
}

export default SplitContainer;
