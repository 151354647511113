import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gaphqlClientManager } from '../../../graphql/clientManager';
import { IUser, useGetUsersQuery } from '../../../apis/generated/react';
import { getAvatar } from '../../util'; 
// import { ICompetition, IIEntityRef, ITeam, useGetTagsQuery } from '../../apis/generated/react';
// import { gaphqlClientManager } from '../../graphql/clientManager';

interface UserFilterProps {
  setUserIds: (userIds: string[]) => void
  userIds: string[]
}

export default function UserFilter({ setUserIds, userIds }: UserFilterProps) {

  const [query, setQuery] = useState<string>('')

  const { data, status } = useGetUsersQuery(gaphqlClientManager.client, {
    filter: { username: { regexp: `/.*${query}.*/i` } }
  })

  let users: IUser[] = []
  if (status === 'success') {
    users = data.queryUser as IUser[]
  }

  const handleChange = (users: (string | IUser)[]) => {
    const userIds: string[] = []

    users.forEach(user => {
      if (typeof user !== 'string') {
        userIds.push(user.objectiveID)
      } else {
        userIds.push(user)
      }
    })

    setUserIds(userIds)
  }

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="tags-standard"
        options={users}
        onChange={(e, values) => handleChange(values)}
        getOptionLabel={(option) => `${option.username}: ${option.balance}`}
        renderOption={(option) => (
          <React.Fragment>
            {getAvatar(option.avatar, option.__typename, "small")}
            {option.username}: {option.balance}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="User Filter"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      />
    </div>
  );
}