import { Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useGetCompetitionsQuery, ICompetition, useGetLeaguesQuery, ILeague } from "../../apis/generated/react";
import { gaphqlClientManager } from "../../graphql/clientManager";
import { UserContext } from "../../providers/UserProvider";
import DateSelect from "../DateSelect";
import CompetitionPoll from "../Poll/CompetitionPoll";
import { calculatePotentialRewards } from "../util";
import { makeDateFilter } from "../../graphql/helpers";
import LeagueSelect from "../League/LeagueSelect";

export default function EventPollContainer() {


  const [date, setDate] = useState<Date>(new Date());
  const [league, setLeague] = useState<string>('');
  const { user, userPollResponseMap } = useContext(UserContext);
  const { data, status } = useGetCompetitionsQuery(gaphqlClientManager.client, {
    filter: {
      startTime: makeDateFilter(date)
    }, leagueFilter: {
      objectiveID: [league]
    }
  });

  const { data: leagueData, status: leagueStatus } = useGetLeaguesQuery(gaphqlClientManager.client, {});

  let events: ICompetition[] = []
  if (status === 'success') {
    events = (data.queryCompetition as ICompetition[])
  }

  let leagues: ILeague[] = []
  if (leagueStatus === 'success') {
    leagues = (leagueData.queryLeague as ILeague[])
  }

  events.sort((a, b) => {
    if (moment(b.startTime).days() - moment(a.startTime).days() !== 0) {
      return moment(b.startTime).days() - moment(a.startTime).days()
    } else {
      return moment(a.startTime).diff(moment(b.startTime))
    }
  });

  useEffect(() => {
    if (leagueStatus === 'success') {
      if (leagueData.queryLeague.length)
        setLeague(leagueData.queryLeague[0].objectiveID)
    }
  }, [leagueStatus, leagueData]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <LeagueSelect league={league} leagues={leagues} setLeague={setLeague} />
        </Grid>
        <Grid item>
          <DateSelect date={date} setDate={setDate} />
        </Grid>
        <Grid container direction="row" alignItems="center" item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom align="center"> Daily Potential Earnings: {calculatePotentialRewards(Object.values(userPollResponseMap), date)} </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom align="center"> Daily Tokens Available: {events.length * 10} </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom align="center"><strong>Pick Your Winners</strong></Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="flex-start" item xs={12} spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            Home
      </Grid>
          <Grid item xs={4}>
            Away
      </Grid>
        </Grid>

        <Grid item>

          {events.map(comp => <CompetitionPoll
            key={comp.objectiveID}
            competition={comp}
          />)}

          {/* </> */}
          {/* )}  */}
        </Grid>
      </Grid>
    </>)
}