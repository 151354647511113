import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SportsGateway from '../../apis/sports';
import { useQuery } from 'react-query';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

interface EventDetailsProps {
    eventUid: string
}

const teamStats = [
    'fieldGoalPct',
    'fieldGoalsMade-fieldGoalsAttempted',
    'threePointFieldGoalsMade-threePointFieldGoalsAttempted',
    'threePointFieldGoalPct',
    'freeThrowsMade-freeThrowsAttempted',
    'totalRebounds',
    'assists',
    'steals',
    'blocks',
    'turnovers',
    'streak',
    'Last Ten Games',
    'avgPoints',
    'avgRebounds',
    'avgAssists',
    'avgBlocks',
    'avgSteals',
    'avgPointsAgainst'
]

export default function EventDetails({ eventUid }: EventDetailsProps) {
    const classes = useStyles();
    const { data } = useQuery([eventUid], () => SportsGateway.getEventDetails(eventUid))

    if (!data) return null

    const { boxscore, header } = data

    let homeIdx = header?.competitions[0]?.competitors[0]?.homeAway === 'home' ? 0 : 1
    let homeId = header?.competitions[0]?.competitors[homeIdx]?.uid
    const homeTeamIdx = boxscore?.teams.findIndex(team => team.team.uid === homeId)
    const homeTeamStats = boxscore?.teams[homeTeamIdx].statistics.filter(value => teamStats.includes(value.name))
    const awayTeamStats = boxscore?.teams[1 - homeTeamIdx].statistics.filter(value => teamStats.includes(value.name))

    return (
        <Grid container direction="row">
            <Grid item xs={2}>
                {/* <VerticalTabs tabs={['Stats', 'Takes', 'Odds']} setTab={setTab} tab={tab}/> */}
            </Grid>


            <Grid item xs={5}>
                {homeTeamStats.map(stat => <>
                    {stat.label}: &nbsp; <strong> {stat.displayValue} </strong> <br />
                </>)}
            </Grid>
            <Grid item xs={5}>
                {awayTeamStats.map(stat => <>
                    {stat.label}: &nbsp; <strong> {stat.displayValue} </strong> <br />
                </>)}
            </Grid>

            <Grid item xs={1} />


        </Grid>

    );
}
