import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ITeam, useGetLeaguesQuery, useGetTeamsQuery } from '../../apis/generated/react';
import { ILeague } from '../../apis/generated/sdk';
import { gaphqlClientManager } from '../../graphql/clientManager';

export default function LeagueContainer() {

    // const { user: loggedInTeam } = useContext(U)
    // const { loginWithRedirect } = useAuth0()
    const navigate = useNavigate()
    const params = useParams()
    const id = params["*"]    
    const { data, status } = useGetLeaguesQuery(gaphqlClientManager.client, {
        filter: { objectiveID: [id] }
    })

    let league: ILeague = null
    if (status === "success") {
      league = data?.queryLeague[0] as any
    }

    if (!league) return null

    return (<>
      {/* <Avatar alt="Remy Sharp" src={league.avatar} /> */}
      <Typography variant="h2"> {league.label}</Typography>
      <List>
        {league.teams.map(team => <ListItem 
        onClick={() => navigate(`/team/${team.objectiveID}`)}
        button key={team.objectiveID}>
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={team.avatar} />
          </ListItemAvatar>
          <ListItemText primary={team.label} secondary={team.description}/> 
        </ListItem>)}
        {/* {JSON.stringify(league)} */}
      </List>
      {/* <Avatar src={league.avatar} />
      <Typography variant="h2"> {league.label} </Typography>
      <Typography variant="h4"> {league.shortLabel} </Typography>
      {/* <Typography variant="h2"> {league.shortLabel} </Typography> */}
      {/* <Typography variant="h2"> {league.league.label} </Typography>
      <Typography variant="h2"> {league.league.abbreviation} </Typography> */}
      </>
    );
}


