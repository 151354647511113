import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetUsersQuery } from '../../apis/generated/react';
import { IUser } from "../../apis/generated/sdk";
import { gaphqlClientManager } from '../../graphql/clientManager';
import { UserContext } from '../../providers/UserProvider';
import Analyst from '../Analysts/Analyst';

export default function UserContainer() {

    const { user: loggedInUser } = useContext(UserContext)
    const { loginWithRedirect } = useAuth0()
    const params = useParams()
    const id = params["*"]
    const navigate = useNavigate()

    const { data, status } = useGetUsersQuery(gaphqlClientManager.client, {
        filter: { objectiveID: [id] }
    })

    useEffect(() => {
        if (id === 'me') {
            if (loggedInUser) {
                navigate(`/user/${loggedInUser.objectiveID}`)
            } else {
                loginWithRedirect()
            }
        }
    }, [id])

    let user: IUser = null;
    if (status === 'success') {
        if (data.queryUser.length > 0) {
            user = data.queryUser[0] as IUser
        }
    }

    if (user)
        return (<>
            <Analyst user={user} showScore />
        </>
        );
    else return null
}