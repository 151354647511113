import React, { Component, useEffect, useState } from 'react'
import { Button, Chip, createStyles, Grid, IconButton, Link, Paper, Theme, Typography, useTheme } from '@material-ui/core'
import { makeStyles, Avatar } from '@material-ui/core';
import { IOption } from '../../apis/generated/sdk'
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        info: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }),
);

export interface PollOptionProps {
    option: IOption
    selected: boolean
    setOption: (option: IOption) => void
    reward: JSX.Element
    score: number
    record: string
}

export default function PollOption({ option, setOption, selected, reward, score, record }: PollOptionProps) {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <Grid container direction="row" alignItems="center" justify="flex-start" item xs={12} spacing={2}>

            {/* Vote Item 1 */}
            <Grid item xs={12} md={6} className={classes.info} >
                <Chip
                    avatar={option.entity.avatar && <Avatar aria-label="avatar" src={option.entity.avatar} />}
                    label={option.entity.label + ` (${record}) `} onClick={() => navigate(`/team/${option.entity.objectiveID}`)} />
                {/* {makePoints(poll, option, selectedOption)} */}

            </Grid>

            {/* Vote Item 1 */}
            <Grid item md={6} xs={12}>
                <Button
                    fullWidth
                    color={selected ? "secondary" : "default"}
                    variant={selected ? "contained" : "outlined"}
                    onClick={() => setOption(option)}>
                    <>
                        <strong> {score ? `${score}` : ''} </strong>
                &nbsp;
                Vote {option.entity.label.split(" ").reverse()[0]}
                &nbsp;
                        <hr />
                        {reward}
                        {/* {option.entity.avatar && <Avatar aria-label="avatar" src={option.entity.avatar} />} */}
                    </>
                </Button>
            </Grid>
        </Grid>
    )
}