

export const ESPN_SPORT_ID_MAPPING = {
    's:40': {
        'name': 'basketball',
        'leagues': {
            'l:46': 'nba'
        }
    },
    // 's:1': {
    //     'name': 'baseball',
    //     'leagues': {
    //         'l:10': 'mlb'
    //     }
    // }
}

export function getSportsList() {
    const items: [string, string][] = [];
    Object.keys(ESPN_SPORT_ID_MAPPING).forEach((sportKey) => {
        Object.values(ESPN_SPORT_ID_MAPPING[sportKey].leagues).forEach((league) => {
            items.push([ESPN_SPORT_ID_MAPPING[sportKey].name, league as string]);
        })
    })
    return items;
}

/**
 * Returns ESPN API path of form sport/league by parsing ESPN UID value.
 * @param espnUID value to parse into sport/league names
 * @returns ESPN API path for espnUID 
 */
 export function espnUIDToUrlPath(espnUID: string): string {
    const pattern = /(s:\d+)~(l:\d+)/
    const values = espnUID.match(pattern);
    if (values !== null) {
        const sport = ESPN_SPORT_ID_MAPPING[values[1]];
        if (sport !== undefined) {
            const league = sport.leagues[values[2]];
            if (league !== undefined) {
                return `${sport.name}/${league}`;
            }
        }
    }
}