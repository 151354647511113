import React, { createContext, useState } from "react";

type ContextProps = {
    children: React.ReactNode
};

export const DarkStateContext = createContext<{ darkState: boolean, setDarkState: (darkState: boolean) => void }>(null);

const DarkStateProvider = ({ children }: ContextProps) => {
    const [darkState, setDarkState] = useState(false);

    return (
        <DarkStateContext.Provider value={{ darkState, setDarkState }}> {children} </DarkStateContext.Provider>
    );
}

export default DarkStateProvider;