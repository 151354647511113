export const LANDING_CARDS = [{
    title: "Make Predictions",
    description: "Everyday we are creating new polls for you to vote on. Once your comfortable with your prediction, place your vote to lock it in.",
    action: { route: "/events", label: "View Polls" }
},
{
    title: "Post Takes",
    description: "Once you've made your decision, back it up by posting your take and tagging your prediction.",
    action: { route: "/takes", label: "View Takes" }
},
{
    title: "Earn Rewards",
    description: "Wait until game day to see your predictions come true. In the meantime, look over other's takes and make any last minute changes.",
    // action: { route: "/", label: "View Takes" }
},
{
    title: "Raise Your Rank",
    description: "Once the game is over, see how you fared against the competition. Only the best analysts will be featured on our leaderboards.",
    action: { route: "/analysts", label: "View Top Analysts" }
},
{
    title: "Follow Other Analysts",
    description: "Check the leaderboards to find the top analysts. Follow them to access their content and predictions.",
},
{
    title: "Improve Your Game",
    description: "Didn't get the results you wanted? Start making your predictions for upcoming games.",
    action: { route: "/takes", label: "Monitor the Feed" }
},
];

export const ROAD_MAP: {
    title: string;
    description: string;
    subItems: {
        title: string;
        description: string;
    }[];
}[] = [
        {
            title: "Tournaments",
            description: "Pay with tokens fee to enter into a tournament that goes directly into the prize pool.",
            subItems: [{
                title: "Cumulative",
                description: "Weekly tournaments where user pays to enter against other users entered → top  x number of users get distribution of pool money."
            }, {
                title: "Brackets",
                description: "Set number of participants, tournament starts when number of participants is set. Then its H2H elimination (who gets the most points in a day/week/etc moves on)"
            }
            ]
        },
        {
            title: "Wagers",
            description: "Based on existing polls, enter into wagers with other analysts using tokens you've earned.",
            subItems: [{
                title: "Head to Head",
                description: "Propose a wager to the market or call out a specific analyst. Get paid out automatically after the event ends."
            }, {
                title: "Group vs. Group",
                description: "Believe your group is the best? Put them to the test by challenging another group to face-off in a week long battle. May the best group win!"
            }]
        },
        {
            title: "Objective Blog",
            description: "Stay up to date on the future plans of Objective as we strive to change the future of content.",
            subItems: [{
                title: "Token Reward System",
                description: "Here we will reveal the economics of our token system. The system will reward correct predictions, community growth, and be transparent to all."
            }, {
                title: "Path to Decentralization",
                description: "Our goal is to bring this community to the masses, we will explain how we plan to use blockchain technology to build a trustless ecosytsem."
            }]
        }
    ];

export const LANDING_CONFIG = {
    heading: "Objective Sports",
    loopOptions: [
        "Making Predictions",
        "Posting Takes",
        "Earning Tokens",
        // "Raise Your Rank",
        // "Earn Objective Tokens",
        // "Compete with Friends",
        // "Become a Top Analyst",
        // "Sell Your Takes"
    ],
    subHeading: "Earn Tokens with your Sports Knowledge",
    paragraph: "Objective Sports enables people from across the world to vote on sports polls, create and post analysis, and banter with other sports fanatics.",
    timelineItems: [
        {
            title: "Beta-Launch",
            date: "2021-3-14",
            description: "Objective Sports launches Beta."
        },
        {
            title: "NBA Playoffs"
        },
        {
            title: "NHL Playoffs"
        },
        {
            title: "Olympics"
        },
    ]
}

export const WHY_OBJECTIVE = {
    title: "Why Objective Sports?",
    paragraphs: [
        "Our name, Objective Sports, is derived from our mission to provide an objective ranking system that allows users to judge content by the merit, or ability, of the author.",
        "Polls are not 50-50, and we understand that. There are favorites and underdogs. That is why our polls have built in odds based on user voting patterns. The analyst who accurately predicts unlikely events will gain more than those predicting likely events."
    ]
}