import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gaphqlClientManager } from '../../../graphql/clientManager';
import { ITeam, useGetTeamsQuery } from '../../../apis/generated/react';
import { getAvatar } from '../../util';
// import { ICompetition, IIEntityRef, ITeam, useGetTagsQuery } from '../../apis/generated/react';
// import { gaphqlClientManager } from '../../graphql/clientManager';

interface TeamFilterProps {
  setTeamIds: (teamIds: string[]) => void
  teamIds: string[]
}

export default function TeamFilter({ setTeamIds, teamIds }: TeamFilterProps) {

  const [query, setQuery] = useState<string>('')

  const { data, status } = useGetTeamsQuery(gaphqlClientManager.client, {
    filter: { label: { regexp: `/.*${query}.*/i` } }
  })

  let teams: ITeam[] = []
  if (status === 'success') {
    teams = data.queryTeam as ITeam[]
  }

  const handleChange = (teams: (string | ITeam)[]) => {
    const teamIds: string[] = []

    teams.forEach(team => {
      if (typeof team !== 'string') {
        teamIds.push(team.objectiveID)
      } else {
        teamIds.push(team)
      }
    })

    setTeamIds(teamIds)
  }

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="tags-standard"
        options={teams}
        onChange={(e, values) => handleChange(values)}
        getOptionLabel={(option) => `${option.label}: ${option.league.abbreviation}`}
        renderOption={(option) => (
          <React.Fragment>
            {getAvatar(option.avatar, option.__typename, "small")}
            {option.label}: {option.league.abbreviation}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Team Filter"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      />
    </div>
  );
}