import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { IPollResponse } from "../../../apis/generated/react";
import FeedItem from "./FeedItem";

interface PollResponseFeedItemProps {
  pollResponse: IPollResponse
}

function PollResponseFeedItem({ pollResponse }: PollResponseFeedItemProps) {
  return <FeedItem
    type={pollResponse.__typename}
    title={{ label: pollResponse.voter.username, link: { type: 'internal', href: `/user/${pollResponse.voter.objectiveID}` } }}
    avatar={{ src: pollResponse.voter.avatar, link: `/user/${pollResponse.voter.objectiveID}` }}
    tags={pollResponse?.poll?.competition?.competitors?.map(comp => ({ label: comp.entity.label, avatar: comp.entity.avatar, link: `/team/${comp.entity.objectiveID}` }))}
    item={<>
      {pollResponse.voter.username} voted for {pollResponse.vote.entity.label}
    </>}
    dateTime={pollResponse.dateCreated} />
}

export default PollResponseFeedItem;
