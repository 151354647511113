import { Typography } from "@material-ui/core";

interface TextContainerProps {
    title: string
    description: string
}

function TextContainer({ title, description }: TextContainerProps) {

    return <>
        <Typography component="h2" variant="h3" gutterBottom>
            <strong>{title}</strong>
        </Typography>
        <Typography paragraph style={{ fontSize: "1.8rem" }}>
            {description}
        </Typography>
    </>;
}

export default TextContainer;
