import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import { Button, Divider, Typography } from "@material-ui/core";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";

interface FeedFilterProps {
    dateRange: DateRange
    setDateRange: (dr: DateRange) => void
}

function DateFilter({ dateRange, setDateRange }: FeedFilterProps) {
    const [open, setOpen] = React.useState(false);
    const toggle = () => setOpen(!open);

    return <>
        <Button onClick={() => toggle()} color="primary" variant="outlined" fullWidth> Date Filter </Button>
        <Typography align="center">
            {dateRange?.startDate?.getDate() === dateRange?.endDate?.getDate() ?
                dateRange?.startDate?.toDateString()
                : dateRange?.startDate?.toDateString() + "->" + dateRange?.endDate?.toDateString()
            }
        </Typography>
        <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
        />
    </>
}

export default DateFilter;
