import { createMuiTheme, CssBaseline, makeStyles, ThemeProvider } from "@material-ui/core";
import React from "react";
import Navigation from "../Navigation";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useContext } from "react";
import { DarkStateContext } from "../../providers/DarkStateProvider";
import { blue, deepOrange, deepPurple, lightBlue, orange } from "@material-ui/core/colors";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function ApplicationLayout({ children }: { children: React.ReactNode }) {

    const classes = useStyles();

    const { darkState } = useContext(DarkStateContext);
    const palletType = darkState ? "dark" : "light";
    const mainPrimaryColor = darkState ? '#00544a' : lightBlue[700];
    const mainSecondaryColor = darkState ? '#00796b' : lightBlue[400];
    
    const darkTheme = createMuiTheme({
      palette: {
        type: palletType,
        primary: {
          main: mainPrimaryColor
        },
        secondary: {
          main: mainSecondaryColor
        }
      }
    });
  
    return <ThemeProvider theme={darkTheme}>
                  

        <div className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
  
          <Navigation />
          <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
        </MuiPickersUtilsProvider>

      </div>  
      </ThemeProvider>
}