import React, { useContext } from 'react'
import Poll from '.'
import { IPoll, useGetPollsQuery } from '../../apis/generated/react'
import { gaphqlClientManager } from '../../graphql/clientManager'

export interface PollProps {
  pollId: string
}

export default function PollFromId({ pollId }: PollProps) {

  const { data, status } = useGetPollsQuery(gaphqlClientManager.client, {
    filter: { objectiveID: [pollId] }
  }, { enabled: Boolean(pollId) })

  let poll: IPoll = null;
  if (status === 'success') {
    poll = data.queryPoll[0] as IPoll;
  }

  return (
    <Poll poll={poll} />
  )
}