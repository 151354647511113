import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { IPoll } from "../../../apis/generated/react";
import Poll from "../../Poll";
import FeedItem from "./FeedItem";

interface PollFeedItemProps {
  poll: IPoll
}

function PollFeedItem({ poll }: PollFeedItemProps) {
  // return <Grid direction="row" container>
  //   <Poll poll={poll} />
  // </Grid>;

  return <FeedItem
    type={poll.__typename}
    title={{ label: poll.label, link: { type: 'internal', href: `/poll/${poll.objectiveID}` } }}
    avatar={{ src: poll.avatar }}
    item={<Poll poll={poll} />}
    tags={poll.competition.competitors.map(comp => ({ label: comp.entity.label, avatar: comp.entity.avatar, link: `/team/${comp.entity.objectiveID}` }))}
    dateTime={poll.competition.startTime} />
}

export default PollFeedItem;
