import React, { useContext } from "react";
import { useGetExamplePollQuery } from "../../apis/generated/react";
import { gaphqlClientManager } from "../../graphql/clientManager";
import PollFromId from "../Poll/PollFromId";

function FeaturedPolls() {
    const { data, status } = useGetExamplePollQuery(gaphqlClientManager.client, {});
    let pollId = "";
    if (status === "success") {
        pollId = data.queryPoll[0].objectiveID;
    }

    return <PollFromId pollId={pollId} />;
}

export default FeaturedPolls;
