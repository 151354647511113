import moment from 'moment';
import { IGetFeedQueryVariables, IDateTimeRange, IDateTimeFilter } from '../apis/generated/react';

export function getDateTime() {
    return new Date().toISOString();
}

export function convertTime(input: string) {
    return new Date(input).toISOString();
}

/**
 * @param first count of each content type to return
 * @param offset pagination offset
 * @param query query string to search for
 * @param dateRange time period to filter to
 * @param userIDs list of user ids to filter content to
 * @param teamIDs list of team ids to filter content to
 * @param competitionIDs list of competition ids to filter content to
 * @param leagueIDs list of league ids to filter content to
 */
export interface IFeedFilterParams {
    first?: number,
    offset?: number,
    query?: string,
    dateRange?: IDateTimeRange,
    userIDs?: string[],
    teamIDs?: string[],
    competitionIDs?: string[],
    leagueIDs?: string[]
}

export function makeDateFilter(date: Date): IDateTimeFilter {

    date.setHours(0, 0, 0, 0);

    // Set end date to start of next day
    const endDate = new Date(date)
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0, 0, 0, 0);

    return { between: { min: date, max: endDate } };
}

/**
 * Creates an object that can be passed to the GetFeed query to filter to relevant content.
 * @param params parameters to create the filter with
 * @returns filter object constructed using passed variables to pass to GetFeed query
 */
export function makeFeedFilter(params: IFeedFilterParams): IGetFeedQueryVariables {
    const regexQuery = params.query ? `/.*?${params.query}.*?/i` : null;
    let allIDs: string[] = [];
    if (params?.teamIDs?.length) { allIDs = allIDs.concat(params.teamIDs); }
    if (params.competitionIDs?.length) { allIDs = allIDs.concat(params.competitionIDs); }
    if (params.leagueIDs?.length) { allIDs = allIDs.concat(params.leagueIDs); }

    let dateRange: IDateTimeRange = null;
    if (params.dateRange) {
        // Set start date to start of day
        const startDate = new Date(params.dateRange.min);
        startDate.setHours(0, 0, 0, 0);

        // Set end date to start of next day
        const endDate = new Date(params.dateRange.max);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);

        dateRange = { min: startDate, max: endDate };
    }

    let filter: IGetFeedQueryVariables = {
        first: params.first,
        offset: params.offset,
        postFilter: {
            content: { anyoftext: params.query },
            dateCreated: { between: dateRange }
        },
        pollFilter: {
            label: { regexp: regexQuery }
        },
        competitionFilter: {
            objectiveID: params.competitionIDs,
            label: { regexp: regexQuery },
            startTime: { between: dateRange }
        },
        teamFilter: {
            objectiveID: params.teamIDs,
            label: { regexp: regexQuery }
        },
        pollResponseFilter: {
            dateCreated: { between: dateRange }
        },
        tagFilter: {
            objectiveID: allIDs.length > 0 ? allIDs : null,
            label: { regexp: regexQuery }
        },
        articleFilter: params.userIDs ? { label: { regexp: "/$a/" } } : { // impossible regex if user filter is used
            label: { regexp: regexQuery },
            description: { anyoftext: params.query },
            datePublished: { between: dateRange }
        },
        leagueFilter: {
            objectiveID: params.leagueIDs,
            label: { regexp: regexQuery }
        }
    }

    if (params.userIDs?.length) {
        filter.userFilter = {
            objectiveID: params.userIDs
        }
    }

    if (params.teamIDs?.length) {
        filter.teamFilter.objectiveID = params.teamIDs
    }

    if (params.competitionIDs?.length) {
        filter.competitionFilter.objectiveID = params.competitionIDs
    }

    return filter
}