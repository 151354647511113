import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useNavigate } from 'react-router';
import { IUser } from '../../apis/generated/sdk';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface LeaderboardProps {
  analysts: IUser[]
  selectUser: (user: IUser) => void
}

export default function Leaderboard({ analysts, selectUser }: LeaderboardProps) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> Ranking </TableCell>
            <TableCell> Name </TableCell>
            <TableCell align="right"> Total Votes </TableCell>
            <TableCell align="right"> Total Correct </TableCell>
            <TableCell align="right"> Percent Correct </TableCell>
            <TableCell align="right"> Score </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {analysts.map((row, i) => (
            <TableRow
              key={row.username}
              onClick={(event) => selectUser(row)}
              hover>
              <TableCell component="th" scope="row"> {i + 1} </TableCell>
              <TableCell component="th" scope="row"> {row.username} </TableCell>
              <TableCell align="right"> {row.totalVotes} </TableCell>
              <TableCell align="right"> {row.correctVotes} </TableCell>
              <TableCell align="right"> {row.totalVotes != 0 ? (100 * row.correctVotes / row.totalVotes).toFixed(0) + "%" : ""} </TableCell>
              <TableCell align="right"> {row.balance} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
