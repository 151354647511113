import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ITeam, useGetTeamsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Feed from '../Feed';

export default function TeamContainer() {

  // const { user: loggedInTeam } = useContext(U)
  // const { loginWithRedirect } = useAuth0()
  const params = useParams()
  const navigate = useNavigate()
  const id = params["*"]
  const { data, status } = useGetTeamsQuery(gaphqlClientManager.client, {
    filter: { objectiveID: [id] }
  })

  let team: ITeam = null
  if (status === "success") {
    team = data?.queryTeam[0] as any
  }

  // useEffect(() => {
  //     if (id === 'me') {
  //         if (loggedInTeam) {
  //             navigate(`/user/${loggedInTeam.objectiveID}`)
  //         } else {
  //             loginWithRedirect()
  //         }
  //     }
  // }, [id])

  // let user: ITeam = null;
  // if (status === 'success') {
  //     if (data.queryTeam.length > 0) {
  //         user = data.queryTeam[0] as ITeam
  //     }
  // }

  if (!team) return null

  return (

    <Grid direction="column" container spacing={2} justify="center">
      <Grid item xs={12}>
        <Button startIcon={<ArrowBackIosIcon />} onClick={() => navigate(`/league/${team.league.objectiveID}`)}>
          {team.league.abbreviation}
        </Button>
      </Grid>
      <Grid item xs={12} direction="row" container alignItems="center">
        <Grid item xs={2}>
          <Avatar src={team.avatar} style={{ width: "80%", height: "auto" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h2" align="left"> {team.label} </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} direction="row" container alignItems="center">
        <Feed disabledFilters={{
          teamIDs: false
        }}
          defaultFilters={{
            teamIDs: [team.objectiveID]
          }}
        />
      </Grid>



    </Grid>

  );
}


