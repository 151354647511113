import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The DateTime scalar type represents date and time as a string in RFC3339 format.
   * For example: "1985-04-12T23:20:50.52Z" represents 20 minutes and 50.52 seconds after the 23rd hour of April 12th, 1985 in UTC.
   */
  DateTime: any;
  /**
   * The Int64 scalar type represents a signed 64‐bit numeric non‐fractional value.
   * Int64 can represent values in range [-(2^63),(2^63 - 1)].
   */
  Int64: any;
};














export type IAddTransactionPayload = {
  __typename?: 'AddTransactionPayload';
  transaction?: Maybe<Array<Maybe<ITransaction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddTransactionPayloadTransactionArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  user?: Maybe<Array<Maybe<IUser>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteUserPayloadUserArgs = {
  filter?: Maybe<IUserFilter>;
  order?: Maybe<IUserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ISeasonFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  year?: Maybe<IIntFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  has?: Maybe<ISeasonHasFilter>;
  and?: Maybe<Array<Maybe<ISeasonFilter>>>;
  or?: Maybe<Array<Maybe<ISeasonFilter>>>;
  not?: Maybe<ISeasonFilter>;
};

export type IAddPollPayload = {
  __typename?: 'AddPollPayload';
  poll?: Maybe<Array<Maybe<IPoll>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddPollPayloadPollArgs = {
  filter?: Maybe<IPollFilter>;
  order?: Maybe<IPollOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IResultOutcomeAggregateResult = {
  __typename?: 'ResultOutcomeAggregateResult';
  count?: Maybe<Scalars['Int']>;
};

export enum IIEntityOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar'
}

export enum IUserHasFilter {
  IncomingTransactions = 'incomingTransactions',
  OutgoingTransactions = 'outgoingTransactions',
  Balance = 'balance',
  DateCreated = 'dateCreated',
  Username = 'username',
  Email = 'email',
  PollResponses = 'pollResponses',
  TotalVotes = 'totalVotes',
  CorrectVotes = 'correctVotes',
  Posts = 'posts',
  Avatar = 'avatar',
  Settings = 'settings',
  FavoriteTeams = 'favoriteTeams',
  FavoriteLeagues = 'favoriteLeagues'
}

export type ISeasonPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['Int']>;
  espnID?: Maybe<Scalars['Int']>;
  league?: Maybe<ILeagueRef>;
  events?: Maybe<Array<IEventRef>>;
};

export type IUpdateReferralInput = {
  filter: IReferralFilter;
  set?: Maybe<IReferralPatch>;
  remove?: Maybe<IReferralPatch>;
};

export type IStringHashFilter = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IUpdateCompetitorPayload = {
  __typename?: 'UpdateCompetitorPayload';
  competitor?: Maybe<Array<Maybe<ICompetitor>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateCompetitorPayloadCompetitorArgs = {
  filter?: Maybe<ICompetitorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddTeamInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  league: ILeagueRef;
};

export type ICompetitorRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  entity?: Maybe<ITeamRef>;
  home?: Maybe<Scalars['Boolean']>;
  winner?: Maybe<Scalars['Boolean']>;
};

export type IEventPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  season?: Maybe<ISeasonRef>;
  league?: Maybe<ILeagueRef>;
  competitions?: Maybe<Array<ICompetitionRef>>;
};

export type IPollResponsePatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  poll?: Maybe<IPollRef>;
  voter?: Maybe<IUserRef>;
  vote?: Maybe<IOptionRef>;
  correct?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
};

export type IArticle = IIEntity & {
  __typename?: 'Article';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  datePublished?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  link: Scalars['String'];
  objectiveID: Scalars['ID'];
  shortLabel?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<IIEntity>>;
  tagsAggregate?: Maybe<IIEntityAggregateResult>;
};


export type IArticleTagsArgs = {
  filter?: Maybe<IIEntityFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IIEntityOrder>;
};


export type IArticleTagsAggregateArgs = {
  filter?: Maybe<IIEntityFilter>;
};

export type IITransactorPatch = {
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance?: Maybe<Scalars['Float']>;
};

export type IUpdateIEntityInput = {
  filter: IIEntityFilter;
  set?: Maybe<IIEntityPatch>;
  remove?: Maybe<IIEntityPatch>;
};

export type IEventAggregateResult = {
  __typename?: 'EventAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  espnIDMin?: Maybe<Scalars['String']>;
  espnIDMax?: Maybe<Scalars['String']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
  startTimeMin?: Maybe<Scalars['DateTime']>;
  startTimeMax?: Maybe<Scalars['DateTime']>;
};

export type IUpdateCompetitionPayload = {
  __typename?: 'UpdateCompetitionPayload';
  competition?: Maybe<Array<Maybe<ICompetition>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateCompetitionPayloadCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
  order?: Maybe<ICompetitionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddSeasonInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  type: Scalars['Int'];
  espnID: Scalars['Int'];
  espnUID: Scalars['String'];
  league: ILeagueRef;
  events?: Maybe<Array<IEventRef>>;
};

export type IIMetadataOrder = {
  asc?: Maybe<IIMetadataOrderable>;
  desc?: Maybe<IIMetadataOrderable>;
  then?: Maybe<IIMetadataOrder>;
};

export type IUserSettingsRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  user?: Maybe<IUserRef>;
  darkMode?: Maybe<Scalars['Boolean']>;
};

export type IReferral = IIMetadata & {
  __typename?: 'Referral';
  dateCreated: Scalars['DateTime'];
  ipAddress?: Maybe<Scalars['String']>;
  objectiveID: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  user?: Maybe<IUser>;
};


export type IReferralUserArgs = {
  filter?: Maybe<IUserFilter>;
};

export type IUpdateSeasonPayload = {
  __typename?: 'UpdateSeasonPayload';
  season?: Maybe<Array<Maybe<ISeason>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateSeasonPayloadSeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
  order?: Maybe<ISeasonOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddOptionInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated: Scalars['DateTime'];
  poll?: Maybe<IPollRef>;
  entity: ITeamRef;
  outcome: IResultOutcomeRef;
  correct?: Maybe<Scalars['Boolean']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type IITransactorRef = {
  objectiveID: Scalars['ID'];
};

export type IOptionRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  poll?: Maybe<IPollRef>;
  entity?: Maybe<ITeamRef>;
  outcome?: Maybe<IResultOutcomeRef>;
  correct?: Maybe<Scalars['Boolean']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type ITransactionRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  from?: Maybe<IITransactorRef>;
  to?: Maybe<IITransactorRef>;
  amount?: Maybe<Scalars['Float']>;
  type?: Maybe<ITransaction_Type>;
};

export type IUpdateUserInput = {
  filter: IUserFilter;
  set?: Maybe<IUserPatch>;
  remove?: Maybe<IUserPatch>;
};

export type IIMetadata = {
  dateCreated: Scalars['DateTime'];
};

export enum IEventHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  StartTime = 'startTime',
  Season = 'season',
  League = 'league',
  Competitions = 'competitions'
}

export type ILeagueRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  espnUID?: Maybe<Scalars['String']>;
  sport?: Maybe<ISportRef>;
  teams?: Maybe<Array<ITeamRef>>;
  seasons?: Maybe<Array<ISeasonRef>>;
};

export type IUpdatePostInput = {
  filter: IPostFilter;
  set?: Maybe<IPostPatch>;
  remove?: Maybe<IPostPatch>;
};

export type IUpdateResultOutcomeInput = {
  filter: IResultOutcomeFilter;
  set?: Maybe<IResultOutcomePatch>;
  remove?: Maybe<IResultOutcomePatch>;
};

export type ITeamAggregateResult = {
  __typename?: 'TeamAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  espnIDMin?: Maybe<Scalars['String']>;
  espnIDMax?: Maybe<Scalars['String']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
};

export type IUserSettingsAggregateResult = {
  __typename?: 'UserSettingsAggregateResult';
  count?: Maybe<Scalars['Int']>;
};

export enum ICompetitionHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  StartTime = 'startTime',
  Competitors = 'competitors',
  Event = 'event',
  Polls = 'polls',
  Completed = 'completed'
}

export type ISportPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  leagues?: Maybe<Array<ILeagueRef>>;
};

export type ITransactionPatch = {
  dateCreated?: Maybe<Scalars['DateTime']>;
  from?: Maybe<IITransactorRef>;
  to?: Maybe<IITransactorRef>;
  amount?: Maybe<Scalars['Float']>;
  type?: Maybe<ITransaction_Type>;
};

export type IUpdatePollResponsePayload = {
  __typename?: 'UpdatePollResponsePayload';
  pollResponse?: Maybe<Array<Maybe<IPollResponse>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdatePollResponsePayloadPollResponseArgs = {
  filter?: Maybe<IPollResponseFilter>;
  order?: Maybe<IPollResponseOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IContainsFilter = {
  point?: Maybe<IPointRef>;
  polygon?: Maybe<IPolygonRef>;
};

export type IIntersectsFilter = {
  polygon?: Maybe<IPolygonRef>;
  multiPolygon?: Maybe<IMultiPolygonRef>;
};

export type IUpdatePollPayload = {
  __typename?: 'UpdatePollPayload';
  poll?: Maybe<Array<Maybe<IPoll>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdatePollPayloadPollArgs = {
  filter?: Maybe<IPollFilter>;
  order?: Maybe<IPollOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdatePollResponseInput = {
  filter: IPollResponseFilter;
  set?: Maybe<IPollResponsePatch>;
  remove?: Maybe<IPollResponsePatch>;
};

export type IAddOptionPayload = {
  __typename?: 'AddOptionPayload';
  option?: Maybe<Array<Maybe<IOption>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddOptionPayloadOptionArgs = {
  filter?: Maybe<IOptionFilter>;
  order?: Maybe<IOptionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  event?: Maybe<Array<Maybe<IEvent>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateEventPayloadEventArgs = {
  filter?: Maybe<IEventFilter>;
  order?: Maybe<IEventOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IPostOrderable {
  DateCreated = 'dateCreated',
  Content = 'content'
}

export type IArticleOrder = {
  asc?: Maybe<IArticleOrderable>;
  desc?: Maybe<IArticleOrderable>;
  then?: Maybe<IArticleOrder>;
};

export type ITeamOrder = {
  asc?: Maybe<ITeamOrderable>;
  desc?: Maybe<ITeamOrderable>;
  then?: Maybe<ITeamOrder>;
};

export type IUpdateArticleInput = {
  filter: IArticleFilter;
  set?: Maybe<IArticlePatch>;
  remove?: Maybe<IArticlePatch>;
};

export type ICompetitor = {
  __typename?: 'Competitor';
  entity: ITeam;
  home?: Maybe<Scalars['Boolean']>;
  objectiveID: Scalars['ID'];
  winner?: Maybe<Scalars['Boolean']>;
};


export type ICompetitorEntityArgs = {
  filter?: Maybe<ITeamFilter>;
};

export type IUpdateReferralPayload = {
  __typename?: 'UpdateReferralPayload';
  referral?: Maybe<Array<Maybe<IReferral>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateReferralPayloadReferralArgs = {
  filter?: Maybe<IReferralFilter>;
  order?: Maybe<IReferralOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IArticleOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DatePublished = 'datePublished',
  Link = 'link'
}

export type IMutation = {
  __typename?: 'Mutation';
  addArticle?: Maybe<IAddArticlePayload>;
  addCompetition?: Maybe<IAddCompetitionPayload>;
  addCompetitor?: Maybe<IAddCompetitorPayload>;
  addEvent?: Maybe<IAddEventPayload>;
  addLeague?: Maybe<IAddLeaguePayload>;
  addOption?: Maybe<IAddOptionPayload>;
  addPoll?: Maybe<IAddPollPayload>;
  addPost?: Maybe<IAddPostPayload>;
  addReferral?: Maybe<IAddReferralPayload>;
  addResultOutcome?: Maybe<IAddResultOutcomePayload>;
  addSeason?: Maybe<IAddSeasonPayload>;
  addSport?: Maybe<IAddSportPayload>;
  addTeam?: Maybe<IAddTeamPayload>;
  addTransaction?: Maybe<IAddTransactionPayload>;
  addUser?: Maybe<IAddUserPayload>;
  addUserSettings?: Maybe<IAddUserSettingsPayload>;
  changePollResponse?: Maybe<Scalars['Boolean']>;
  deleteArticle?: Maybe<IDeleteArticlePayload>;
  deleteCompetition?: Maybe<IDeleteCompetitionPayload>;
  deleteCompetitor?: Maybe<IDeleteCompetitorPayload>;
  deleteEvent?: Maybe<IDeleteEventPayload>;
  deleteIEntity?: Maybe<IDeleteIEntityPayload>;
  deleteIMetadata?: Maybe<IDeleteIMetadataPayload>;
  deleteITransactor?: Maybe<IDeleteITransactorPayload>;
  deleteLeague?: Maybe<IDeleteLeaguePayload>;
  deleteOption?: Maybe<IDeleteOptionPayload>;
  deletePoll?: Maybe<IDeletePollPayload>;
  deletePost?: Maybe<IDeletePostPayload>;
  deleteReferral?: Maybe<IDeleteReferralPayload>;
  deleteResultOutcome?: Maybe<IDeleteResultOutcomePayload>;
  deleteSeason?: Maybe<IDeleteSeasonPayload>;
  deleteSport?: Maybe<IDeleteSportPayload>;
  deleteTeam?: Maybe<IDeleteTeamPayload>;
  deleteTransaction?: Maybe<IDeleteTransactionPayload>;
  deleteUser?: Maybe<IDeleteUserPayload>;
  deleteUserSettings?: Maybe<IDeleteUserSettingsPayload>;
  newPollResponse?: Maybe<Scalars['Boolean']>;
  removePollResponse?: Maybe<Scalars['Boolean']>;
  updateArticle?: Maybe<IUpdateArticlePayload>;
  updateCompetition?: Maybe<IUpdateCompetitionPayload>;
  updateCompetitor?: Maybe<IUpdateCompetitorPayload>;
  updateEvent?: Maybe<IUpdateEventPayload>;
  updateIEntity?: Maybe<IUpdateIEntityPayload>;
  updateIMetadata?: Maybe<IUpdateIMetadataPayload>;
  updateITransactor?: Maybe<IUpdateITransactorPayload>;
  updateLeague?: Maybe<IUpdateLeaguePayload>;
  updateOption?: Maybe<IUpdateOptionPayload>;
  updatePoll?: Maybe<IUpdatePollPayload>;
  updatePollResponse?: Maybe<IUpdatePollResponsePayload>;
  updatePost?: Maybe<IUpdatePostPayload>;
  updateReferral?: Maybe<IUpdateReferralPayload>;
  updateResultOutcome?: Maybe<IUpdateResultOutcomePayload>;
  updateSeason?: Maybe<IUpdateSeasonPayload>;
  updateSport?: Maybe<IUpdateSportPayload>;
  updateTeam?: Maybe<IUpdateTeamPayload>;
  updateTransaction?: Maybe<IUpdateTransactionPayload>;
  updateUser?: Maybe<IUpdateUserPayload>;
  updateUserSettings?: Maybe<IUpdateUserSettingsPayload>;
};


export type IMutationAddArticleArgs = {
  input: Array<IAddArticleInput>;
};


export type IMutationAddCompetitionArgs = {
  input: Array<IAddCompetitionInput>;
};


export type IMutationAddCompetitorArgs = {
  input: Array<IAddCompetitorInput>;
};


export type IMutationAddEventArgs = {
  input: Array<IAddEventInput>;
};


export type IMutationAddLeagueArgs = {
  input: Array<IAddLeagueInput>;
};


export type IMutationAddOptionArgs = {
  input: Array<IAddOptionInput>;
};


export type IMutationAddPollArgs = {
  input: Array<IAddPollInput>;
};


export type IMutationAddPostArgs = {
  input: Array<IAddPostInput>;
};


export type IMutationAddReferralArgs = {
  input: Array<IAddReferralInput>;
};


export type IMutationAddResultOutcomeArgs = {
  input: Array<IAddResultOutcomeInput>;
};


export type IMutationAddSeasonArgs = {
  input: Array<IAddSeasonInput>;
};


export type IMutationAddSportArgs = {
  input: Array<IAddSportInput>;
};


export type IMutationAddTeamArgs = {
  input: Array<IAddTeamInput>;
};


export type IMutationAddTransactionArgs = {
  input: Array<IAddTransactionInput>;
};


export type IMutationAddUserArgs = {
  input: Array<IAddUserInput>;
};


export type IMutationAddUserSettingsArgs = {
  input: Array<IAddUserSettingsInput>;
};


export type IMutationChangePollResponseArgs = {
  newOptionID: Scalars['ID'];
  pollResponseID: Scalars['ID'];
};


export type IMutationDeleteArticleArgs = {
  filter: IArticleFilter;
};


export type IMutationDeleteCompetitionArgs = {
  filter: ICompetitionFilter;
};


export type IMutationDeleteCompetitorArgs = {
  filter: ICompetitorFilter;
};


export type IMutationDeleteEventArgs = {
  filter: IEventFilter;
};


export type IMutationDeleteIEntityArgs = {
  filter: IIEntityFilter;
};


export type IMutationDeleteIMetadataArgs = {
  filter: IIMetadataFilter;
};


export type IMutationDeleteITransactorArgs = {
  filter: IITransactorFilter;
};


export type IMutationDeleteLeagueArgs = {
  filter: ILeagueFilter;
};


export type IMutationDeleteOptionArgs = {
  filter: IOptionFilter;
};


export type IMutationDeletePollArgs = {
  filter: IPollFilter;
};


export type IMutationDeletePostArgs = {
  filter: IPostFilter;
};


export type IMutationDeleteReferralArgs = {
  filter: IReferralFilter;
};


export type IMutationDeleteResultOutcomeArgs = {
  filter: IResultOutcomeFilter;
};


export type IMutationDeleteSeasonArgs = {
  filter: ISeasonFilter;
};


export type IMutationDeleteSportArgs = {
  filter: ISportFilter;
};


export type IMutationDeleteTeamArgs = {
  filter: ITeamFilter;
};


export type IMutationDeleteTransactionArgs = {
  filter: ITransactionFilter;
};


export type IMutationDeleteUserArgs = {
  filter: IUserFilter;
};


export type IMutationDeleteUserSettingsArgs = {
  filter: IUserSettingsFilter;
};


export type IMutationNewPollResponseArgs = {
  optionID: Scalars['ID'];
  pollID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type IMutationRemovePollResponseArgs = {
  pollResponseID: Scalars['ID'];
};


export type IMutationUpdateArticleArgs = {
  input: IUpdateArticleInput;
};


export type IMutationUpdateCompetitionArgs = {
  input: IUpdateCompetitionInput;
};


export type IMutationUpdateCompetitorArgs = {
  input: IUpdateCompetitorInput;
};


export type IMutationUpdateEventArgs = {
  input: IUpdateEventInput;
};


export type IMutationUpdateIEntityArgs = {
  input: IUpdateIEntityInput;
};


export type IMutationUpdateIMetadataArgs = {
  input: IUpdateIMetadataInput;
};


export type IMutationUpdateITransactorArgs = {
  input: IUpdateITransactorInput;
};


export type IMutationUpdateLeagueArgs = {
  input: IUpdateLeagueInput;
};


export type IMutationUpdateOptionArgs = {
  input: IUpdateOptionInput;
};


export type IMutationUpdatePollArgs = {
  input: IUpdatePollInput;
};


export type IMutationUpdatePollResponseArgs = {
  input: IUpdatePollResponseInput;
};


export type IMutationUpdatePostArgs = {
  input: IUpdatePostInput;
};


export type IMutationUpdateReferralArgs = {
  input: IUpdateReferralInput;
};


export type IMutationUpdateResultOutcomeArgs = {
  input: IUpdateResultOutcomeInput;
};


export type IMutationUpdateSeasonArgs = {
  input: IUpdateSeasonInput;
};


export type IMutationUpdateSportArgs = {
  input: IUpdateSportInput;
};


export type IMutationUpdateTeamArgs = {
  input: IUpdateTeamInput;
};


export type IMutationUpdateTransactionArgs = {
  input: IUpdateTransactionInput;
};


export type IMutationUpdateUserArgs = {
  input: IUpdateUserInput;
};


export type IMutationUpdateUserSettingsArgs = {
  input: IUpdateUserSettingsInput;
};

export type IDeletePollPayload = {
  __typename?: 'DeletePollPayload';
  poll?: Maybe<Array<Maybe<IPoll>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeletePollPayloadPollArgs = {
  filter?: Maybe<IPollFilter>;
  order?: Maybe<IPollOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  team?: Maybe<Array<Maybe<ITeam>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteTeamPayloadTeamArgs = {
  filter?: Maybe<ITeamFilter>;
  order?: Maybe<ITeamOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddSportInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  leagues?: Maybe<Array<ILeagueRef>>;
};

export type IIEntityPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type IArticleFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  datePublished?: Maybe<IDateTimeFilter>;
  link?: Maybe<IStringHashFilter>;
  has?: Maybe<IArticleHasFilter>;
  and?: Maybe<Array<Maybe<IArticleFilter>>>;
  or?: Maybe<Array<Maybe<IArticleFilter>>>;
  not?: Maybe<IArticleFilter>;
};

export type IResultOutcomePatch = {
  win?: Maybe<Scalars['Boolean']>;
};

export type ICompetition = IIEntity & {
  __typename?: 'Competition';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  competitors?: Maybe<Array<ICompetitor>>;
  competitorsAggregate?: Maybe<ICompetitorAggregateResult>;
  completed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  event: IEvent;
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  polls?: Maybe<Array<IPoll>>;
  pollsAggregate?: Maybe<IPollAggregateResult>;
  shortLabel?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
};


export type ICompetitionCompetitorsArgs = {
  filter?: Maybe<ICompetitorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ICompetitionCompetitorsAggregateArgs = {
  filter?: Maybe<ICompetitorFilter>;
};


export type ICompetitionEventArgs = {
  filter?: Maybe<IEventFilter>;
};


export type ICompetitionPollsArgs = {
  filter?: Maybe<IPollFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IPollOrder>;
};


export type ICompetitionPollsAggregateArgs = {
  filter?: Maybe<IPollFilter>;
};

export type IGenerateQueryParams = {
  get?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['Boolean']>;
  aggregate?: Maybe<Scalars['Boolean']>;
};

export type IUpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  post?: Maybe<Array<Maybe<IPost>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdatePostPayloadPostArgs = {
  filter?: Maybe<IPostFilter>;
  order?: Maybe<IPostOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IIEntityOrder = {
  asc?: Maybe<IIEntityOrderable>;
  desc?: Maybe<IIEntityOrderable>;
  then?: Maybe<IIEntityOrder>;
};

export type IIEntityRef = {
  objectiveID: Scalars['ID'];
};

export type IUpdateITransactorInput = {
  filter: IITransactorFilter;
  set?: Maybe<IITransactorPatch>;
  remove?: Maybe<IITransactorPatch>;
};

export type IMultiPolygon = {
  __typename?: 'MultiPolygon';
  polygons: Array<IPolygon>;
};

export type ITransactionAggregateResult = {
  __typename?: 'TransactionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  amountMin?: Maybe<Scalars['Float']>;
  amountMax?: Maybe<Scalars['Float']>;
  amountSum?: Maybe<Scalars['Float']>;
  amountAvg?: Maybe<Scalars['Float']>;
};

export type IPollRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  competition?: Maybe<ICompetitionRef>;
  options?: Maybe<Array<IOptionRef>>;
  totalVotes?: Maybe<Scalars['Int']>;
  votingOpen?: Maybe<Scalars['Boolean']>;
  pollResolved?: Maybe<Scalars['Boolean']>;
  correctOption?: Maybe<IOptionRef>;
  pollResponses?: Maybe<Array<IPollResponseRef>>;
};

export enum IPollOrderable {
  Balance = 'balance',
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  TotalVotes = 'totalVotes'
}

export type IDeleteTransactionPayload = {
  __typename?: 'DeleteTransactionPayload';
  transaction?: Maybe<Array<Maybe<ITransaction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteTransactionPayloadTransactionArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ISeasonAggregateResult = {
  __typename?: 'SeasonAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  yearMin?: Maybe<Scalars['Int']>;
  yearMax?: Maybe<Scalars['Int']>;
  yearSum?: Maybe<Scalars['Int']>;
  yearAvg?: Maybe<Scalars['Float']>;
  startDateMin?: Maybe<Scalars['DateTime']>;
  startDateMax?: Maybe<Scalars['DateTime']>;
  endDateMin?: Maybe<Scalars['DateTime']>;
  endDateMax?: Maybe<Scalars['DateTime']>;
  typeMin?: Maybe<Scalars['Int']>;
  typeMax?: Maybe<Scalars['Int']>;
  typeSum?: Maybe<Scalars['Int']>;
  typeAvg?: Maybe<Scalars['Float']>;
  espnIDMin?: Maybe<Scalars['Int']>;
  espnIDMax?: Maybe<Scalars['Int']>;
  espnIDSum?: Maybe<Scalars['Int']>;
  espnIDAvg?: Maybe<Scalars['Float']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
};

export type IUpdateIEntityPayload = {
  __typename?: 'UpdateIEntityPayload';
  iEntity?: Maybe<Array<Maybe<IIEntity>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateIEntityPayloadIEntityArgs = {
  filter?: Maybe<IIEntityFilter>;
  order?: Maybe<IIEntityOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ICompetitionOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  StartTime = 'startTime'
}

export type IReferralOrder = {
  asc?: Maybe<IReferralOrderable>;
  desc?: Maybe<IReferralOrderable>;
  then?: Maybe<IReferralOrder>;
};

export type IUpdateCompetitorInput = {
  filter: ICompetitorFilter;
  set?: Maybe<ICompetitorPatch>;
  remove?: Maybe<ICompetitorPatch>;
};

export type IAddLeaguePayload = {
  __typename?: 'AddLeaguePayload';
  league?: Maybe<Array<Maybe<ILeague>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddLeaguePayloadLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
  order?: Maybe<ILeagueOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IPolygonGeoFilter = {
  near?: Maybe<INearFilter>;
  within?: Maybe<IWithinFilter>;
  contains?: Maybe<IContainsFilter>;
  intersects?: Maybe<IIntersectsFilter>;
};

export type IUpdateLeaguePayload = {
  __typename?: 'UpdateLeaguePayload';
  league?: Maybe<Array<Maybe<ILeague>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateLeaguePayloadLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
  order?: Maybe<ILeagueOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ICompetitorHasFilter {
  Entity = 'entity',
  Home = 'home',
  Winner = 'winner'
}

export type IITransactorOrder = {
  asc?: Maybe<IITransactorOrderable>;
  desc?: Maybe<IITransactorOrderable>;
  then?: Maybe<IITransactorOrder>;
};

export type IPollResponseRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  poll?: Maybe<IPollRef>;
  voter?: Maybe<IUserRef>;
  vote?: Maybe<IOptionRef>;
  correct?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
};

export type IDeleteCompetitionPayload = {
  __typename?: 'DeleteCompetitionPayload';
  competition?: Maybe<Array<Maybe<ICompetition>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteCompetitionPayloadCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
  order?: Maybe<ICompetitionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateOptionPayload = {
  __typename?: 'UpdateOptionPayload';
  option?: Maybe<Array<Maybe<IOption>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateOptionPayloadOptionArgs = {
  filter?: Maybe<IOptionFilter>;
  order?: Maybe<IOptionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IITransactorHasFilter {
  IncomingTransactions = 'incomingTransactions',
  OutgoingTransactions = 'outgoingTransactions',
  Balance = 'balance'
}

export enum IUserOrderable {
  Balance = 'balance',
  DateCreated = 'dateCreated',
  Username = 'username',
  Email = 'email',
  TotalVotes = 'totalVotes',
  CorrectVotes = 'correctVotes',
  Avatar = 'avatar'
}

export type ICompetitionOrder = {
  asc?: Maybe<ICompetitionOrderable>;
  desc?: Maybe<ICompetitionOrderable>;
  then?: Maybe<ICompetitionOrder>;
};

export type ICompetitorPatch = {
  entity?: Maybe<ITeamRef>;
  home?: Maybe<Scalars['Boolean']>;
  winner?: Maybe<Scalars['Boolean']>;
};

export type IAddUserPayload = {
  __typename?: 'AddUserPayload';
  user?: Maybe<Array<Maybe<IUser>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddUserPayloadUserArgs = {
  filter?: Maybe<IUserFilter>;
  order?: Maybe<IUserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateEventInput = {
  filter: IEventFilter;
  set?: Maybe<IEventPatch>;
  remove?: Maybe<IEventPatch>;
};

export type IUpdateUserSettingsPayload = {
  __typename?: 'UpdateUserSettingsPayload';
  userSettings?: Maybe<Array<Maybe<IUserSettings>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateUserSettingsPayloadUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IPostAggregateResult = {
  __typename?: 'PostAggregateResult';
  count?: Maybe<Scalars['Int']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  contentMin?: Maybe<Scalars['String']>;
  contentMax?: Maybe<Scalars['String']>;
};

export type ITeamPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  league?: Maybe<ILeagueRef>;
};

export type ISport = IIEntity & {
  __typename?: 'Sport';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  label: Scalars['String'];
  leagues?: Maybe<Array<ILeague>>;
  leaguesAggregate?: Maybe<ILeagueAggregateResult>;
  objectiveID: Scalars['ID'];
  shortLabel?: Maybe<Scalars['String']>;
};


export type ISportLeaguesArgs = {
  filter?: Maybe<ILeagueFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ILeagueOrder>;
};


export type ISportLeaguesAggregateArgs = {
  filter?: Maybe<ILeagueFilter>;
};

export enum IHttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

export type IUpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  team?: Maybe<Array<Maybe<ITeam>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateTeamPayloadTeamArgs = {
  filter?: Maybe<ITeamFilter>;
  order?: Maybe<ITeamOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ITransactionOrderable {
  DateCreated = 'dateCreated',
  Amount = 'amount'
}

export type IAddLeagueInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  sport: ISportRef;
  teams?: Maybe<Array<ITeamRef>>;
  seasons?: Maybe<Array<ISeasonRef>>;
};

export type IIEntityFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  has?: Maybe<IIEntityHasFilter>;
  and?: Maybe<Array<Maybe<IIEntityFilter>>>;
  or?: Maybe<Array<Maybe<IIEntityFilter>>>;
  not?: Maybe<IIEntityFilter>;
};

export type IPollResponseOrder = {
  asc?: Maybe<IPollResponseOrderable>;
  desc?: Maybe<IPollResponseOrderable>;
  then?: Maybe<IPollResponseOrder>;
};

export type IPostFilter = {
  dateCreated?: Maybe<IDateTimeFilter>;
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  content?: Maybe<IStringFullTextFilter>;
  has?: Maybe<IPostHasFilter>;
  and?: Maybe<Array<Maybe<IPostFilter>>>;
  or?: Maybe<Array<Maybe<IPostFilter>>>;
  not?: Maybe<IPostFilter>;
};

export type ITeamFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  has?: Maybe<ITeamHasFilter>;
  and?: Maybe<Array<Maybe<ITeamFilter>>>;
  or?: Maybe<Array<Maybe<ITeamFilter>>>;
  not?: Maybe<ITeamFilter>;
};

export enum ITeamOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID'
}

export type IAddCompetitionPayload = {
  __typename?: 'AddCompetitionPayload';
  competition?: Maybe<Array<Maybe<ICompetition>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddCompetitionPayloadCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
  order?: Maybe<ICompetitionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IIEntityAggregateResult = {
  __typename?: 'IEntityAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
};

export enum IIEntityHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar'
}

export enum IPollHasFilter {
  IncomingTransactions = 'incomingTransactions',
  OutgoingTransactions = 'outgoingTransactions',
  Balance = 'balance',
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  Competition = 'competition',
  Options = 'options',
  TotalVotes = 'totalVotes',
  VotingOpen = 'votingOpen',
  PollResolved = 'pollResolved',
  CorrectOption = 'correctOption',
  PollResponses = 'pollResponses'
}

export enum ITeamHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  League = 'league'
}

export type IPollResponse = IIEntity & IIMetadata & {
  __typename?: 'PollResponse';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
  dateCreated: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  poll: IPoll;
  score?: Maybe<Scalars['Float']>;
  shortLabel?: Maybe<Scalars['String']>;
  vote: IOption;
  voter: IUser;
};


export type IPollResponsePollArgs = {
  filter?: Maybe<IPollFilter>;
};


export type IPollResponseVoteArgs = {
  filter?: Maybe<IOptionFilter>;
};


export type IPollResponseVoterArgs = {
  filter?: Maybe<IUserFilter>;
};

export type IMultiPolygonRef = {
  polygons: Array<IPolygonRef>;
};

export type IStringExactFilter = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  between?: Maybe<IStringRange>;
};

export type IOptionAggregateResult = {
  __typename?: 'OptionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  voteCountMin?: Maybe<Scalars['Int']>;
  voteCountMax?: Maybe<Scalars['Int']>;
  voteCountSum?: Maybe<Scalars['Int']>;
  voteCountAvg?: Maybe<Scalars['Float']>;
};

export enum IUserSettingsHasFilter {
  User = 'user',
  DarkMode = 'darkMode'
}

export type IITransactorFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  balance?: Maybe<IFloatFilter>;
  has?: Maybe<IITransactorHasFilter>;
  and?: Maybe<Array<Maybe<IITransactorFilter>>>;
  or?: Maybe<Array<Maybe<IITransactorFilter>>>;
  not?: Maybe<IITransactorFilter>;
};

export type IResultOutcome = {
  __typename?: 'ResultOutcome';
  win: Scalars['Boolean'];
};

export enum ILeagueHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  Sport = 'sport',
  Teams = 'teams',
  Seasons = 'seasons'
}

export enum IPollResponseOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  Score = 'score'
}

export type IReferralFilter = {
  dateCreated?: Maybe<IDateTimeFilter>;
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  ipAddress?: Maybe<IStringExactFilter>;
  source?: Maybe<IStringExactFilter>;
  has?: Maybe<IReferralHasFilter>;
  and?: Maybe<Array<Maybe<IReferralFilter>>>;
  or?: Maybe<Array<Maybe<IReferralFilter>>>;
  not?: Maybe<IReferralFilter>;
};

export type IUpdateTransactionInput = {
  filter: ITransactionFilter;
  set?: Maybe<ITransactionPatch>;
  remove?: Maybe<ITransactionPatch>;
};

export type IUpdateUserSettingsInput = {
  filter: IUserSettingsFilter;
  set?: Maybe<IUserSettingsPatch>;
  remove?: Maybe<IUserSettingsPatch>;
};

export type ICustomHttp = {
  url: Scalars['String'];
  method: IHttpMethod;
  body?: Maybe<Scalars['String']>;
  graphql?: Maybe<Scalars['String']>;
  mode?: Maybe<IMode>;
  forwardHeaders?: Maybe<Array<Scalars['String']>>;
  secretHeaders?: Maybe<Array<Scalars['String']>>;
  introspectionHeaders?: Maybe<Array<Scalars['String']>>;
  skipIntrospection?: Maybe<Scalars['Boolean']>;
};

export type IDeleteLeaguePayload = {
  __typename?: 'DeleteLeaguePayload';
  league?: Maybe<Array<Maybe<ILeague>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteLeaguePayloadLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
  order?: Maybe<ILeagueOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IOptionOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  VoteCount = 'voteCount'
}

export type IPollPatch = {
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  competition?: Maybe<ICompetitionRef>;
  options?: Maybe<Array<IOptionRef>>;
  totalVotes?: Maybe<Scalars['Int']>;
  votingOpen?: Maybe<Scalars['Boolean']>;
  pollResolved?: Maybe<Scalars['Boolean']>;
  correctOption?: Maybe<IOptionRef>;
  pollResponses?: Maybe<Array<IPollResponseRef>>;
};

export type IITransactor = {
  balance: Scalars['Float'];
  incomingTransactions?: Maybe<Array<ITransaction>>;
  incomingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
  objectiveID: Scalars['ID'];
  outgoingTransactions?: Maybe<Array<ITransaction>>;
  outgoingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
};


export type IITransactorIncomingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ITransactionOrder>;
};


export type IITransactorIncomingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IITransactorOutgoingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ITransactionOrder>;
};


export type IITransactorOutgoingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};

export type ISeasonRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['Int']>;
  espnID?: Maybe<Scalars['Int']>;
  espnUID?: Maybe<Scalars['String']>;
  league?: Maybe<ILeagueRef>;
  events?: Maybe<Array<IEventRef>>;
};

export type ITransactionFilter = {
  dateCreated?: Maybe<IDateTimeFilter>;
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  amount?: Maybe<IFloatFilter>;
  type?: Maybe<ITransaction_Type_Hash>;
  has?: Maybe<ITransactionHasFilter>;
  and?: Maybe<Array<Maybe<ITransactionFilter>>>;
  or?: Maybe<Array<Maybe<ITransactionFilter>>>;
  not?: Maybe<ITransactionFilter>;
};

export type IUserRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance?: Maybe<Scalars['Float']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pollResponses?: Maybe<Array<IPollResponseRef>>;
  totalVotes?: Maybe<Scalars['Int']>;
  correctVotes?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<IPostRef>>;
  avatar?: Maybe<Scalars['String']>;
  settings?: Maybe<IUserSettingsRef>;
  favoriteTeams?: Maybe<Array<ITeamRef>>;
  favoriteLeagues?: Maybe<Array<ILeagueRef>>;
};

export type IPoint = {
  __typename?: 'Point';
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type ISportAggregateResult = {
  __typename?: 'SportAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  espnIDMin?: Maybe<Scalars['String']>;
  espnIDMax?: Maybe<Scalars['String']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
};

export enum IEventOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  StartTime = 'startTime'
}

export enum IPollResponseHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  Poll = 'poll',
  Voter = 'voter',
  Vote = 'vote',
  Correct = 'correct',
  Score = 'score'
}

export type IOptionPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  poll?: Maybe<IPollRef>;
  entity?: Maybe<ITeamRef>;
  outcome?: Maybe<IResultOutcomeRef>;
  correct?: Maybe<Scalars['Boolean']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type ISportOrder = {
  asc?: Maybe<ISportOrderable>;
  desc?: Maybe<ISportOrderable>;
  then?: Maybe<ISportOrder>;
};

export type IIntRange = {
  min: Scalars['Int'];
  max: Scalars['Int'];
};


export type IFloatRange = {
  min: Scalars['Float'];
  max: Scalars['Float'];
};

export type IPointGeoFilter = {
  near?: Maybe<INearFilter>;
  within?: Maybe<IWithinFilter>;
};

export type IArticleAggregateResult = {
  __typename?: 'ArticleAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  datePublishedMin?: Maybe<Scalars['DateTime']>;
  datePublishedMax?: Maybe<Scalars['DateTime']>;
  linkMin?: Maybe<Scalars['String']>;
  linkMax?: Maybe<Scalars['String']>;
};

export type IDeleteSeasonPayload = {
  __typename?: 'DeleteSeasonPayload';
  season?: Maybe<Array<Maybe<ISeason>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteSeasonPayloadSeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
  order?: Maybe<ISeasonOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteUserSettingsPayload = {
  __typename?: 'DeleteUserSettingsPayload';
  userSettings?: Maybe<Array<Maybe<IUserSettings>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteUserSettingsPayloadUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IPollAggregateResult = {
  __typename?: 'PollAggregateResult';
  count?: Maybe<Scalars['Int']>;
  balanceMin?: Maybe<Scalars['Float']>;
  balanceMax?: Maybe<Scalars['Float']>;
  balanceSum?: Maybe<Scalars['Float']>;
  balanceAvg?: Maybe<Scalars['Float']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  totalVotesMin?: Maybe<Scalars['Int']>;
  totalVotesMax?: Maybe<Scalars['Int']>;
  totalVotesSum?: Maybe<Scalars['Int']>;
  totalVotesAvg?: Maybe<Scalars['Float']>;
};

export type IEvent = IIEntity & {
  __typename?: 'Event';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  competitions?: Maybe<Array<ICompetition>>;
  competitionsAggregate?: Maybe<ICompetitionAggregateResult>;
  description?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  label: Scalars['String'];
  league?: Maybe<ILeague>;
  objectiveID: Scalars['ID'];
  season?: Maybe<ISeason>;
  shortLabel?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
};


export type IEventCompetitionsArgs = {
  filter?: Maybe<ICompetitionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ICompetitionOrder>;
};


export type IEventCompetitionsAggregateArgs = {
  filter?: Maybe<ICompetitionFilter>;
};


export type IEventLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
};


export type IEventSeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
};

export type IIMetadataFilter = {
  dateCreated?: Maybe<IDateTimeFilter>;
  has?: Maybe<IIMetadataHasFilter>;
  and?: Maybe<Array<Maybe<IIMetadataFilter>>>;
  or?: Maybe<Array<Maybe<IIMetadataFilter>>>;
  not?: Maybe<IIMetadataFilter>;
};

export type IAddReferralInput = {
  dateCreated: Scalars['DateTime'];
  user?: Maybe<IUserRef>;
  ipAddress?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type IInt64Filter = {
  eq?: Maybe<Scalars['Int64']>;
  le?: Maybe<Scalars['Int64']>;
  lt?: Maybe<Scalars['Int64']>;
  ge?: Maybe<Scalars['Int64']>;
  gt?: Maybe<Scalars['Int64']>;
  between?: Maybe<IInt64Range>;
};

export type IEventFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  has?: Maybe<IEventHasFilter>;
  and?: Maybe<Array<Maybe<IEventFilter>>>;
  or?: Maybe<Array<Maybe<IEventFilter>>>;
  not?: Maybe<IEventFilter>;
};

export type IPointList = {
  __typename?: 'PointList';
  points: Array<IPoint>;
};

export type IAddArticleInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  datePublished?: Maybe<Scalars['DateTime']>;
  link: Scalars['String'];
  tags?: Maybe<Array<IIEntityRef>>;
};

export type IPostOrder = {
  asc?: Maybe<IPostOrderable>;
  desc?: Maybe<IPostOrderable>;
  then?: Maybe<IPostOrder>;
};

export type ISportRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  espnUID?: Maybe<Scalars['String']>;
  leagues?: Maybe<Array<ILeagueRef>>;
};

export type IDeleteCompetitorPayload = {
  __typename?: 'DeleteCompetitorPayload';
  competitor?: Maybe<Array<Maybe<ICompetitor>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteCompetitorPayloadCompetitorArgs = {
  filter?: Maybe<ICompetitorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  event?: Maybe<Array<Maybe<IEvent>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteEventPayloadEventArgs = {
  filter?: Maybe<IEventFilter>;
  order?: Maybe<IEventOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IITransactorAggregateResult = {
  __typename?: 'ITransactorAggregateResult';
  count?: Maybe<Scalars['Int']>;
  balanceMin?: Maybe<Scalars['Float']>;
  balanceMax?: Maybe<Scalars['Float']>;
  balanceSum?: Maybe<Scalars['Float']>;
  balanceAvg?: Maybe<Scalars['Float']>;
};

export type IOptionOrder = {
  asc?: Maybe<IOptionOrderable>;
  desc?: Maybe<IOptionOrderable>;
  then?: Maybe<IOptionOrder>;
};

export type IPollOrder = {
  asc?: Maybe<IPollOrderable>;
  desc?: Maybe<IPollOrderable>;
  then?: Maybe<IPollOrder>;
};

export type IAddReferralPayload = {
  __typename?: 'AddReferralPayload';
  referral?: Maybe<Array<Maybe<IReferral>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddReferralPayloadReferralArgs = {
  filter?: Maybe<IReferralFilter>;
  order?: Maybe<IReferralOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IResultOutcomeHasFilter {
  Win = 'win'
}

export type IAddResultOutcomeInput = {
  win: Scalars['Boolean'];
};

export type ISeasonOrder = {
  asc?: Maybe<ISeasonOrderable>;
  desc?: Maybe<ISeasonOrderable>;
  then?: Maybe<ISeasonOrder>;
};

export type IUserFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  balance?: Maybe<IFloatFilter>;
  dateCreated?: Maybe<IDateTimeFilter>;
  username?: Maybe<IStringRegExpFilter>;
  email?: Maybe<IStringHashFilter>;
  totalVotes?: Maybe<IIntFilter>;
  correctVotes?: Maybe<IIntFilter>;
  has?: Maybe<IUserHasFilter>;
  and?: Maybe<Array<Maybe<IUserFilter>>>;
  or?: Maybe<Array<Maybe<IUserFilter>>>;
  not?: Maybe<IUserFilter>;
};

export type IUpdateSportPayload = {
  __typename?: 'UpdateSportPayload';
  sport?: Maybe<Array<Maybe<ISport>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateSportPayloadSportArgs = {
  filter?: Maybe<ISportFilter>;
  order?: Maybe<ISportOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddSeasonPayload = {
  __typename?: 'AddSeasonPayload';
  season?: Maybe<Array<Maybe<ISeason>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddSeasonPayloadSeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
  order?: Maybe<ISeasonOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ISeasonHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  Year = 'year',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Type = 'type',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  League = 'league',
  Events = 'events'
}

export type IReferralRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  user?: Maybe<IUserRef>;
  ipAddress?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type IIEntity = {
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  shortLabel?: Maybe<Scalars['String']>;
};

export type IPostRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<IIEntityRef>>;
  author?: Maybe<IUserRef>;
};

export type IPollResponseFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  dateCreated?: Maybe<IDateTimeFilter>;
  correct?: Maybe<Scalars['Boolean']>;
  has?: Maybe<IPollResponseHasFilter>;
  and?: Maybe<Array<Maybe<IPollResponseFilter>>>;
  or?: Maybe<Array<Maybe<IPollResponseFilter>>>;
  not?: Maybe<IPollResponseFilter>;
};

export type IAddEventPayload = {
  __typename?: 'AddEventPayload';
  event?: Maybe<Array<Maybe<IEvent>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddEventPayloadEventArgs = {
  filter?: Maybe<IEventFilter>;
  order?: Maybe<IEventOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteITransactorPayload = {
  __typename?: 'DeleteITransactorPayload';
  iTransactor?: Maybe<Array<Maybe<IITransactor>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteITransactorPayloadITransactorArgs = {
  filter?: Maybe<IITransactorFilter>;
  order?: Maybe<IITransactorOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IITransactorOrderable {
  Balance = 'balance'
}

export enum IOptionHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DateCreated = 'dateCreated',
  Poll = 'poll',
  Entity = 'entity',
  Outcome = 'outcome',
  Correct = 'correct',
  VoteCount = 'voteCount'
}

export type IAddTransactionInput = {
  dateCreated: Scalars['DateTime'];
  from?: Maybe<IITransactorRef>;
  to: IITransactorRef;
  amount: Scalars['Float'];
  type: ITransaction_Type;
};

export type IPollFilter = {
  balance?: Maybe<IFloatFilter>;
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  dateCreated?: Maybe<IDateTimeFilter>;
  votingOpen?: Maybe<Scalars['Boolean']>;
  pollResolved?: Maybe<Scalars['Boolean']>;
  has?: Maybe<IPollHasFilter>;
  and?: Maybe<Array<Maybe<IPollFilter>>>;
  or?: Maybe<Array<Maybe<IPollFilter>>>;
  not?: Maybe<IPollFilter>;
};

export enum IDgraphIndex {
  Int = 'int',
  Int64 = 'int64',
  Float = 'float',
  Bool = 'bool',
  Hash = 'hash',
  Exact = 'exact',
  Term = 'term',
  Fulltext = 'fulltext',
  Trigram = 'trigram',
  Regexp = 'regexp',
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Geo = 'geo'
}

export type IUserAggregateResult = {
  __typename?: 'UserAggregateResult';
  count?: Maybe<Scalars['Int']>;
  balanceMin?: Maybe<Scalars['Float']>;
  balanceMax?: Maybe<Scalars['Float']>;
  balanceSum?: Maybe<Scalars['Float']>;
  balanceAvg?: Maybe<Scalars['Float']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  usernameMin?: Maybe<Scalars['String']>;
  usernameMax?: Maybe<Scalars['String']>;
  emailMin?: Maybe<Scalars['String']>;
  emailMax?: Maybe<Scalars['String']>;
  totalVotesMin?: Maybe<Scalars['Int']>;
  totalVotesMax?: Maybe<Scalars['Int']>;
  totalVotesSum?: Maybe<Scalars['Int']>;
  totalVotesAvg?: Maybe<Scalars['Float']>;
  correctVotesMin?: Maybe<Scalars['Int']>;
  correctVotesMax?: Maybe<Scalars['Int']>;
  correctVotesSum?: Maybe<Scalars['Int']>;
  correctVotesAvg?: Maybe<Scalars['Float']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
};

export type IEventRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  espnUID?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  season?: Maybe<ISeasonRef>;
  league?: Maybe<ILeagueRef>;
  competitions?: Maybe<Array<ICompetitionRef>>;
};

export type IDateTimeFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  le?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  ge?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  between?: Maybe<IDateTimeRange>;
};

export enum ITransaction_Type {
  PollReward = 'POLL_REWARD',
  TokenMinting = 'TOKEN_MINTING'
}

export type IWithinFilter = {
  polygon: IPolygonRef;
};

export type IUpdateTransactionPayload = {
  __typename?: 'UpdateTransactionPayload';
  transaction?: Maybe<Array<Maybe<ITransaction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateTransactionPayloadTransactionArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ISportFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  has?: Maybe<ISportHasFilter>;
  and?: Maybe<Array<Maybe<ISportFilter>>>;
  or?: Maybe<Array<Maybe<ISportFilter>>>;
  not?: Maybe<ISportFilter>;
};

export type IUpdatePollInput = {
  filter: IPollFilter;
  set?: Maybe<IPollPatch>;
  remove?: Maybe<IPollPatch>;
};

export type IDeleteReferralPayload = {
  __typename?: 'DeleteReferralPayload';
  referral?: Maybe<Array<Maybe<IReferral>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteReferralPayloadReferralArgs = {
  filter?: Maybe<IReferralFilter>;
  order?: Maybe<IReferralOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IArticlePatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  datePublished?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<IIEntityRef>>;
};

export type IReferralPatch = {
  dateCreated?: Maybe<Scalars['DateTime']>;
  user?: Maybe<IUserRef>;
  ipAddress?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type IDeleteIMetadataPayload = {
  __typename?: 'DeleteIMetadataPayload';
  iMetadata?: Maybe<Array<Maybe<IIMetadata>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteIMetadataPayloadIMetadataArgs = {
  filter?: Maybe<IIMetadataFilter>;
  order?: Maybe<IIMetadataOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateCompetitionInput = {
  filter: ICompetitionFilter;
  set?: Maybe<ICompetitionPatch>;
  remove?: Maybe<ICompetitionPatch>;
};

export type IArticleRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  datePublished?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<IIEntityRef>>;
};

export type IDeleteArticlePayload = {
  __typename?: 'DeleteArticlePayload';
  article?: Maybe<Array<Maybe<IArticle>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteArticlePayloadArticleArgs = {
  filter?: Maybe<IArticleFilter>;
  order?: Maybe<IArticleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ISportHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID',
  Leagues = 'leagues'
}

export type IUpdateLeagueInput = {
  filter: ILeagueFilter;
  set?: Maybe<ILeaguePatch>;
  remove?: Maybe<ILeaguePatch>;
};

export type ILeague = IIEntity & {
  __typename?: 'League';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  seasons?: Maybe<Array<ISeason>>;
  seasonsAggregate?: Maybe<ISeasonAggregateResult>;
  shortLabel?: Maybe<Scalars['String']>;
  sport: ISport;
  teams?: Maybe<Array<ITeam>>;
  teamsAggregate?: Maybe<ITeamAggregateResult>;
};


export type ILeagueSeasonsArgs = {
  filter?: Maybe<ISeasonFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ISeasonOrder>;
};


export type ILeagueSeasonsAggregateArgs = {
  filter?: Maybe<ISeasonFilter>;
};


export type ILeagueSportArgs = {
  filter?: Maybe<ISportFilter>;
};


export type ILeagueTeamsArgs = {
  filter?: Maybe<ITeamFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ITeamOrder>;
};


export type ILeagueTeamsAggregateArgs = {
  filter?: Maybe<ITeamFilter>;
};

export type IDeleteResultOutcomePayload = {
  __typename?: 'DeleteResultOutcomePayload';
  resultOutcome?: Maybe<Array<Maybe<IResultOutcome>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteResultOutcomePayloadResultOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IResultOutcomeFilter = {
  has?: Maybe<IResultOutcomeHasFilter>;
  and?: Maybe<Array<Maybe<IResultOutcomeFilter>>>;
  or?: Maybe<Array<Maybe<IResultOutcomeFilter>>>;
  not?: Maybe<IResultOutcomeFilter>;
};

export type ICompetitorAggregateResult = {
  __typename?: 'CompetitorAggregateResult';
  count?: Maybe<Scalars['Int']>;
};

export type IAddUserInput = {
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance: Scalars['Float'];
  dateCreated: Scalars['DateTime'];
  username: Scalars['String'];
  email: Scalars['String'];
  pollResponses?: Maybe<Array<IPollResponseRef>>;
  totalVotes: Scalars['Int'];
  correctVotes: Scalars['Int'];
  posts?: Maybe<Array<IPostRef>>;
  avatar?: Maybe<Scalars['String']>;
  settings?: Maybe<IUserSettingsRef>;
  favoriteTeams?: Maybe<Array<ITeamRef>>;
  favoriteLeagues?: Maybe<Array<ILeagueRef>>;
};

export type ITransactionOrder = {
  asc?: Maybe<ITransactionOrderable>;
  desc?: Maybe<ITransactionOrderable>;
  then?: Maybe<ITransactionOrder>;
};

export enum ISportOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID'
}

export type IStringTermFilter = {
  allofterms?: Maybe<Scalars['String']>;
  anyofterms?: Maybe<Scalars['String']>;
};

export type IAddSportPayload = {
  __typename?: 'AddSportPayload';
  sport?: Maybe<Array<Maybe<ISport>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddSportPayloadSportArgs = {
  filter?: Maybe<ISportFilter>;
  order?: Maybe<ISportOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ICompetitionAggregateResult = {
  __typename?: 'CompetitionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  espnIDMin?: Maybe<Scalars['String']>;
  espnIDMax?: Maybe<Scalars['String']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
  startTimeMin?: Maybe<Scalars['DateTime']>;
  startTimeMax?: Maybe<Scalars['DateTime']>;
};

export type ILeagueAggregateResult = {
  __typename?: 'LeagueAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  espnIDMin?: Maybe<Scalars['String']>;
  espnIDMax?: Maybe<Scalars['String']>;
  espnUIDMin?: Maybe<Scalars['String']>;
  espnUIDMax?: Maybe<Scalars['String']>;
};

export enum IArticleHasFilter {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  DatePublished = 'datePublished',
  Link = 'link',
  Tags = 'tags'
}

export type IAddPollInput = {
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance: Scalars['Float'];
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dateCreated: Scalars['DateTime'];
  competition: ICompetitionRef;
  options?: Maybe<Array<IOptionRef>>;
  totalVotes: Scalars['Int'];
  votingOpen: Scalars['Boolean'];
  pollResolved: Scalars['Boolean'];
  correctOption?: Maybe<IOptionRef>;
  pollResponses?: Maybe<Array<IPollResponseRef>>;
};

export type IUpdateOptionInput = {
  filter: IOptionFilter;
  set?: Maybe<IOptionPatch>;
  remove?: Maybe<IOptionPatch>;
};

export type IPoll = IIEntity & IIMetadata & IITransactor & {
  __typename?: 'Poll';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  balance: Scalars['Float'];
  competition: ICompetition;
  correctOption?: Maybe<IOption>;
  dateCreated: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  incomingTransactions?: Maybe<Array<ITransaction>>;
  incomingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  options?: Maybe<Array<IOption>>;
  optionsAggregate?: Maybe<IOptionAggregateResult>;
  outgoingTransactions?: Maybe<Array<ITransaction>>;
  outgoingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
  pollResolved: Scalars['Boolean'];
  pollResponses?: Maybe<Array<IPollResponse>>;
  pollResponsesAggregate?: Maybe<IPollResponseAggregateResult>;
  shortLabel?: Maybe<Scalars['String']>;
  totalVotes: Scalars['Int'];
  votingOpen: Scalars['Boolean'];
};


export type IPollCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
};


export type IPollCorrectOptionArgs = {
  filter?: Maybe<IOptionFilter>;
};


export type IPollIncomingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IPollIncomingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IPollOptionsArgs = {
  filter?: Maybe<IOptionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IOptionOrder>;
};


export type IPollOptionsAggregateArgs = {
  filter?: Maybe<IOptionFilter>;
};


export type IPollOutgoingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IPollOutgoingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IPollPollResponsesArgs = {
  filter?: Maybe<IPollResponseFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IPollResponseOrder>;
};


export type IPollPollResponsesAggregateArgs = {
  filter?: Maybe<IPollResponseFilter>;
};

export type IFloatFilter = {
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  between?: Maybe<IFloatRange>;
};

export type IStringRegExpFilter = {
  regexp?: Maybe<Scalars['String']>;
};

export type IReferralAggregateResult = {
  __typename?: 'ReferralAggregateResult';
  count?: Maybe<Scalars['Int']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  ipAddressMin?: Maybe<Scalars['String']>;
  ipAddressMax?: Maybe<Scalars['String']>;
  sourceMin?: Maybe<Scalars['String']>;
  sourceMax?: Maybe<Scalars['String']>;
};

export type IUpdateResultOutcomePayload = {
  __typename?: 'UpdateResultOutcomePayload';
  resultOutcome?: Maybe<Array<Maybe<IResultOutcome>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateResultOutcomePayloadResultOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IIMetadataOrderable {
  DateCreated = 'dateCreated'
}

export enum IPostHasFilter {
  DateCreated = 'dateCreated',
  Content = 'content',
  Tags = 'tags',
  Author = 'author'
}

export type ISeason = IIEntity & {
  __typename?: 'Season';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  espnID: Scalars['Int'];
  espnUID: Scalars['String'];
  events?: Maybe<Array<IEvent>>;
  eventsAggregate?: Maybe<IEventAggregateResult>;
  label: Scalars['String'];
  league: ILeague;
  objectiveID: Scalars['ID'];
  shortLabel?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: Scalars['Int'];
  year: Scalars['Int'];
};


export type ISeasonEventsArgs = {
  filter?: Maybe<IEventFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IEventOrder>;
};


export type ISeasonEventsAggregateArgs = {
  filter?: Maybe<IEventFilter>;
};


export type ISeasonLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
};

export type IPolygon = {
  __typename?: 'Polygon';
  coordinates: Array<IPointList>;
};

export type IPolygonRef = {
  coordinates: Array<IPointListRef>;
};

export type IQuery = {
  __typename?: 'Query';
  queryIMetadata?: Maybe<Array<Maybe<IIMetadata>>>;
  aggregateIMetadata?: Maybe<IIMetadataAggregateResult>;
  getIEntity?: Maybe<IIEntity>;
  queryIEntity?: Maybe<Array<Maybe<IIEntity>>>;
  aggregateIEntity?: Maybe<IIEntityAggregateResult>;
  getITransactor?: Maybe<IITransactor>;
  queryITransactor?: Maybe<Array<Maybe<IITransactor>>>;
  aggregateITransactor?: Maybe<IITransactorAggregateResult>;
  getUser?: Maybe<IUser>;
  queryUser?: Maybe<Array<Maybe<IUser>>>;
  aggregateUser?: Maybe<IUserAggregateResult>;
  getUserSettings?: Maybe<IUserSettings>;
  queryUserSettings?: Maybe<Array<Maybe<IUserSettings>>>;
  aggregateUserSettings?: Maybe<IUserSettingsAggregateResult>;
  getSport?: Maybe<ISport>;
  querySport?: Maybe<Array<Maybe<ISport>>>;
  aggregateSport?: Maybe<ISportAggregateResult>;
  getLeague?: Maybe<ILeague>;
  queryLeague?: Maybe<Array<Maybe<ILeague>>>;
  aggregateLeague?: Maybe<ILeagueAggregateResult>;
  getSeason?: Maybe<ISeason>;
  querySeason?: Maybe<Array<Maybe<ISeason>>>;
  aggregateSeason?: Maybe<ISeasonAggregateResult>;
  getTeam?: Maybe<ITeam>;
  queryTeam?: Maybe<Array<Maybe<ITeam>>>;
  aggregateTeam?: Maybe<ITeamAggregateResult>;
  getCompetitor?: Maybe<ICompetitor>;
  queryCompetitor?: Maybe<Array<Maybe<ICompetitor>>>;
  aggregateCompetitor?: Maybe<ICompetitorAggregateResult>;
  getEvent?: Maybe<IEvent>;
  queryEvent?: Maybe<Array<Maybe<IEvent>>>;
  aggregateEvent?: Maybe<IEventAggregateResult>;
  getCompetition?: Maybe<ICompetition>;
  queryCompetition?: Maybe<Array<Maybe<ICompetition>>>;
  aggregateCompetition?: Maybe<ICompetitionAggregateResult>;
  queryResultOutcome?: Maybe<Array<Maybe<IResultOutcome>>>;
  aggregateResultOutcome?: Maybe<IResultOutcomeAggregateResult>;
  getOption?: Maybe<IOption>;
  queryOption?: Maybe<Array<Maybe<IOption>>>;
  aggregateOption?: Maybe<IOptionAggregateResult>;
  getPoll?: Maybe<IPoll>;
  queryPoll?: Maybe<Array<Maybe<IPoll>>>;
  aggregatePoll?: Maybe<IPollAggregateResult>;
  getPollResponse?: Maybe<IPollResponse>;
  queryPollResponse?: Maybe<Array<Maybe<IPollResponse>>>;
  aggregatePollResponse?: Maybe<IPollResponseAggregateResult>;
  getPost?: Maybe<IPost>;
  queryPost?: Maybe<Array<Maybe<IPost>>>;
  aggregatePost?: Maybe<IPostAggregateResult>;
  getArticle?: Maybe<IArticle>;
  queryArticle?: Maybe<Array<Maybe<IArticle>>>;
  aggregateArticle?: Maybe<IArticleAggregateResult>;
  getTransaction?: Maybe<ITransaction>;
  queryTransaction?: Maybe<Array<Maybe<ITransaction>>>;
  aggregateTransaction?: Maybe<ITransactionAggregateResult>;
  getReferral?: Maybe<IReferral>;
  queryReferral?: Maybe<Array<Maybe<IReferral>>>;
  aggregateReferral?: Maybe<IReferralAggregateResult>;
};


export type IQueryQueryIMetadataArgs = {
  filter?: Maybe<IIMetadataFilter>;
  order?: Maybe<IIMetadataOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateIMetadataArgs = {
  filter?: Maybe<IIMetadataFilter>;
};


export type IQueryGetIEntityArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryIEntityArgs = {
  filter?: Maybe<IIEntityFilter>;
  order?: Maybe<IIEntityOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateIEntityArgs = {
  filter?: Maybe<IIEntityFilter>;
};


export type IQueryGetITransactorArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryITransactorArgs = {
  filter?: Maybe<IITransactorFilter>;
  order?: Maybe<IITransactorOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateITransactorArgs = {
  filter?: Maybe<IITransactorFilter>;
};


export type IQueryGetUserArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};


export type IQueryQueryUserArgs = {
  filter?: Maybe<IUserFilter>;
  order?: Maybe<IUserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateUserArgs = {
  filter?: Maybe<IUserFilter>;
};


export type IQueryGetUserSettingsArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
};


export type IQueryGetSportArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQuerySportArgs = {
  filter?: Maybe<ISportFilter>;
  order?: Maybe<ISportOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateSportArgs = {
  filter?: Maybe<ISportFilter>;
};


export type IQueryGetLeagueArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQueryLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
  order?: Maybe<ILeagueOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
};


export type IQueryGetSeasonArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQuerySeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
  order?: Maybe<ISeasonOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateSeasonArgs = {
  filter?: Maybe<ISeasonFilter>;
};


export type IQueryGetTeamArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQueryTeamArgs = {
  filter?: Maybe<ITeamFilter>;
  order?: Maybe<ITeamOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateTeamArgs = {
  filter?: Maybe<ITeamFilter>;
};


export type IQueryGetCompetitorArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryCompetitorArgs = {
  filter?: Maybe<ICompetitorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateCompetitorArgs = {
  filter?: Maybe<ICompetitorFilter>;
};


export type IQueryGetEventArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQueryEventArgs = {
  filter?: Maybe<IEventFilter>;
  order?: Maybe<IEventOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateEventArgs = {
  filter?: Maybe<IEventFilter>;
};


export type IQueryGetCompetitionArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  espnUID?: Maybe<Scalars['String']>;
};


export type IQueryQueryCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
  order?: Maybe<ICompetitionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateCompetitionArgs = {
  filter?: Maybe<ICompetitionFilter>;
};


export type IQueryQueryResultOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateResultOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
};


export type IQueryGetOptionArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryOptionArgs = {
  filter?: Maybe<IOptionFilter>;
  order?: Maybe<IOptionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateOptionArgs = {
  filter?: Maybe<IOptionFilter>;
};


export type IQueryGetPollArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryPollArgs = {
  filter?: Maybe<IPollFilter>;
  order?: Maybe<IPollOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregatePollArgs = {
  filter?: Maybe<IPollFilter>;
};


export type IQueryGetPollResponseArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryPollResponseArgs = {
  filter?: Maybe<IPollResponseFilter>;
  order?: Maybe<IPollResponseOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregatePollResponseArgs = {
  filter?: Maybe<IPollResponseFilter>;
};


export type IQueryGetPostArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryPostArgs = {
  filter?: Maybe<IPostFilter>;
  order?: Maybe<IPostOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregatePostArgs = {
  filter?: Maybe<IPostFilter>;
};


export type IQueryGetArticleArgs = {
  objectiveID?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
};


export type IQueryQueryArticleArgs = {
  filter?: Maybe<IArticleFilter>;
  order?: Maybe<IArticleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateArticleArgs = {
  filter?: Maybe<IArticleFilter>;
};


export type IQueryGetTransactionArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryTransactionArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateTransactionArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IQueryGetReferralArgs = {
  objectiveID: Scalars['ID'];
};


export type IQueryQueryReferralArgs = {
  filter?: Maybe<IReferralFilter>;
  order?: Maybe<IReferralOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IQueryAggregateReferralArgs = {
  filter?: Maybe<IReferralFilter>;
};

export type IUserSettingsPatch = {
  user?: Maybe<IUserRef>;
  darkMode?: Maybe<Scalars['Boolean']>;
};

export type IOption = IIEntity & IIMetadata & {
  __typename?: 'Option';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
  dateCreated: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  entity: ITeam;
  label: Scalars['String'];
  objectiveID: Scalars['ID'];
  outcome: IResultOutcome;
  poll?: Maybe<IPoll>;
  shortLabel?: Maybe<Scalars['String']>;
  voteCount?: Maybe<Scalars['Int']>;
};


export type IOptionEntityArgs = {
  filter?: Maybe<ITeamFilter>;
};


export type IOptionOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
};


export type IOptionPollArgs = {
  filter?: Maybe<IPollFilter>;
};

export type IInt64Range = {
  min: Scalars['Int64'];
  max: Scalars['Int64'];
};

export type IDeleteSportPayload = {
  __typename?: 'DeleteSportPayload';
  sport?: Maybe<Array<Maybe<ISport>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteSportPayloadSportArgs = {
  filter?: Maybe<ISportFilter>;
  order?: Maybe<ISportOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddUserSettingsInput = {
  user: IUserRef;
  darkMode?: Maybe<Scalars['Boolean']>;
};

export type ICompetitionPatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  competitors?: Maybe<Array<ICompetitorRef>>;
  event?: Maybe<IEventRef>;
  polls?: Maybe<Array<IPollRef>>;
  completed?: Maybe<Scalars['Boolean']>;
};

export type ITeamRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  espnUID?: Maybe<Scalars['String']>;
  league?: Maybe<ILeagueRef>;
};

export type IAddTeamPayload = {
  __typename?: 'AddTeamPayload';
  team?: Maybe<Array<Maybe<ITeam>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddTeamPayloadTeamArgs = {
  filter?: Maybe<ITeamFilter>;
  order?: Maybe<ITeamOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddUserSettingsPayload = {
  __typename?: 'AddUserSettingsPayload';
  userSettings?: Maybe<Array<Maybe<IUserSettings>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddUserSettingsPayloadUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateArticlePayload = {
  __typename?: 'UpdateArticlePayload';
  article?: Maybe<Array<Maybe<IArticle>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateArticlePayloadArticleArgs = {
  filter?: Maybe<IArticleFilter>;
  order?: Maybe<IArticleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<Array<Maybe<IUser>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateUserPayloadUserArgs = {
  filter?: Maybe<IUserFilter>;
  order?: Maybe<IUserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ICompetitionFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  startTime?: Maybe<IDateTimeFilter>;
  completed?: Maybe<Scalars['Boolean']>;
  has?: Maybe<ICompetitionHasFilter>;
  and?: Maybe<Array<Maybe<ICompetitionFilter>>>;
  or?: Maybe<Array<Maybe<ICompetitionFilter>>>;
  not?: Maybe<ICompetitionFilter>;
};

export type ILeagueOrder = {
  asc?: Maybe<ILeagueOrderable>;
  desc?: Maybe<ILeagueOrderable>;
  then?: Maybe<ILeagueOrder>;
};

export type IPostPatch = {
  dateCreated?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<IIEntityRef>>;
  author?: Maybe<IUserRef>;
};

export type IAddCompetitorPayload = {
  __typename?: 'AddCompetitorPayload';
  competitor?: Maybe<Array<Maybe<ICompetitor>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddCompetitorPayloadCompetitorArgs = {
  filter?: Maybe<ICompetitorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateIMetadataInput = {
  filter: IIMetadataFilter;
  set?: Maybe<IIMetadataPatch>;
  remove?: Maybe<IIMetadataPatch>;
};

export enum IMode {
  Batch = 'BATCH',
  Single = 'SINGLE'
}

export enum IReferralHasFilter {
  DateCreated = 'dateCreated',
  User = 'user',
  IpAddress = 'ipAddress',
  Source = 'source'
}

export type IResultOutcomeRef = {
  win?: Maybe<Scalars['Boolean']>;
};

export type IAuthRule = {
  and?: Maybe<Array<Maybe<IAuthRule>>>;
  or?: Maybe<Array<Maybe<IAuthRule>>>;
  not?: Maybe<IAuthRule>;
  rule?: Maybe<Scalars['String']>;
};

export type IUserPatch = {
  incomingTransactions?: Maybe<Array<ITransactionRef>>;
  outgoingTransactions?: Maybe<Array<ITransactionRef>>;
  balance?: Maybe<Scalars['Float']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  pollResponses?: Maybe<Array<IPollResponseRef>>;
  totalVotes?: Maybe<Scalars['Int']>;
  correctVotes?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<IPostRef>>;
  avatar?: Maybe<Scalars['String']>;
  settings?: Maybe<IUserSettingsRef>;
  favoriteTeams?: Maybe<Array<ITeamRef>>;
  favoriteLeagues?: Maybe<Array<ILeagueRef>>;
};

export type IPointRef = {
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type IUser = IIMetadata & IITransactor & {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  balance: Scalars['Float'];
  correctVotes: Scalars['Int'];
  dateCreated: Scalars['DateTime'];
  email: Scalars['String'];
  favoriteLeagues?: Maybe<Array<ILeague>>;
  favoriteLeaguesAggregate?: Maybe<ILeagueAggregateResult>;
  favoriteTeams?: Maybe<Array<ITeam>>;
  favoriteTeamsAggregate?: Maybe<ITeamAggregateResult>;
  incomingTransactions?: Maybe<Array<ITransaction>>;
  incomingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
  objectiveID: Scalars['ID'];
  outgoingTransactions?: Maybe<Array<ITransaction>>;
  outgoingTransactionsAggregate?: Maybe<ITransactionAggregateResult>;
  pollResponses?: Maybe<Array<IPollResponse>>;
  pollResponsesAggregate?: Maybe<IPollResponseAggregateResult>;
  posts?: Maybe<Array<IPost>>;
  postsAggregate?: Maybe<IPostAggregateResult>;
  settings?: Maybe<IUserSettings>;
  totalVotes: Scalars['Int'];
  username: Scalars['String'];
};


export type IUserFavoriteLeaguesArgs = {
  filter?: Maybe<ILeagueFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ILeagueOrder>;
};


export type IUserFavoriteLeaguesAggregateArgs = {
  filter?: Maybe<ILeagueFilter>;
};


export type IUserFavoriteTeamsArgs = {
  filter?: Maybe<ITeamFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<ITeamOrder>;
};


export type IUserFavoriteTeamsAggregateArgs = {
  filter?: Maybe<ITeamFilter>;
};


export type IUserIncomingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IUserIncomingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IUserOutgoingTransactionsArgs = {
  filter?: Maybe<ITransactionFilter>;
  order?: Maybe<ITransactionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type IUserOutgoingTransactionsAggregateArgs = {
  filter?: Maybe<ITransactionFilter>;
};


export type IUserPollResponsesArgs = {
  filter?: Maybe<IPollResponseFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IPollResponseOrder>;
};


export type IUserPollResponsesAggregateArgs = {
  filter?: Maybe<IPollResponseFilter>;
};


export type IUserPostsArgs = {
  filter?: Maybe<IPostFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IPostOrder>;
};


export type IUserPostsAggregateArgs = {
  filter?: Maybe<IPostFilter>;
};


export type IUserSettingsArgs = {
  filter?: Maybe<IUserSettingsFilter>;
};

export type IPointListRef = {
  points: Array<IPointRef>;
};

export type IAddArticlePayload = {
  __typename?: 'AddArticlePayload';
  article?: Maybe<Array<Maybe<IArticle>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddArticlePayloadArticleArgs = {
  filter?: Maybe<IArticleFilter>;
  order?: Maybe<IArticleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddResultOutcomePayload = {
  __typename?: 'AddResultOutcomePayload';
  resultOutcome?: Maybe<Array<Maybe<IResultOutcome>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddResultOutcomePayloadResultOutcomeArgs = {
  filter?: Maybe<IResultOutcomeFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ICompetitionRef = {
  objectiveID?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  espnUID?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  competitors?: Maybe<Array<ICompetitorRef>>;
  event?: Maybe<IEventRef>;
  polls?: Maybe<Array<IPollRef>>;
  completed?: Maybe<Scalars['Boolean']>;
};

export type IDeletePostPayload = {
  __typename?: 'DeletePostPayload';
  post?: Maybe<Array<Maybe<IPost>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeletePostPayloadPostArgs = {
  filter?: Maybe<IPostFilter>;
  order?: Maybe<IPostOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IEventOrder = {
  asc?: Maybe<IEventOrderable>;
  desc?: Maybe<IEventOrderable>;
  then?: Maybe<IEventOrder>;
};

export type ITransaction_Type_Hash = {
  eq?: Maybe<ITransaction_Type>;
  in?: Maybe<Array<Maybe<ITransaction_Type>>>;
};

export type IStringRange = {
  min: Scalars['String'];
  max: Scalars['String'];
};

export type IIntFilter = {
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<IIntRange>;
};

export type INearFilter = {
  distance: Scalars['Float'];
  coordinate: IPointRef;
};

export enum ILeagueOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  EspnId = 'espnID',
  EspnUid = 'espnUID'
}

export type ICompetitorFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<ICompetitorHasFilter>;
  and?: Maybe<Array<Maybe<ICompetitorFilter>>>;
  or?: Maybe<Array<Maybe<ICompetitorFilter>>>;
  not?: Maybe<ICompetitorFilter>;
};

export type IStringFullTextFilter = {
  alloftext?: Maybe<Scalars['String']>;
  anyoftext?: Maybe<Scalars['String']>;
};

export type IUpdateIMetadataPayload = {
  __typename?: 'UpdateIMetadataPayload';
  iMetadata?: Maybe<Array<Maybe<IIMetadata>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateIMetadataPayloadIMetadataArgs = {
  filter?: Maybe<IIMetadataFilter>;
  order?: Maybe<IIMetadataOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IUpdateITransactorPayload = {
  __typename?: 'UpdateITransactorPayload';
  iTransactor?: Maybe<Array<Maybe<IITransactor>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IUpdateITransactorPayloadITransactorArgs = {
  filter?: Maybe<IITransactorFilter>;
  order?: Maybe<IITransactorOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum IIMetadataHasFilter {
  DateCreated = 'dateCreated'
}

export enum ISeasonOrderable {
  Label = 'label',
  ShortLabel = 'shortLabel',
  Abbreviation = 'abbreviation',
  Description = 'description',
  Avatar = 'avatar',
  Year = 'year',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Type = 'type',
  EspnId = 'espnID',
  EspnUid = 'espnUID'
}

export type IOptionFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  dateCreated?: Maybe<IDateTimeFilter>;
  has?: Maybe<IOptionHasFilter>;
  and?: Maybe<Array<Maybe<IOptionFilter>>>;
  or?: Maybe<Array<Maybe<IOptionFilter>>>;
  not?: Maybe<IOptionFilter>;
};

export type IUserSettingsFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<IUserSettingsHasFilter>;
  and?: Maybe<Array<Maybe<IUserSettingsFilter>>>;
  or?: Maybe<Array<Maybe<IUserSettingsFilter>>>;
  not?: Maybe<IUserSettingsFilter>;
};

export type IUserSettings = {
  __typename?: 'UserSettings';
  darkMode?: Maybe<Scalars['Boolean']>;
  objectiveID: Scalars['ID'];
  user: IUser;
};


export type IUserSettingsUserArgs = {
  filter?: Maybe<IUserFilter>;
};

export type IAddPostPayload = {
  __typename?: 'AddPostPayload';
  post?: Maybe<Array<Maybe<IPost>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IAddPostPayloadPostArgs = {
  filter?: Maybe<IPostFilter>;
  order?: Maybe<IPostOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ITransactionHasFilter {
  DateCreated = 'dateCreated',
  From = 'from',
  To = 'to',
  Amount = 'amount',
  Type = 'type'
}

export type IAddCompetitionInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  startTime: Scalars['DateTime'];
  competitors?: Maybe<Array<ICompetitorRef>>;
  event: IEventRef;
  polls?: Maybe<Array<IPollRef>>;
  completed: Scalars['Boolean'];
};

export type IAddEventInput = {
  label: Scalars['String'];
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  startTime: Scalars['DateTime'];
  season?: Maybe<ISeasonRef>;
  league?: Maybe<ILeagueRef>;
  competitions?: Maybe<Array<ICompetitionRef>>;
};

export type IPost = IIMetadata & {
  __typename?: 'Post';
  author: IUser;
  content: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  objectiveID: Scalars['ID'];
  tags?: Maybe<Array<IIEntity>>;
  tagsAggregate?: Maybe<IIEntityAggregateResult>;
};


export type IPostAuthorArgs = {
  filter?: Maybe<IUserFilter>;
};


export type IPostTagsArgs = {
  filter?: Maybe<IIEntityFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<IIEntityOrder>;
};


export type IPostTagsAggregateArgs = {
  filter?: Maybe<IIEntityFilter>;
};

export type IPollResponseAggregateResult = {
  __typename?: 'PollResponseAggregateResult';
  count?: Maybe<Scalars['Int']>;
  labelMin?: Maybe<Scalars['String']>;
  labelMax?: Maybe<Scalars['String']>;
  shortLabelMin?: Maybe<Scalars['String']>;
  shortLabelMax?: Maybe<Scalars['String']>;
  abbreviationMin?: Maybe<Scalars['String']>;
  abbreviationMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  avatarMin?: Maybe<Scalars['String']>;
  avatarMax?: Maybe<Scalars['String']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
  scoreMin?: Maybe<Scalars['Float']>;
  scoreMax?: Maybe<Scalars['Float']>;
  scoreSum?: Maybe<Scalars['Float']>;
  scoreAvg?: Maybe<Scalars['Float']>;
};

export type IAddPostInput = {
  dateCreated: Scalars['DateTime'];
  content: Scalars['String'];
  tags?: Maybe<Array<IIEntityRef>>;
  author: IUserRef;
};

export type IUpdateSportInput = {
  filter: ISportFilter;
  set?: Maybe<ISportPatch>;
  remove?: Maybe<ISportPatch>;
};

export type IIMetadataAggregateResult = {
  __typename?: 'IMetadataAggregateResult';
  count?: Maybe<Scalars['Int']>;
  dateCreatedMin?: Maybe<Scalars['DateTime']>;
  dateCreatedMax?: Maybe<Scalars['DateTime']>;
};

export type IGenerateMutationParams = {
  add?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
};

export enum IReferralOrderable {
  DateCreated = 'dateCreated',
  IpAddress = 'ipAddress',
  Source = 'source'
}

export type ILeagueFilter = {
  objectiveID?: Maybe<Array<Scalars['ID']>>;
  label?: Maybe<IStringRegExpFilter>;
  shortLabel?: Maybe<IStringRegExpFilter>;
  abbreviation?: Maybe<IStringRegExpFilter>;
  description?: Maybe<IStringFullTextFilter>;
  espnUID?: Maybe<IStringHashFilter>;
  has?: Maybe<ILeagueHasFilter>;
  and?: Maybe<Array<Maybe<ILeagueFilter>>>;
  or?: Maybe<Array<Maybe<ILeagueFilter>>>;
  not?: Maybe<ILeagueFilter>;
};

export type ILeaguePatch = {
  label?: Maybe<Scalars['String']>;
  shortLabel?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  espnID?: Maybe<Scalars['String']>;
  sport?: Maybe<ISportRef>;
  teams?: Maybe<Array<ITeamRef>>;
  seasons?: Maybe<Array<ISeasonRef>>;
};

export type ITransaction = IIMetadata & {
  __typename?: 'Transaction';
  amount: Scalars['Float'];
  dateCreated: Scalars['DateTime'];
  from?: Maybe<IITransactor>;
  objectiveID: Scalars['ID'];
  to: IITransactor;
  type: ITransaction_Type;
};


export type ITransactionFromArgs = {
  filter?: Maybe<IITransactorFilter>;
};


export type ITransactionToArgs = {
  filter?: Maybe<IITransactorFilter>;
};

export type IUpdateTeamInput = {
  filter: ITeamFilter;
  set?: Maybe<ITeamPatch>;
  remove?: Maybe<ITeamPatch>;
};

export type IIMetadataPatch = {
  dateCreated?: Maybe<Scalars['DateTime']>;
};

export type IDateTimeRange = {
  min: Scalars['DateTime'];
  max: Scalars['DateTime'];
};

export type IDeleteIEntityPayload = {
  __typename?: 'DeleteIEntityPayload';
  iEntity?: Maybe<Array<Maybe<IIEntity>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteIEntityPayloadIEntityArgs = {
  filter?: Maybe<IIEntityFilter>;
  order?: Maybe<IIEntityOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IDeleteOptionPayload = {
  __typename?: 'DeleteOptionPayload';
  option?: Maybe<Array<Maybe<IOption>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type IDeleteOptionPayloadOptionArgs = {
  filter?: Maybe<IOptionFilter>;
  order?: Maybe<IOptionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IAddCompetitorInput = {
  entity: ITeamRef;
  home?: Maybe<Scalars['Boolean']>;
  winner?: Maybe<Scalars['Boolean']>;
};

export type IUpdateSeasonInput = {
  filter: ISeasonFilter;
  set?: Maybe<ISeasonPatch>;
  remove?: Maybe<ISeasonPatch>;
};

export type IUserOrder = {
  asc?: Maybe<IUserOrderable>;
  desc?: Maybe<IUserOrderable>;
  then?: Maybe<IUserOrder>;
};

export type ITeam = IIEntity & {
  __typename?: 'Team';
  abbreviation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  espnID: Scalars['String'];
  espnUID: Scalars['String'];
  label: Scalars['String'];
  league: ILeague;
  objectiveID: Scalars['ID'];
  shortLabel?: Maybe<Scalars['String']>;
};


export type ITeamLeagueArgs = {
  filter?: Maybe<ILeagueFilter>;
};

export type IGetFeedQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  postFilter?: Maybe<IPostFilter>;
  userFilter?: Maybe<IUserFilter>;
  pollFilter?: Maybe<IPollFilter>;
  competitionFilter?: Maybe<ICompetitionFilter>;
  teamFilter?: Maybe<ITeamFilter>;
  pollResponseFilter?: Maybe<IPollResponseFilter>;
  tagFilter?: Maybe<IIEntityFilter>;
  articleFilter?: Maybe<IArticleFilter>;
  leagueFilter?: Maybe<ILeagueFilter>;
}>;


export type IGetFeedQuery = (
  { __typename?: 'Query' }
  & { queryPost?: Maybe<Array<Maybe<(
    { __typename: 'Post' }
    & Pick<IPost, 'objectiveID' | 'dateCreated' | 'content'>
    & { author: (
      { __typename?: 'User' }
      & Pick<IUser, 'objectiveID' | 'username' | 'avatar'>
    ), tags?: Maybe<Array<(
      { __typename: 'Article' }
      & Pick<IArticle, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Sport' }
      & Pick<ISport, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'League' }
      & Pick<ILeague, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Season' }
      & Pick<ISeason, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Option' }
      & Pick<IOption, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Team' }
      & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    )>> }
  )>>>, queryPoll?: Maybe<Array<Maybe<(
    { __typename: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'label' | 'balance' | 'totalVotes' | 'votingOpen' | 'pollResolved'>
    & { competition: (
      { __typename?: 'Competition' }
      & Pick<ICompetition, 'startTime' | 'objectiveID' | 'label' | 'espnID'>
      & { event: (
        { __typename?: 'Event' }
        & { league?: Maybe<(
          { __typename?: 'League' }
          & Pick<ILeague, 'objectiveID'>
        )> }
      ), competitors?: Maybe<Array<(
        { __typename?: 'Competitor' }
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'avatar'>
        ) }
      )>> }
    ), options?: Maybe<Array<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID' | 'voteCount' | 'correct'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'label' | 'objectiveID' | 'espnID' | 'espnUID' | 'avatar'>
      ), outcome: (
        { __typename?: 'ResultOutcome' }
        & Pick<IResultOutcome, 'win'>
      ) }
    )>>, correctOption?: Maybe<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
    )>, pollResponses?: Maybe<Array<(
      { __typename?: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID'>
      & { voter: (
        { __typename?: 'User' }
        & Pick<IUser, 'objectiveID'>
      ) }
    )>> }
  )>>>, queryPollResponse?: Maybe<Array<Maybe<(
    { __typename: 'PollResponse' }
    & Pick<IPollResponse, 'dateCreated'>
    & { voter: (
      { __typename?: 'User' }
      & Pick<IUser, 'objectiveID' | 'username' | 'avatar'>
    ), vote: (
      { __typename?: 'Option' }
      & Pick<IOption, 'voteCount' | 'objectiveID'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'espnUID' | 'espnID' | 'objectiveID' | 'label' | 'avatar'>
      ) }
    ), poll: (
      { __typename?: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'dateCreated' | 'totalVotes' | 'balance'>
      & { competition: (
        { __typename?: 'Competition' }
        & Pick<ICompetition, 'espnID' | 'startTime' | 'objectiveID' | 'label'>
        & { event: (
          { __typename?: 'Event' }
          & { league?: Maybe<(
            { __typename?: 'League' }
            & Pick<ILeague, 'objectiveID'>
          )> }
        ), competitors?: Maybe<Array<(
          { __typename?: 'Competitor' }
          & { entity: (
            { __typename?: 'Team' }
            & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'avatar'>
          ) }
        )>> }
      ) }
    ) }
  )>>>, queryArticle?: Maybe<Array<Maybe<(
    { __typename: 'Article' }
    & Pick<IArticle, 'objectiveID' | 'label' | 'description' | 'avatar' | 'datePublished' | 'link'>
    & { tags?: Maybe<Array<(
      { __typename: 'Article' }
      & Pick<IArticle, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Sport' }
      & Pick<ISport, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'League' }
      & Pick<ILeague, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Season' }
      & Pick<ISeason, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Option' }
      & Pick<IOption, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    ) | (
      { __typename: 'Team' }
      & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
    )>> }
  )>>> }
);

export type IUserDataFragment = (
  { __typename?: 'User' }
  & Pick<IUser, 'objectiveID' | 'username' | 'email' | 'totalVotes' | 'correctVotes' | 'balance' | 'dateCreated' | 'avatar'>
);

export type ISportDataFragment = (
  { __typename?: 'Sport' }
  & Pick<ISport, 'objectiveID' | 'espnUID' | 'espnID' | 'label'>
  & { leagues?: Maybe<Array<(
    { __typename?: 'League' }
    & ILeagueDataFragment
  )>> }
);

export type ILeagueDataFragment = (
  { __typename?: 'League' }
  & Pick<ILeague, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'abbreviation'>
  & { sport: (
    { __typename?: 'Sport' }
    & Pick<ISport, 'objectiveID' | 'espnUID' | 'espnID' | 'label'>
  ), teams?: Maybe<Array<(
    { __typename?: 'Team' }
    & ITeamDataFragment
  )>>, seasons?: Maybe<Array<(
    { __typename?: 'Season' }
    & ISeasonDataFragment
  )>> }
);

export type ISeasonDataFragment = (
  { __typename?: 'Season' }
  & Pick<ISeason, 'objectiveID' | 'year' | 'startDate' | 'endDate' | 'type' | 'espnID' | 'label'>
  & { league: (
    { __typename?: 'League' }
    & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
  ), events?: Maybe<Array<(
    { __typename?: 'Event' }
    & IEventDataFragment
  )>> }
);

export type ITeamDataFragment = (
  { __typename?: 'Team' }
  & Pick<ITeam, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'avatar'>
  & { league: (
    { __typename?: 'League' }
    & Pick<ILeague, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'abbreviation'>
  ) }
);

export type ICompetitorDataFragment = (
  { __typename?: 'Competitor' }
  & Pick<ICompetitor, 'objectiveID' | 'home' | 'winner'>
  & { entity: (
    { __typename?: 'Team' }
    & ITeamDataFragment
  ) }
);

export type IEventDataFragment = (
  { __typename?: 'Event' }
  & Pick<IEvent, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'startTime'>
  & { season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<ISeason, 'objectiveID' | 'year' | 'startDate' | 'endDate' | 'type' | 'espnID' | 'label'>
  )>, league?: Maybe<(
    { __typename?: 'League' }
    & Pick<ILeague, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'abbreviation'>
  )>, competitions?: Maybe<Array<(
    { __typename?: 'Competition' }
    & ICompetitionDataFragment
  )>> }
);

export type ICompetitionDataFragment = (
  { __typename?: 'Competition' }
  & Pick<ICompetition, 'objectiveID' | 'espnUID' | 'espnID' | 'startTime' | 'completed' | 'label'>
  & { competitors?: Maybe<Array<(
    { __typename?: 'Competitor' }
    & ICompetitorDataFragment
  )>>, event: (
    { __typename?: 'Event' }
    & Pick<IEvent, 'objectiveID' | 'espnUID' | 'espnID' | 'label' | 'startTime'>
  ), polls?: Maybe<Array<(
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'totalVotes' | 'votingOpen' | 'pollResolved'>
    & { correctOption?: Maybe<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
    )> }
  )>> }
);

export type IOptionDataFragment = (
  { __typename?: 'Option' }
  & Pick<IOption, 'objectiveID' | 'correct' | 'voteCount'>
  & { poll?: Maybe<(
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'totalVotes' | 'votingOpen' | 'pollResolved'>
    & { correctOption?: Maybe<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
    )> }
  )>, entity: (
    { __typename?: 'Team' }
    & ITeamDataFragment
  ), outcome: (
    { __typename?: 'ResultOutcome' }
    & Pick<IResultOutcome, 'win'>
  ) }
);

export type IPollDataFragment = (
  { __typename?: 'Poll' }
  & Pick<IPoll, 'objectiveID' | 'totalVotes' | 'votingOpen' | 'pollResolved' | 'label'>
  & { correctOption?: Maybe<(
    { __typename?: 'Option' }
    & IOptionDataFragment
  )>, competition: (
    { __typename?: 'Competition' }
    & ICompetitionDataFragment
  ), options?: Maybe<Array<(
    { __typename?: 'Option' }
    & IOptionDataFragment
  )>>, pollResponses?: Maybe<Array<(
    { __typename?: 'PollResponse' }
    & IPollResponseDataFragment
  )>> }
);

export type IPollResponseDataFragment = (
  { __typename?: 'PollResponse' }
  & Pick<IPollResponse, 'objectiveID' | 'correct' | 'score' | 'dateCreated'>
  & { poll: (
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID'>
  ), voter: (
    { __typename?: 'User' }
    & IUserDataFragment
  ), vote: (
    { __typename?: 'Option' }
    & IOptionDataFragment
  ) }
);

export type IPostDataFragment = (
  { __typename?: 'Post' }
  & Pick<IPost, 'objectiveID' | 'content' | 'dateCreated'>
  & { tags?: Maybe<Array<(
    { __typename?: 'Article' }
    & IIEntityData_Article_Fragment
  ) | (
    { __typename?: 'Competition' }
    & IIEntityData_Competition_Fragment
  ) | (
    { __typename?: 'Sport' }
    & IIEntityData_Sport_Fragment
  ) | (
    { __typename?: 'PollResponse' }
    & IIEntityData_PollResponse_Fragment
  ) | (
    { __typename?: 'Event' }
    & IIEntityData_Event_Fragment
  ) | (
    { __typename?: 'League' }
    & IIEntityData_League_Fragment
  ) | (
    { __typename?: 'Poll' }
    & IIEntityData_Poll_Fragment
  ) | (
    { __typename?: 'Season' }
    & IIEntityData_Season_Fragment
  ) | (
    { __typename?: 'Option' }
    & IIEntityData_Option_Fragment
  ) | (
    { __typename?: 'Team' }
    & IIEntityData_Team_Fragment
  )>>, author: (
    { __typename?: 'User' }
    & IUserDataFragment
  ) }
);

type IIEntityData_Article_Fragment = (
  { __typename: 'Article' }
  & Pick<IArticle, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Competition_Fragment = (
  { __typename: 'Competition' }
  & Pick<ICompetition, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Sport_Fragment = (
  { __typename: 'Sport' }
  & Pick<ISport, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_PollResponse_Fragment = (
  { __typename: 'PollResponse' }
  & Pick<IPollResponse, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Event_Fragment = (
  { __typename: 'Event' }
  & Pick<IEvent, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_League_Fragment = (
  { __typename: 'League' }
  & Pick<ILeague, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Poll_Fragment = (
  { __typename: 'Poll' }
  & Pick<IPoll, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Season_Fragment = (
  { __typename: 'Season' }
  & Pick<ISeason, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Option_Fragment = (
  { __typename: 'Option' }
  & Pick<IOption, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

type IIEntityData_Team_Fragment = (
  { __typename: 'Team' }
  & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
);

export type IIEntityDataFragment = IIEntityData_Article_Fragment | IIEntityData_Competition_Fragment | IIEntityData_Sport_Fragment | IIEntityData_PollResponse_Fragment | IIEntityData_Event_Fragment | IIEntityData_League_Fragment | IIEntityData_Poll_Fragment | IIEntityData_Season_Fragment | IIEntityData_Option_Fragment | IIEntityData_Team_Fragment;

export type IArticleDataFragment = (
  { __typename?: 'Article' }
  & Pick<IArticle, 'objectiveID' | 'label' | 'link' | 'avatar' | 'datePublished'>
  & { tags?: Maybe<Array<(
    { __typename?: 'Article' }
    & IIEntityData_Article_Fragment
  ) | (
    { __typename?: 'Competition' }
    & IIEntityData_Competition_Fragment
  ) | (
    { __typename?: 'Sport' }
    & IIEntityData_Sport_Fragment
  ) | (
    { __typename?: 'PollResponse' }
    & IIEntityData_PollResponse_Fragment
  ) | (
    { __typename?: 'Event' }
    & IIEntityData_Event_Fragment
  ) | (
    { __typename?: 'League' }
    & IIEntityData_League_Fragment
  ) | (
    { __typename?: 'Poll' }
    & IIEntityData_Poll_Fragment
  ) | (
    { __typename?: 'Season' }
    & IIEntityData_Season_Fragment
  ) | (
    { __typename?: 'Option' }
    & IIEntityData_Option_Fragment
  ) | (
    { __typename?: 'Team' }
    & IIEntityData_Team_Fragment
  )>> }
);

export type IGetLandingStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetLandingStatsQuery = (
  { __typename?: 'Query' }
  & { tokensMinted?: Maybe<(
    { __typename?: 'TransactionAggregateResult' }
    & Pick<ITransactionAggregateResult, 'amountSum'>
  )>, tokensRewarded?: Maybe<(
    { __typename?: 'TransactionAggregateResult' }
    & Pick<ITransactionAggregateResult, 'amountSum'>
  )>, polls?: Maybe<(
    { __typename?: 'PollAggregateResult' }
    & Pick<IPollAggregateResult, 'totalVotesSum'>
  )>, openPolls?: Maybe<(
    { __typename?: 'PollAggregateResult' }
    & Pick<IPollAggregateResult, 'count'>
  )>, users?: Maybe<(
    { __typename?: 'UserAggregateResult' }
    & Pick<IUserAggregateResult, 'count'>
  )> }
);

export type IGetExamplePollQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetExamplePollQuery = (
  { __typename?: 'Query' }
  & { queryPoll?: Maybe<Array<Maybe<(
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID'>
  )>>> }
);

export type IGetAvatarsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetAvatarsQuery = (
  { __typename?: 'Query' }
  & { queryTeam?: Maybe<Array<Maybe<(
    { __typename?: 'Team' }
    & Pick<ITeam, 'avatar' | 'objectiveID' | 'shortLabel'>
  )>>> }
);

export type IAddArticlesMutationVariables = Exact<{
  input: Array<IAddArticleInput> | IAddArticleInput;
}>;


export type IAddArticlesMutation = (
  { __typename?: 'Mutation' }
  & { addArticle?: Maybe<(
    { __typename?: 'AddArticlePayload' }
    & { article?: Maybe<Array<Maybe<(
      { __typename?: 'Article' }
      & Pick<IArticle, 'objectiveID'>
    )>>> }
  )> }
);

export type IGetArticlesQueryVariables = Exact<{
  filter?: Maybe<IArticleFilter>;
}>;


export type IGetArticlesQuery = (
  { __typename?: 'Query' }
  & { queryArticle?: Maybe<Array<Maybe<(
    { __typename?: 'Article' }
    & Pick<IArticle, 'objectiveID' | 'label' | 'description' | 'link' | 'avatar'>
    & { tags?: Maybe<Array<(
      { __typename: 'Article' }
      & Pick<IArticle, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Sport' }
      & Pick<ISport, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'League' }
      & Pick<ILeague, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Season' }
      & Pick<ISeason, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Option' }
      & Pick<IOption, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Team' }
      & Pick<ITeam, 'objectiveID' | 'label' | 'avatar'>
    )>> }
  )>>> }
);

export type IAddPollsMutationVariables = Exact<{
  polls: Array<IAddPollInput> | IAddPollInput;
}>;


export type IAddPollsMutation = (
  { __typename?: 'Mutation' }
  & { addPoll?: Maybe<(
    { __typename?: 'AddPollPayload' }
    & { poll?: Maybe<Array<Maybe<(
      { __typename?: 'Poll' }
      & Pick<IPoll, 'dateCreated' | 'objectiveID' | 'label' | 'totalVotes' | 'votingOpen' | 'pollResolved'>
      & { competition: (
        { __typename?: 'Competition' }
        & Pick<ICompetition, 'objectiveID' | 'label' | 'completed' | 'startTime'>
        & { competitors?: Maybe<Array<(
          { __typename?: 'Competitor' }
          & Pick<ICompetitor, 'home'>
          & { entity: (
            { __typename?: 'Team' }
            & Pick<ITeam, 'objectiveID' | 'label'>
          ) }
        )>> }
      ), options?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<IOption, 'objectiveID'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'objectiveID' | 'label'>
        ), outcome: (
          { __typename?: 'ResultOutcome' }
          & Pick<IResultOutcome, 'win'>
        ) }
      )>> }
    )>>> }
  )> }
);

export type IUpdatePollMutationVariables = Exact<{
  input: IUpdatePollInput;
}>;


export type IUpdatePollMutation = (
  { __typename?: 'Mutation' }
  & { updatePoll?: Maybe<(
    { __typename?: 'UpdatePollPayload' }
    & { poll?: Maybe<Array<Maybe<(
      { __typename?: 'Poll' }
      & Pick<IPoll, 'objectiveID'>
    )>>> }
  )> }
);

export type IGetPollsQueryVariables = Exact<{
  filter?: Maybe<IPollFilter>;
}>;


export type IGetPollsQuery = (
  { __typename?: 'Query' }
  & { queryPoll?: Maybe<Array<Maybe<(
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'balance' | 'label' | 'totalVotes' | 'votingOpen' | 'pollResolved'>
    & { competition: (
      { __typename?: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'label' | 'completed' | 'startTime' | 'espnID' | 'espnUID'>
      & { competitors?: Maybe<Array<(
        { __typename?: 'Competitor' }
        & Pick<ICompetitor, 'home'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'objectiveID' | 'espnUID' | 'label'>
        ) }
      )>> }
    ), options?: Maybe<Array<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID' | 'voteCount' | 'correct'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'objectiveID' | 'espnUID' | 'label' | 'avatar' | 'shortLabel'>
      ), outcome: (
        { __typename?: 'ResultOutcome' }
        & Pick<IResultOutcome, 'win'>
      ) }
    )>>, correctOption?: Maybe<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
    )>, pollResponses?: Maybe<Array<(
      { __typename?: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'dateCreated'>
      & { voter: (
        { __typename?: 'User' }
        & Pick<IUser, 'username' | 'email'>
      ), vote: (
        { __typename?: 'Option' }
        & Pick<IOption, 'objectiveID'>
      ) }
    )>> }
  )>>> }
);

export type IAddPollResponseMutationVariables = Exact<{
  pollID: Scalars['ID'];
  optionID: Scalars['ID'];
  userID: Scalars['ID'];
}>;


export type IAddPollResponseMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'newPollResponse'>
);

export type IGetPollResponseQueryVariables = Exact<{
  filter?: Maybe<IPollResponseFilter>;
}>;


export type IGetPollResponseQuery = (
  { __typename?: 'Query' }
  & { queryPollResponse?: Maybe<Array<Maybe<(
    { __typename?: 'PollResponse' }
    & Pick<IPollResponse, 'objectiveID' | 'score' | 'correct'>
    & { voter: (
      { __typename?: 'User' }
      & Pick<IUser, 'username'>
    ), vote: (
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
    ) }
  )>>> }
);

export type IChangePollResponseMutationVariables = Exact<{
  pollResponseID: Scalars['ID'];
  newOptionID: Scalars['ID'];
}>;


export type IChangePollResponseMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'changePollResponse'>
);

export type IUpdatePollResponseMutationVariables = Exact<{
  input: IUpdatePollResponseInput;
}>;


export type IUpdatePollResponseMutation = (
  { __typename?: 'Mutation' }
  & { updatePollResponse?: Maybe<(
    { __typename?: 'UpdatePollResponsePayload' }
    & { pollResponse?: Maybe<Array<Maybe<(
      { __typename?: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID'>
      & { vote: (
        { __typename?: 'Option' }
        & Pick<IOption, 'objectiveID'>
        & { outcome: (
          { __typename?: 'ResultOutcome' }
          & Pick<IResultOutcome, 'win'>
        ) }
      ), voter: (
        { __typename?: 'User' }
        & Pick<IUser, 'username' | 'objectiveID' | 'email'>
      ) }
    )>>> }
  )> }
);

export type IDeletePollResponseMutationVariables = Exact<{
  pollResponseID: Scalars['ID'];
}>;


export type IDeletePollResponseMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'removePollResponse'>
);

export type IAddPostsMutationVariables = Exact<{
  posts: Array<IAddPostInput> | IAddPostInput;
}>;


export type IAddPostsMutation = (
  { __typename?: 'Mutation' }
  & { addPost?: Maybe<(
    { __typename?: 'AddPostPayload' }
    & { post?: Maybe<Array<Maybe<(
      { __typename?: 'Post' }
      & Pick<IPost, 'objectiveID' | 'content' | 'dateCreated'>
      & { author: (
        { __typename?: 'User' }
        & Pick<IUser, 'objectiveID' | 'username'>
      ) }
    )>>> }
  )> }
);

export type IGetPostsQueryVariables = Exact<{
  filter: IPostFilter;
}>;


export type IGetPostsQuery = (
  { __typename?: 'Query' }
  & { queryPost?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & Pick<IPost, 'objectiveID' | 'content' | 'dateCreated'>
    & { author: (
      { __typename?: 'User' }
      & Pick<IUser, 'objectiveID' | 'username' | 'avatar' | 'balance'>
    ), tags?: Maybe<Array<(
      { __typename: 'Article' }
      & Pick<IArticle, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Sport' }
      & Pick<ISport, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'League' }
      & Pick<ILeague, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Season' }
      & Pick<ISeason, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Option' }
      & Pick<IOption, 'objectiveID' | 'label' | 'avatar'>
    ) | (
      { __typename: 'Team' }
      & Pick<ITeam, 'objectiveID' | 'label' | 'avatar'>
    )>> }
  )>>> }
);

export type IGetTagsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type IGetTagsQuery = (
  { __typename?: 'Query' }
  & { queryIEntity?: Maybe<Array<Maybe<(
    { __typename: 'Article' }
    & Pick<IArticle, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Competition' }
    & Pick<ICompetition, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Sport' }
    & Pick<ISport, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'PollResponse' }
    & Pick<IPollResponse, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Event' }
    & Pick<IEvent, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'League' }
    & Pick<ILeague, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Season' }
    & Pick<ISeason, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Option' }
    & Pick<IOption, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  ) | (
    { __typename: 'Team' }
    & Pick<ITeam, 'objectiveID' | 'label' | 'shortLabel' | 'abbreviation' | 'avatar'>
  )>>> }
);

export type IAddSportsMutationVariables = Exact<{
  sports: Array<IAddSportInput> | IAddSportInput;
}>;


export type IAddSportsMutation = (
  { __typename?: 'Mutation' }
  & { addSport?: Maybe<(
    { __typename?: 'AddSportPayload' }
    & { sport?: Maybe<Array<Maybe<(
      { __typename?: 'Sport' }
      & Pick<ISport, 'espnID' | 'espnUID' | 'label' | 'objectiveID'>
    )>>> }
  )> }
);

export type IGetSportsQueryVariables = Exact<{
  filter?: Maybe<ISportFilter>;
}>;


export type IGetSportsQuery = (
  { __typename?: 'Query' }
  & { querySport?: Maybe<Array<Maybe<(
    { __typename?: 'Sport' }
    & Pick<ISport, 'objectiveID' | 'espnID' | 'espnUID' | 'label'>
  )>>> }
);

export type IGetLeaguesQueryVariables = Exact<{
  filter?: Maybe<ILeagueFilter>;
}>;


export type IGetLeaguesQuery = (
  { __typename?: 'Query' }
  & { queryLeague?: Maybe<Array<Maybe<(
    { __typename?: 'League' }
    & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation' | 'avatar'>
    & { sport: (
      { __typename?: 'Sport' }
      & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
    ), teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<ITeam, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'avatar'>
    )>> }
  )>>> }
);

export type IGetSeasonsQueryVariables = Exact<{
  filter?: Maybe<ISeasonFilter>;
}>;


export type IGetSeasonsQuery = (
  { __typename?: 'Query' }
  & { querySeason?: Maybe<Array<Maybe<(
    { __typename?: 'Season' }
    & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
    & { league: (
      { __typename?: 'League' }
      & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
      & { sport: (
        { __typename?: 'Sport' }
        & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
      ) }
    ) }
  )>>> }
);

export type IGetTeamsQueryVariables = Exact<{
  filter?: Maybe<ITeamFilter>;
}>;


export type IGetTeamsQuery = (
  { __typename?: 'Query' }
  & { queryTeam?: Maybe<Array<Maybe<(
    { __typename?: 'Team' }
    & Pick<ITeam, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'avatar'>
    & { league: (
      { __typename?: 'League' }
      & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
      & { sport: (
        { __typename?: 'Sport' }
        & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
      ) }
    ) }
  )>>> }
);

export type IAddEventsMutationVariables = Exact<{
  events: Array<IAddEventInput> | IAddEventInput;
}>;


export type IAddEventsMutation = (
  { __typename?: 'Mutation' }
  & { addEvent?: Maybe<(
    { __typename?: 'AddEventPayload' }
    & { event?: Maybe<Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'startTime'>
      & { season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
        & { league: (
          { __typename?: 'League' }
          & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
          & { sport: (
            { __typename?: 'Sport' }
            & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
          ) }
        ) }
      )> }
    )>>> }
  )> }
);

export type IUpdateEventMutationVariables = Exact<{
  event: IUpdateEventInput;
}>;


export type IUpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent?: Maybe<(
    { __typename?: 'UpdateEventPayload' }
    & { event?: Maybe<Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<IEvent, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'startTime'>
      & { season?: Maybe<(
        { __typename?: 'Season' }
        & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
        & { league: (
          { __typename?: 'League' }
          & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
          & { sport: (
            { __typename?: 'Sport' }
            & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
          ) }
        ) }
      )> }
    )>>> }
  )> }
);

export type IGetEventsQueryVariables = Exact<{
  filter?: Maybe<IEventFilter>;
}>;


export type IGetEventsQuery = (
  { __typename?: 'Query' }
  & { queryEvent?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<IEvent, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'startTime'>
    & { season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
      & { league: (
        { __typename?: 'League' }
        & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
        & { sport: (
          { __typename?: 'Sport' }
          & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
        ) }
      ) }
    )> }
  )>>> }
);

export type IGetCompetitionsQueryVariables = Exact<{
  filter?: Maybe<ICompetitionFilter>;
  pollFilter?: Maybe<IPollFilter>;
  leagueFilter?: Maybe<ILeagueFilter>;
}>;


export type IGetCompetitionsQuery = (
  { __typename?: 'Query' }
  & { queryCompetition?: Maybe<Array<Maybe<(
    { __typename?: 'Competition' }
    & Pick<ICompetition, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'completed' | 'startTime'>
    & { competitors?: Maybe<Array<(
      { __typename?: 'Competitor' }
      & Pick<ICompetitor, 'home'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'avatar' | 'label' | 'objectiveID' | 'espnID' | 'espnUID'>
      ) }
    )>>, polls?: Maybe<Array<(
      { __typename?: 'Poll' }
      & Pick<IPoll, 'objectiveID' | 'label' | 'pollResolved' | 'votingOpen' | 'totalVotes' | 'balance'>
      & { options?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<IOption, 'objectiveID' | 'correct' | 'voteCount'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'avatar' | 'label' | 'objectiveID' | 'espnID' | 'espnUID'>
        ), outcome: (
          { __typename?: 'ResultOutcome' }
          & Pick<IResultOutcome, 'win'>
        ) }
      )>>, correctOption?: Maybe<(
        { __typename?: 'Option' }
        & Pick<IOption, 'objectiveID'>
      )> }
    )>>, event: (
      { __typename?: 'Event' }
      & Pick<IEvent, 'objectiveID'>
      & { league?: Maybe<(
        { __typename?: 'League' }
        & Pick<ILeague, 'objectiveID'>
      )> }
    ) }
  )>>> }
);

export type IUpdateCompetitionMutationVariables = Exact<{
  competition: IUpdateCompetitionInput;
}>;


export type IUpdateCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetition?: Maybe<(
    { __typename?: 'UpdateCompetitionPayload' }
    & { competition?: Maybe<Array<Maybe<(
      { __typename?: 'Competition' }
      & Pick<ICompetition, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'completed' | 'startTime'>
      & { competitors?: Maybe<Array<(
        { __typename?: 'Competitor' }
        & Pick<ICompetitor, 'objectiveID'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'label' | 'objectiveID' | 'espnID' | 'espnUID'>
        ) }
      )>>, polls?: Maybe<Array<(
        { __typename?: 'Poll' }
        & Pick<IPoll, 'objectiveID' | 'label' | 'pollResolved' | 'votingOpen' | 'totalVotes'>
        & { options?: Maybe<Array<(
          { __typename?: 'Option' }
          & Pick<IOption, 'objectiveID'>
          & { entity: (
            { __typename?: 'Team' }
            & Pick<ITeam, 'label' | 'objectiveID' | 'espnID' | 'espnUID'>
          ), outcome: (
            { __typename?: 'ResultOutcome' }
            & Pick<IResultOutcome, 'win'>
          ) }
        )>>, correctOption?: Maybe<(
          { __typename?: 'Option' }
          & Pick<IOption, 'objectiveID'>
        )> }
      )>>, event: (
        { __typename?: 'Event' }
        & Pick<IEvent, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'startTime'>
        & { season?: Maybe<(
          { __typename?: 'Season' }
          & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
          & { league: (
            { __typename?: 'League' }
            & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
            & { sport: (
              { __typename?: 'Sport' }
              & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
            ) }
          ) }
        )> }
      ) }
    )>>> }
  )> }
);

export type IUpdateCompetitorMutationVariables = Exact<{
  competitor: IUpdateCompetitorInput;
}>;


export type IUpdateCompetitorMutation = (
  { __typename?: 'Mutation' }
  & { updateCompetitor?: Maybe<(
    { __typename?: 'UpdateCompetitorPayload' }
    & { competitor?: Maybe<Array<Maybe<(
      { __typename?: 'Competitor' }
      & Pick<ICompetitor, 'home' | 'winner'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'espnID' | 'espnUID' | 'objectiveID' | 'label'>
      ) }
    )>>> }
  )> }
);

export type IAddTransactionsMutationVariables = Exact<{
  transactions: Array<IAddTransactionInput> | IAddTransactionInput;
}>;


export type IAddTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { addTransaction?: Maybe<(
    { __typename?: 'AddTransactionPayload' }
    & { transaction?: Maybe<Array<Maybe<(
      { __typename?: 'Transaction' }
      & Pick<ITransaction, 'objectiveID' | 'amount' | 'type'>
      & { to: (
        { __typename?: 'Poll' }
        & Pick<IPoll, 'objectiveID'>
      ) | (
        { __typename?: 'User' }
        & Pick<IUser, 'objectiveID'>
      ) }
    )>>> }
  )> }
);

export type IAddUsersMutationVariables = Exact<{
  users: Array<IAddUserInput> | IAddUserInput;
}>;


export type IAddUsersMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'AddUserPayload' }
    & { user?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & IUserDataFragment
    )>>> }
  )> }
);

export type IGetUsersQueryVariables = Exact<{
  filter?: Maybe<IUserFilter>;
}>;


export type IGetUsersQuery = (
  { __typename?: 'Query' }
  & { queryUser?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & { settings?: Maybe<(
      { __typename?: 'UserSettings' }
      & Pick<IUserSettings, 'darkMode'>
    )>, pollResponses?: Maybe<Array<(
      { __typename?: 'PollResponse' }
      & Pick<IPollResponse, 'objectiveID' | 'score' | 'correct' | 'dateCreated'>
      & { poll: (
        { __typename?: 'Poll' }
        & Pick<IPoll, 'balance' | 'pollResolved' | 'objectiveID' | 'label'>
        & { competition: (
          { __typename?: 'Competition' }
          & Pick<ICompetition, 'startTime'>
        ) }
      ), vote: (
        { __typename?: 'Option' }
        & Pick<IOption, 'voteCount' | 'objectiveID'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'label'>
        ) }
      ) }
    )>>, posts?: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<IPost, 'objectiveID' | 'content' | 'dateCreated'>
    )>> }
    & IUserDataFragment
  )>>> }
);

export type IUpdateUserMutationVariables = Exact<{
  input: IUpdateUserInput;
}>;


export type IUpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & IUserDataFragment
    )>>> }
  )> }
);

export type IUpdateUserSettingsMutationVariables = Exact<{
  input: IUpdateUserSettingsInput;
}>;


export type IUpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UpdateUserSettingsPayload' }
    & { userSettings?: Maybe<Array<Maybe<(
      { __typename?: 'UserSettings' }
      & Pick<IUserSettings, 'objectiveID' | 'darkMode'>
    )>>> }
  )> }
);

export type IAddReferralsMutationVariables = Exact<{
  referrals: Array<IAddReferralInput> | IAddReferralInput;
}>;


export type IAddReferralsMutation = (
  { __typename?: 'Mutation' }
  & { addReferral?: Maybe<(
    { __typename?: 'AddReferralPayload' }
    & { referral?: Maybe<Array<Maybe<(
      { __typename?: 'Referral' }
      & Pick<IReferral, 'objectiveID' | 'ipAddress' | 'source'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<IUser, 'objectiveID'>
      )> }
    )>>> }
  )> }
);

export type IUpdateReferralsMutationVariables = Exact<{
  input: IUpdateReferralInput;
}>;


export type IUpdateReferralsMutation = (
  { __typename?: 'Mutation' }
  & { updateReferral?: Maybe<(
    { __typename?: 'UpdateReferralPayload' }
    & { referral?: Maybe<Array<Maybe<(
      { __typename?: 'Referral' }
      & Pick<IReferral, 'objectiveID' | 'ipAddress' | 'source'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<IUser, 'objectiveID'>
      )> }
    )>>> }
  )> }
);

export type IGetPollsToCloseQueryVariables = Exact<{
  filter?: Maybe<IPollFilter>;
  competitionFilter?: Maybe<ICompetitionFilter>;
}>;


export type IGetPollsToCloseQuery = (
  { __typename?: 'Query' }
  & { queryPoll?: Maybe<Array<Maybe<(
    { __typename?: 'Poll' }
    & Pick<IPoll, 'objectiveID' | 'label' | 'totalVotes'>
    & { options?: Maybe<Array<(
      { __typename?: 'Option' }
      & Pick<IOption, 'objectiveID'>
      & { entity: (
        { __typename?: 'Team' }
        & Pick<ITeam, 'espnUID'>
      ) }
    )>>, competition: (
      { __typename?: 'Competition' }
      & { competitors?: Maybe<Array<(
        { __typename?: 'Competitor' }
        & Pick<ICompetitor, 'objectiveID'>
        & { entity: (
          { __typename?: 'Team' }
          & Pick<ITeam, 'espnUID'>
        ) }
      )>>, event: (
        { __typename?: 'Event' }
        & Pick<IEvent, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'startTime'>
        & { season?: Maybe<(
          { __typename?: 'Season' }
          & Pick<ISeason, 'objectiveID' | 'espnID' | 'type' | 'year' | 'label'>
          & { league: (
            { __typename?: 'League' }
            & Pick<ILeague, 'objectiveID' | 'espnID' | 'espnUID' | 'label' | 'abbreviation'>
            & { sport: (
              { __typename?: 'Sport' }
              & Pick<ISport, 'label' | 'espnID' | 'espnUID' | 'objectiveID'>
            ) }
          ) }
        )> }
      ) }
    ) }
  )>>> }
);

export const TeamDataFragmentDoc = gql`
    fragment teamData on Team {
  objectiveID
  espnUID
  espnID
  label
  avatar
  league {
    objectiveID
    espnUID
    espnID
    label
    abbreviation
  }
}
    `;
export const CompetitorDataFragmentDoc = gql`
    fragment competitorData on Competitor {
  objectiveID
  entity {
    ...teamData
  }
  home
  winner
}
    ${TeamDataFragmentDoc}`;
export const CompetitionDataFragmentDoc = gql`
    fragment competitionData on Competition {
  objectiveID
  espnUID
  espnID
  startTime
  completed
  label
  competitors {
    ...competitorData
  }
  event {
    objectiveID
    espnUID
    espnID
    label
    startTime
  }
  polls {
    objectiveID
    totalVotes
    votingOpen
    pollResolved
    correctOption {
      objectiveID
    }
  }
}
    ${CompetitorDataFragmentDoc}`;
export const EventDataFragmentDoc = gql`
    fragment eventData on Event {
  objectiveID
  espnUID
  espnID
  label
  startTime
  season {
    objectiveID
    year
    startDate
    endDate
    type
    espnID
    label
  }
  league {
    objectiveID
    espnUID
    espnID
    label
    abbreviation
  }
  competitions {
    ...competitionData
  }
}
    ${CompetitionDataFragmentDoc}`;
export const SeasonDataFragmentDoc = gql`
    fragment seasonData on Season {
  objectiveID
  year
  startDate
  endDate
  type
  espnID
  label
  league {
    objectiveID
    espnID
    espnUID
    label
    abbreviation
  }
  events {
    ...eventData
  }
}
    ${EventDataFragmentDoc}`;
export const LeagueDataFragmentDoc = gql`
    fragment leagueData on League {
  objectiveID
  espnUID
  espnID
  label
  abbreviation
  sport {
    objectiveID
    espnUID
    espnID
    label
  }
  teams {
    ...teamData
  }
  seasons {
    ...seasonData
  }
}
    ${TeamDataFragmentDoc}
${SeasonDataFragmentDoc}`;
export const SportDataFragmentDoc = gql`
    fragment sportData on Sport {
  objectiveID
  espnUID
  espnID
  label
  leagues {
    ...leagueData
  }
}
    ${LeagueDataFragmentDoc}`;
export const OptionDataFragmentDoc = gql`
    fragment optionData on Option {
  objectiveID
  correct
  voteCount
  poll {
    objectiveID
    totalVotes
    votingOpen
    pollResolved
    correctOption {
      objectiveID
    }
  }
  entity {
    ...teamData
  }
  outcome {
    win
  }
}
    ${TeamDataFragmentDoc}`;
export const UserDataFragmentDoc = gql`
    fragment userData on User {
  objectiveID
  username
  email
  totalVotes
  correctVotes
  balance
  dateCreated
  avatar
}
    `;
export const PollResponseDataFragmentDoc = gql`
    fragment pollResponseData on PollResponse {
  objectiveID
  poll {
    objectiveID
  }
  voter {
    ...userData
  }
  vote {
    ...optionData
  }
  correct
  score
  dateCreated
}
    ${UserDataFragmentDoc}
${OptionDataFragmentDoc}`;
export const PollDataFragmentDoc = gql`
    fragment pollData on Poll {
  objectiveID
  totalVotes
  votingOpen
  pollResolved
  correctOption {
    ...optionData
  }
  competition {
    ...competitionData
  }
  label
  options {
    ...optionData
  }
  pollResponses {
    ...pollResponseData
  }
}
    ${OptionDataFragmentDoc}
${CompetitionDataFragmentDoc}
${PollResponseDataFragmentDoc}`;
export const IEntityDataFragmentDoc = gql`
    fragment iEntityData on IEntity {
  __typename
  objectiveID
  label
  shortLabel
  abbreviation
  avatar
}
    `;
export const PostDataFragmentDoc = gql`
    fragment postData on Post {
  objectiveID
  content
  tags {
    ...iEntityData
  }
  author {
    ...userData
  }
  dateCreated
}
    ${IEntityDataFragmentDoc}
${UserDataFragmentDoc}`;
export const ArticleDataFragmentDoc = gql`
    fragment articleData on Article {
  objectiveID
  label
  link
  avatar
  datePublished
  tags {
    ...iEntityData
  }
}
    ${IEntityDataFragmentDoc}`;
export const GetFeedDocument = gql`
    query GetFeed($first: Int = 50, $offset: Int = 0, $postFilter: PostFilter, $userFilter: UserFilter, $pollFilter: PollFilter, $competitionFilter: CompetitionFilter, $teamFilter: TeamFilter, $pollResponseFilter: PollResponseFilter, $tagFilter: IEntityFilter, $articleFilter: ArticleFilter, $leagueFilter: LeagueFilter) {
  queryPost(
    first: $first
    offset: $offset
    filter: $postFilter
    order: {desc: dateCreated}
  ) @cascade(fields: ["author", "tags"]) {
    __typename
    objectiveID
    dateCreated
    content
    author(filter: $userFilter) @cascade(fields: ["objectiveID"]) {
      objectiveID
      username
      avatar
    }
    tags(filter: $tagFilter) @cascade(fields: ["objectiveID"]) {
      __typename
      objectiveID
      label
      shortLabel
      abbreviation
      avatar
    }
  }
  queryPoll(
    first: $first
    offset: $offset
    filter: $pollFilter
    order: {desc: dateCreated, then: {desc: totalVotes}}
  ) @cascade(fields: ["competition", "pollResponses"]) {
    __typename
    objectiveID
    label
    balance
    competition(filter: $competitionFilter) @cascade(fields: ["competitors", "event"]) {
      startTime
      objectiveID
      label
      espnID
      event @cascade(fields: "league") {
        league(filter: $leagueFilter) {
          objectiveID
        }
      }
      competitors @cascade(fields: ["entity"]) {
        entity(filter: $teamFilter) {
          objectiveID
          label
          shortLabel
          avatar
        }
      }
    }
    options {
      objectiveID
      voteCount
      correct
      entity {
        label
        objectiveID
        espnID
        espnUID
        avatar
      }
      outcome {
        win
      }
    }
    totalVotes
    votingOpen
    pollResolved
    correctOption {
      objectiveID
    }
    pollResponses @cascade(fields: ["voter"]) {
      objectiveID
      voter(filter: $userFilter) {
        objectiveID
      }
    }
  }
  queryPollResponse(
    first: $first
    offset: $offset
    filter: $pollResponseFilter
    order: {desc: dateCreated}
  ) @cascade(fields: ["voter", "vote", "poll"]) {
    __typename
    voter(filter: $userFilter) @cascade(fields: ["objectiveID"]) {
      objectiveID
      username
      avatar
    }
    dateCreated
    vote @cascade(fields: ["entity"]) {
      voteCount
      objectiveID
      entity(filter: $teamFilter) @cascade(fields: ["objectiveID"]) {
        espnUID
        espnID
        objectiveID
        label
        avatar
      }
    }
    poll(filter: $pollFilter) @cascade(fields: ["competition"]) {
      objectiveID
      label
      dateCreated
      totalVotes
      balance
      competition(filter: $competitionFilter) @cascade(fields: ["competitors", "event"]) {
        espnID
        startTime
        objectiveID
        label
        event @cascade(fields: "league") {
          league(filter: $leagueFilter) {
            objectiveID
          }
        }
        competitors @cascade(fields: ["entity"]) {
          entity(filter: $teamFilter) @cascade(fields: ["objectiveID"]) {
            objectiveID
            label
            shortLabel
            avatar
          }
        }
      }
    }
  }
  queryArticle(
    first: $first
    offset: $offset
    filter: $articleFilter
    order: {desc: datePublished}
  ) @cascade(fields: ["tags"]) {
    __typename
    objectiveID
    label
    description
    avatar
    datePublished
    link
    tags(filter: $tagFilter) @cascade(fields: ["objectiveID"]) {
      __typename
      objectiveID
      label
      shortLabel
      abbreviation
      avatar
    }
  }
}
    `;
export const GetLandingStatsDocument = gql`
    query GetLandingStats {
  tokensMinted: aggregateTransaction(filter: {type: {eq: TOKEN_MINTING}}) {
    amountSum
  }
  tokensRewarded: aggregateTransaction(filter: {type: {eq: POLL_REWARD}}) {
    amountSum
  }
  polls: aggregatePoll {
    totalVotesSum
  }
  openPolls: aggregatePoll(filter: {pollResolved: false}) {
    count
  }
  users: aggregateUser {
    count
  }
}
    `;
export const GetExamplePollDocument = gql`
    query GetExamplePoll {
  queryPoll(
    filter: {pollResolved: false}
    order: {desc: totalVotes, then: {asc: dateCreated}}
    first: 1
  ) {
    objectiveID
  }
}
    `;
export const GetAvatarsDocument = gql`
    query GetAvatars {
  queryTeam {
    avatar
    objectiveID
    shortLabel
  }
}
    `;
export const AddArticlesDocument = gql`
    mutation AddArticles($input: [AddArticleInput!]!) {
  addArticle(input: $input) {
    article {
      objectiveID
    }
  }
}
    `;
export const GetArticlesDocument = gql`
    query GetArticles($filter: ArticleFilter) {
  queryArticle(filter: $filter) {
    objectiveID
    label
    description
    link
    avatar
    tags {
      __typename
      objectiveID
      label
      avatar
    }
  }
}
    `;
export const AddPollsDocument = gql`
    mutation AddPolls($polls: [AddPollInput!]!) {
  addPoll(input: $polls) {
    poll {
      dateCreated
      objectiveID
      competition {
        objectiveID
        label
        completed
        startTime
        competitors {
          entity {
            objectiveID
            label
          }
          home
        }
      }
      label
      options {
        objectiveID
        entity {
          objectiveID
          label
        }
        outcome {
          win
        }
      }
      totalVotes
      votingOpen
      pollResolved
    }
  }
}
    `;
export const UpdatePollDocument = gql`
    mutation UpdatePoll($input: UpdatePollInput!) {
  updatePoll(input: $input) {
    poll {
      objectiveID
    }
  }
}
    `;
export const GetPollsDocument = gql`
    query GetPolls($filter: PollFilter) {
  queryPoll(filter: $filter) {
    objectiveID
    balance
    competition {
      objectiveID
      label
      completed
      startTime
      espnID
      espnUID
      competitors {
        entity {
          objectiveID
          espnUID
          label
        }
        home
      }
    }
    label
    options {
      objectiveID
      voteCount
      entity {
        objectiveID
        espnUID
        label
        avatar
        shortLabel
      }
      outcome {
        win
      }
      correct
    }
    totalVotes
    votingOpen
    pollResolved
    correctOption {
      objectiveID
    }
    pollResponses {
      objectiveID
      dateCreated
      voter {
        username
        email
      }
      vote {
        objectiveID
      }
    }
  }
}
    `;
export const AddPollResponseDocument = gql`
    mutation AddPollResponse($pollID: ID!, $optionID: ID!, $userID: ID!) {
  newPollResponse(pollID: $pollID, optionID: $optionID, userID: $userID)
}
    `;
export const GetPollResponseDocument = gql`
    query GetPollResponse($filter: PollResponseFilter) {
  queryPollResponse(filter: $filter) {
    objectiveID
    voter {
      username
    }
    vote {
      objectiveID
    }
    score
    correct
  }
}
    `;
export const ChangePollResponseDocument = gql`
    mutation ChangePollResponse($pollResponseID: ID!, $newOptionID: ID!) {
  changePollResponse(pollResponseID: $pollResponseID, newOptionID: $newOptionID)
}
    `;
export const UpdatePollResponseDocument = gql`
    mutation UpdatePollResponse($input: UpdatePollResponseInput!) {
  updatePollResponse(input: $input) {
    pollResponse {
      objectiveID
      vote {
        objectiveID
        outcome {
          win
        }
      }
      voter {
        username
        objectiveID
        email
      }
    }
  }
}
    `;
export const DeletePollResponseDocument = gql`
    mutation DeletePollResponse($pollResponseID: ID!) {
  removePollResponse(pollResponseID: $pollResponseID)
}
    `;
export const AddPostsDocument = gql`
    mutation AddPosts($posts: [AddPostInput!]!) {
  addPost(input: $posts) {
    post {
      objectiveID
      content
      dateCreated
      author {
        objectiveID
        username
      }
    }
  }
}
    `;
export const GetPostsDocument = gql`
    query GetPosts($filter: PostFilter!) {
  queryPost(filter: $filter) {
    objectiveID
    content
    dateCreated
    author {
      objectiveID
      username
      avatar
      balance
    }
    tags {
      __typename
      objectiveID
      label
      avatar
    }
  }
}
    `;
export const GetTagsDocument = gql`
    query GetTags($query: String) {
  queryIEntity(
    filter: {label: {regexp: $query}, or: [{shortLabel: {regexp: $query}}, {abbreviation: {regexp: $query}}]}
  ) {
    __typename
    objectiveID
    label
    shortLabel
    abbreviation
    avatar
  }
}
    `;
export const AddSportsDocument = gql`
    mutation AddSports($sports: [AddSportInput!]!) {
  addSport(input: $sports) {
    sport {
      espnID
      espnUID
      label
      objectiveID
    }
  }
}
    `;
export const GetSportsDocument = gql`
    query GetSports($filter: SportFilter) {
  querySport(filter: $filter) {
    objectiveID
    espnID
    espnUID
    label
  }
}
    `;
export const GetLeaguesDocument = gql`
    query GetLeagues($filter: LeagueFilter) {
  queryLeague(filter: $filter) {
    objectiveID
    espnID
    espnUID
    label
    abbreviation
    avatar
    sport {
      label
      espnID
      espnUID
      objectiveID
    }
    teams {
      objectiveID
      espnID
      espnUID
      label
      avatar
    }
  }
}
    `;
export const GetSeasonsDocument = gql`
    query GetSeasons($filter: SeasonFilter) {
  querySeason(filter: $filter) {
    objectiveID
    espnID
    type
    year
    label
    league {
      objectiveID
      espnID
      espnUID
      label
      abbreviation
      sport {
        label
        espnID
        espnUID
        objectiveID
      }
    }
  }
}
    `;
export const GetTeamsDocument = gql`
    query GetTeams($filter: TeamFilter) {
  queryTeam(filter: $filter) {
    objectiveID
    espnID
    espnUID
    label
    league {
      objectiveID
      espnID
      espnUID
      label
      abbreviation
      sport {
        label
        espnID
        espnUID
        objectiveID
      }
    }
    avatar
  }
}
    `;
export const AddEventsDocument = gql`
    mutation AddEvents($events: [AddEventInput!]!) {
  addEvent(input: $events) {
    event {
      objectiveID
      espnID
      espnUID
      label
      startTime
      season {
        objectiveID
        espnID
        type
        year
        label
        league {
          objectiveID
          espnID
          espnUID
          label
          abbreviation
          sport {
            label
            espnID
            espnUID
            objectiveID
          }
        }
      }
    }
  }
}
    `;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: UpdateEventInput!) {
  updateEvent(input: $event) {
    event {
      objectiveID
      espnID
      espnUID
      label
      startTime
      season {
        objectiveID
        espnID
        type
        year
        label
        league {
          objectiveID
          espnID
          espnUID
          label
          abbreviation
          sport {
            label
            espnID
            espnUID
            objectiveID
          }
        }
      }
    }
  }
}
    `;
export const GetEventsDocument = gql`
    query GetEvents($filter: EventFilter) {
  queryEvent(filter: $filter) {
    objectiveID
    espnID
    espnUID
    label
    startTime
    season {
      objectiveID
      espnID
      type
      year
      label
      league {
        objectiveID
        espnID
        espnUID
        label
        abbreviation
        sport {
          label
          espnID
          espnUID
          objectiveID
        }
      }
    }
  }
}
    `;
export const GetCompetitionsDocument = gql`
    query GetCompetitions($filter: CompetitionFilter, $pollFilter: PollFilter, $leagueFilter: LeagueFilter) {
  queryCompetition(filter: $filter) @cascade(fields: ["event"]) {
    objectiveID
    espnID
    espnUID
    label
    completed
    competitors {
      home
      entity {
        avatar
        label
        objectiveID
        espnID
        espnUID
      }
    }
    startTime
    polls(filter: $pollFilter) {
      objectiveID
      label
      options {
        objectiveID
        correct
        entity {
          avatar
          label
          objectiveID
          espnID
          espnUID
        }
        outcome {
          win
        }
        voteCount
      }
      pollResolved
      votingOpen
      totalVotes
      balance
      correctOption {
        objectiveID
      }
    }
    event @cascade {
      objectiveID
      league(filter: $leagueFilter) {
        objectiveID
      }
    }
  }
}
    `;
export const UpdateCompetitionDocument = gql`
    mutation UpdateCompetition($competition: UpdateCompetitionInput!) {
  updateCompetition(input: $competition) {
    competition {
      objectiveID
      espnID
      espnUID
      label
      completed
      competitors {
        objectiveID
        entity {
          label
          objectiveID
          espnID
          espnUID
        }
      }
      startTime
      polls {
        objectiveID
        label
        options {
          objectiveID
          entity {
            label
            objectiveID
            espnID
            espnUID
          }
          outcome {
            win
          }
        }
        pollResolved
        votingOpen
        totalVotes
        correctOption {
          objectiveID
        }
      }
      event {
        objectiveID
        espnID
        espnUID
        label
        startTime
        season {
          objectiveID
          espnID
          type
          year
          label
          league {
            objectiveID
            espnID
            espnUID
            label
            abbreviation
            sport {
              label
              espnID
              espnUID
              objectiveID
            }
          }
        }
      }
    }
  }
}
    `;
export const UpdateCompetitorDocument = gql`
    mutation UpdateCompetitor($competitor: UpdateCompetitorInput!) {
  updateCompetitor(input: $competitor) {
    competitor {
      entity {
        espnID
        espnUID
        objectiveID
        label
      }
      home
      winner
    }
  }
}
    `;
export const AddTransactionsDocument = gql`
    mutation AddTransactions($transactions: [AddTransactionInput!]!) {
  addTransaction(input: $transactions) {
    transaction {
      objectiveID
      amount
      to {
        objectiveID
      }
      type
    }
  }
}
    `;
export const AddUsersDocument = gql`
    mutation AddUsers($users: [AddUserInput!]!) {
  addUser(input: $users) {
    user {
      ...userData
    }
  }
}
    ${UserDataFragmentDoc}`;
export const GetUsersDocument = gql`
    query GetUsers($filter: UserFilter) {
  queryUser(filter: $filter, order: {desc: balance, then: {desc: correctVotes}}) {
    ...userData
    settings {
      darkMode
    }
    pollResponses {
      poll {
        balance
        pollResolved
        competition {
          startTime
        }
        objectiveID
        label
      }
      objectiveID
      vote {
        voteCount
        objectiveID
        entity {
          label
        }
      }
      poll {
        objectiveID
        label
      }
      score
      correct
      dateCreated
    }
    posts {
      objectiveID
      content
      dateCreated
    }
  }
}
    ${UserDataFragmentDoc}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...userData
    }
  }
}
    ${UserDataFragmentDoc}`;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($input: UpdateUserSettingsInput!) {
  updateUserSettings(input: $input) {
    userSettings {
      objectiveID
      darkMode
    }
  }
}
    `;
export const AddReferralsDocument = gql`
    mutation AddReferrals($referrals: [AddReferralInput!]!) {
  addReferral(input: $referrals) {
    referral {
      objectiveID
      ipAddress
      user {
        objectiveID
      }
      source
    }
  }
}
    `;
export const UpdateReferralsDocument = gql`
    mutation UpdateReferrals($input: UpdateReferralInput!) {
  updateReferral(input: $input) {
    referral {
      objectiveID
      ipAddress
      user {
        objectiveID
      }
      source
    }
  }
}
    `;
export const GetPollsToCloseDocument = gql`
    query GetPollsToClose($filter: PollFilter, $competitionFilter: CompetitionFilter) {
  queryPoll(filter: $filter) @cascade {
    objectiveID
    label
    totalVotes
    options {
      objectiveID
      entity {
        espnUID
      }
    }
    competition(filter: $competitionFilter) {
      competitors {
        objectiveID
        entity {
          espnUID
        }
      }
      event {
        objectiveID
        espnID
        espnUID
        label
        startTime
        season {
          objectiveID
          espnID
          type
          year
          label
          league {
            objectiveID
            espnID
            espnUID
            label
            abbreviation
            sport {
              label
              espnID
              espnUID
              objectiveID
            }
          }
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetFeed(variables?: IGetFeedQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetFeedQuery> {
      return withWrapper(() => client.request<IGetFeedQuery>(print(GetFeedDocument), variables, requestHeaders));
    },
    GetLandingStats(variables?: IGetLandingStatsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetLandingStatsQuery> {
      return withWrapper(() => client.request<IGetLandingStatsQuery>(print(GetLandingStatsDocument), variables, requestHeaders));
    },
    GetExamplePoll(variables?: IGetExamplePollQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetExamplePollQuery> {
      return withWrapper(() => client.request<IGetExamplePollQuery>(print(GetExamplePollDocument), variables, requestHeaders));
    },
    GetAvatars(variables?: IGetAvatarsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetAvatarsQuery> {
      return withWrapper(() => client.request<IGetAvatarsQuery>(print(GetAvatarsDocument), variables, requestHeaders));
    },
    AddArticles(variables: IAddArticlesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddArticlesMutation> {
      return withWrapper(() => client.request<IAddArticlesMutation>(print(AddArticlesDocument), variables, requestHeaders));
    },
    GetArticles(variables?: IGetArticlesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetArticlesQuery> {
      return withWrapper(() => client.request<IGetArticlesQuery>(print(GetArticlesDocument), variables, requestHeaders));
    },
    AddPolls(variables: IAddPollsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddPollsMutation> {
      return withWrapper(() => client.request<IAddPollsMutation>(print(AddPollsDocument), variables, requestHeaders));
    },
    UpdatePoll(variables: IUpdatePollMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdatePollMutation> {
      return withWrapper(() => client.request<IUpdatePollMutation>(print(UpdatePollDocument), variables, requestHeaders));
    },
    GetPolls(variables?: IGetPollsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetPollsQuery> {
      return withWrapper(() => client.request<IGetPollsQuery>(print(GetPollsDocument), variables, requestHeaders));
    },
    AddPollResponse(variables: IAddPollResponseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddPollResponseMutation> {
      return withWrapper(() => client.request<IAddPollResponseMutation>(print(AddPollResponseDocument), variables, requestHeaders));
    },
    GetPollResponse(variables?: IGetPollResponseQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetPollResponseQuery> {
      return withWrapper(() => client.request<IGetPollResponseQuery>(print(GetPollResponseDocument), variables, requestHeaders));
    },
    ChangePollResponse(variables: IChangePollResponseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IChangePollResponseMutation> {
      return withWrapper(() => client.request<IChangePollResponseMutation>(print(ChangePollResponseDocument), variables, requestHeaders));
    },
    UpdatePollResponse(variables: IUpdatePollResponseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdatePollResponseMutation> {
      return withWrapper(() => client.request<IUpdatePollResponseMutation>(print(UpdatePollResponseDocument), variables, requestHeaders));
    },
    DeletePollResponse(variables: IDeletePollResponseMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IDeletePollResponseMutation> {
      return withWrapper(() => client.request<IDeletePollResponseMutation>(print(DeletePollResponseDocument), variables, requestHeaders));
    },
    AddPosts(variables: IAddPostsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddPostsMutation> {
      return withWrapper(() => client.request<IAddPostsMutation>(print(AddPostsDocument), variables, requestHeaders));
    },
    GetPosts(variables: IGetPostsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetPostsQuery> {
      return withWrapper(() => client.request<IGetPostsQuery>(print(GetPostsDocument), variables, requestHeaders));
    },
    GetTags(variables?: IGetTagsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetTagsQuery> {
      return withWrapper(() => client.request<IGetTagsQuery>(print(GetTagsDocument), variables, requestHeaders));
    },
    AddSports(variables: IAddSportsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddSportsMutation> {
      return withWrapper(() => client.request<IAddSportsMutation>(print(AddSportsDocument), variables, requestHeaders));
    },
    GetSports(variables?: IGetSportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetSportsQuery> {
      return withWrapper(() => client.request<IGetSportsQuery>(print(GetSportsDocument), variables, requestHeaders));
    },
    GetLeagues(variables?: IGetLeaguesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetLeaguesQuery> {
      return withWrapper(() => client.request<IGetLeaguesQuery>(print(GetLeaguesDocument), variables, requestHeaders));
    },
    GetSeasons(variables?: IGetSeasonsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetSeasonsQuery> {
      return withWrapper(() => client.request<IGetSeasonsQuery>(print(GetSeasonsDocument), variables, requestHeaders));
    },
    GetTeams(variables?: IGetTeamsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetTeamsQuery> {
      return withWrapper(() => client.request<IGetTeamsQuery>(print(GetTeamsDocument), variables, requestHeaders));
    },
    AddEvents(variables: IAddEventsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddEventsMutation> {
      return withWrapper(() => client.request<IAddEventsMutation>(print(AddEventsDocument), variables, requestHeaders));
    },
    UpdateEvent(variables: IUpdateEventMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateEventMutation> {
      return withWrapper(() => client.request<IUpdateEventMutation>(print(UpdateEventDocument), variables, requestHeaders));
    },
    GetEvents(variables?: IGetEventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetEventsQuery> {
      return withWrapper(() => client.request<IGetEventsQuery>(print(GetEventsDocument), variables, requestHeaders));
    },
    GetCompetitions(variables?: IGetCompetitionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetCompetitionsQuery> {
      return withWrapper(() => client.request<IGetCompetitionsQuery>(print(GetCompetitionsDocument), variables, requestHeaders));
    },
    UpdateCompetition(variables: IUpdateCompetitionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateCompetitionMutation> {
      return withWrapper(() => client.request<IUpdateCompetitionMutation>(print(UpdateCompetitionDocument), variables, requestHeaders));
    },
    UpdateCompetitor(variables: IUpdateCompetitorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateCompetitorMutation> {
      return withWrapper(() => client.request<IUpdateCompetitorMutation>(print(UpdateCompetitorDocument), variables, requestHeaders));
    },
    AddTransactions(variables: IAddTransactionsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddTransactionsMutation> {
      return withWrapper(() => client.request<IAddTransactionsMutation>(print(AddTransactionsDocument), variables, requestHeaders));
    },
    AddUsers(variables: IAddUsersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddUsersMutation> {
      return withWrapper(() => client.request<IAddUsersMutation>(print(AddUsersDocument), variables, requestHeaders));
    },
    GetUsers(variables?: IGetUsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetUsersQuery> {
      return withWrapper(() => client.request<IGetUsersQuery>(print(GetUsersDocument), variables, requestHeaders));
    },
    UpdateUser(variables: IUpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateUserMutation> {
      return withWrapper(() => client.request<IUpdateUserMutation>(print(UpdateUserDocument), variables, requestHeaders));
    },
    UpdateUserSettings(variables: IUpdateUserSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateUserSettingsMutation> {
      return withWrapper(() => client.request<IUpdateUserSettingsMutation>(print(UpdateUserSettingsDocument), variables, requestHeaders));
    },
    AddReferrals(variables: IAddReferralsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IAddReferralsMutation> {
      return withWrapper(() => client.request<IAddReferralsMutation>(print(AddReferralsDocument), variables, requestHeaders));
    },
    UpdateReferrals(variables: IUpdateReferralsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IUpdateReferralsMutation> {
      return withWrapper(() => client.request<IUpdateReferralsMutation>(print(UpdateReferralsDocument), variables, requestHeaders));
    },
    GetPollsToClose(variables?: IGetPollsToCloseQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IGetPollsToCloseQuery> {
      return withWrapper(() => client.request<IGetPollsToCloseQuery>(print(GetPollsToCloseDocument), variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;