import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useState } from "react";
import { useGetUsersQuery } from "../apis/generated/react";
import { IUser, IAddUserInput, IPollResponse } from "../apis/generated/sdk";
import { dgraphGateway } from "../apis/dgraph";
import { gaphqlClientManager } from "../graphql/clientManager";
import { getDateTime } from '../graphql/helpers';
import LogRocket from 'logrocket';

type ContextProps = {
    children: React.ReactNode
};

export const UserContext = createContext<{ user: IUser, userPollResponseMap: { [pollId: string]: IPollResponse } }>(null);

const UserProvider = ({ children }: ContextProps) => {
    const [user, setUser]: [IUser, (user: IUser) => void] = useState(null);
    const [userPollResponseMap, setUserPollResponseMap]: [{ [pollId: string]: IPollResponse }, (user: { [pollId: string]: IPollResponse }) => void] = useState({});

    const { user: auth0user, isAuthenticated, getIdTokenClaims } = useAuth0();

    const { refetch } = useGetUsersQuery(gaphqlClientManager.client, { filter: { email: { eq: auth0user?.email } } }, {
        onSuccess: async (d) => {
            if (!isAuthenticated) {
                setUser(null);
            } else {
                let user: IUser = null;
                if (d.queryUser.length > 0) {
                    user = d.queryUser[0] as IUser
                } else {
                    const newUserInput: IAddUserInput = {
                        email: auth0user?.email,
                        username: auth0user?.nickname,
                        totalVotes: 0,
                        correctVotes: 0,
                        balance: 0.0,
                        pollResponses: [],
                        dateCreated: getDateTime(),
                        avatar: auth0user?.picture,
                        settings: {darkMode: false}
                    }

                    const newUser = await dgraphGateway.sdk.AddUsers({ users: newUserInput });
                    if (newUser?.addUser?.user[0]) {
                        user = newUser?.addUser?.user[0];
                    }
                };

                if (user) {
                    setUser(user);
                    LogRocket.identify(user?.username);
                }

                const token = await getIdTokenClaims();
                if (token) {
                    gaphqlClientManager.resetToken(token.__raw);
                }
            }
        },
        onError: (e) => { console.log('error', e) }
    })

    useEffect(() => {
        if (user && user.pollResponses) {
            const newPollMap: { [pollId: string]: IPollResponse } = {}
            user.pollResponses.forEach(pr => {
                newPollMap[pr.poll.objectiveID] = pr
            })
            setUserPollResponseMap(newPollMap)
        } else {
            setUserPollResponseMap({})
        }
    }, [user])

    useEffect(() => {
        refetch()
    }, [isAuthenticated])

    return (
        <UserContext.Provider value={{ user, userPollResponseMap }}> {children} </UserContext.Provider>
    );
}

export default UserProvider;