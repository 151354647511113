import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router';
import { useGetUsersQuery } from '../../apis/generated/react';
import { IUser } from '../../apis/generated/sdk';
import { gaphqlClientManager } from '../../graphql/clientManager';
import Leaderboard from './LeaderboardView';

export default function Analysts() {
    const [user, setUser] = useState<IUser>(null)

    const { status, data, error, isFetching } = useGetUsersQuery(gaphqlClientManager.client, {});

    const navigate = useNavigate()
    let users: IUser[] = []
    if (status === "success") {
        users = (data.queryUser as IUser[])
        // users = [...users, ...users, ...users, ...users, ...users, ...users];
    }

    return (
        <Leaderboard analysts={users} selectUser={(u) => {
            setUser(u)
            navigate(`/user/${u.objectiveID}`);
        }} />
    );
}