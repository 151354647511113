import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import { UserContext } from "../../providers/UserProvider";
import { IArticle, ICompetition, IGetFeedQuery, IPoll, IPollResponse, IPost, useGetFeedQuery } from "../../apis/generated/react";
import PollFeedItem from "./FeedItems/PollFeedItem";
import PollResponseFeedItem from "./FeedItems/PollResponseFeedItem";
import ArticleFeedItem from "./FeedItems/ArticleFeedItem";
import PostFeedItem from "./FeedItems/PostFeedItem";

interface FeedItemTriageProps {
    item: IPoll | IPollResponse | IArticle | IPost
}

function FeedItemTriage({ item }: FeedItemTriageProps) {
  return <>
    {item.__typename === 'Poll' ?
        <PollFeedItem poll={item} />
    : item.__typename === "PollResponse" ?
        <PollResponseFeedItem pollResponse={item}/>
    : item.__typename === "Article" ? 
      <ArticleFeedItem article={item} />
    : item.__typename === "Post" ? 
      <PostFeedItem post={item} />
    : null
    }
  </>;
}

export default FeedItemTriage;
