import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import moment from 'moment';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    }
  }),
);


interface DateSelectProps {
  date: Date
  setDate: (date: Date) => void
}

export default function DateSelect({ date, setDate }: DateSelectProps) {
  const classes = useStyles();

  const mDate = moment(date)

  const dates = [
    mDate.clone().subtract(2, 'days'),
    mDate.clone().subtract(1, 'days'),
    mDate,
    mDate.clone().add(1, 'days'),
    mDate.clone().add(2, 'days'),
  ]

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={5} cellHeight={60}>
        {/* <Button onClick={() => setDate(new Date())} variant="contained">
          Today
        </Button> */}
        {dates.map((d, i) => (
          <Button
            key={i}
            variant={mDate.isSame(d) ? "contained" : "text"} onClick={() => setDate(d.toDate())}>
            {d.calendar(null, {
              lastDay: '[Yesterday]',
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              lastWeek: '[last] dddd',
              nextWeek: 'dddd',
              sameElse: 'L'
            })}
          </Button>
        ))}


      </GridList>
    </div>
  );
}