import { CONSTANTS } from '../constants';
import { GraphqlClientManager } from '../graphql/clientManager';
import { DqlClientManager } from '../dql/clientManager';
import { getSdk, Sdk } from './generated/sdk';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || CONSTANTS.GRAPHQL_ENDPOINT;
const DGRAPH_CLIENT_KEY = process.env.REACT_APP_DGRAPH_CLIENT_KEY || CONSTANTS.DGRAPH_CLIENT_KEY;

class DgraphGateway {
    gqlClientManager: GraphqlClientManager;
    dqlClientManager: DqlClientManager;
    sdk: Sdk;

    constructor(token?: string, endpoint?: string) {
        this.gqlClientManager = new GraphqlClientManager(token, endpoint);
        this.dqlClientManager = new DqlClientManager(DGRAPH_CLIENT_KEY, endpoint.replace('/graphql', ''));
        this.sdk = getSdk(this.gqlClientManager.client);
    }

    resetToken(token?: string) {
        this.gqlClientManager.resetToken(token);
        this.sdk = getSdk(this.gqlClientManager.client);
    }
}

export const dgraphGateway = new DgraphGateway(
    null,
    GRAPHQL_ENDPOINT
);

export { GRAPHQL_ENDPOINT };