import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export async function http<T>(request: AxiosRequestConfig): Promise<T> {
  const response: AxiosResponse<T> = await axios(request);
  return response.data;
}

export async function get<T>(
  url: string,
  options?: {
    userID?: string;
    mapping?: (val: any) => T;
  }
): Promise<T> {
  let response: any = null;
  if (options?.userID) {
    response = await http<T>({
      url: url,
      method: "GET",
      headers: { Authorization: `Bearer ${options.userID}` },
    });
  } else {
    response = await http<T>({
      url: url,
      method: "GET",
    });
  }

  if (options?.mapping) {
    return options.mapping(response);
  }
  return response;
}

export async function post<T>(
  url: string,
  body: any,
  userID?: any
): Promise<T> {
  return await http<T>({
    url: url,
    method: "POST",
    data: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userID}`,
    },
  });
}

export async function put<T>(url: string, body: any): Promise<T> {
  return await http<T>({
    url: url,
    method: "PUT",
    data: body,
    headers: { "Content-Type": "application/json" },
  });
}

export async function remove<T>(url: string, userID?: string): Promise<T> {
  if (userID) {
    return await http<T>({
      url: url,
      method: "DELETE",
    });
  } else {
    return await http<T>({
      url: url,
      method: "DELETE",
      headers: { authentication: userID },
    });
  }
}
