import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, LinearProgress } from '@material-ui/core';
import { useNavigate, Link } from "react-router-dom";
import { useIsFetching } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { DarkStateContext } from '../../../providers/DarkStateProvider';
import { Person } from '@material-ui/icons';
import { CONSTANTS } from '../../../constants';
import { UserContext } from '../../../providers/UserProvider';
import { calculatePotentialRewards } from '../../util';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  search: {
    // position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface NavBarProps {
  open: boolean
  handleDrawerOpen: () => void
  expandable: boolean
}

export default function NavBar({ open, handleDrawerOpen, expandable }: NavBarProps): JSX.Element {

  const { isLoading, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0()
  const { userPollResponseMap, user } = useContext(UserContext);

  const { darkState, setDarkState } = useContext(DarkStateContext)

  const isFetching = useIsFetching()
  let navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const asyncFunc = async () => {
      const tokens = await getAccessTokenSilently()
    }

    if (isAuthenticated)
      asyncFunc()
  }, [isAuthenticated])

  const potentialRewards = calculatePotentialRewards(Object.values(userPollResponseMap));

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        {expandable && <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>}
        <Typography
          onClick={() => navigate("/")}
          component="h1" variant="h6" color="inherit" noWrap className={classes.title}> Objective </Typography>

        <IconButton aria-label="delete" onClick={() => setDarkState(!darkState)}>
          {darkState ? <NightsStayIcon color="secondary" /> : <WbSunnyIcon color="secondary" />}
        </IconButton>

        {isAuthenticated ?
          <Button color="inherit" onClick={() => {
            logout({ returnTo: window.location.origin, client_id: process.env.REACT_APP_AUTH0_CLIENTID || CONSTANTS.AUTH0_CLIENTID });
          }}> Logout </Button> : <Button color="inherit" onClick={() => loginWithRedirect()}>Login</Button>
        }

        <IconButton aria-label="delete" onClick={() => navigate("/dashboard/account")}>
          <Person color="secondary" />
        </IconButton>

        <Typography style={{ fontSize: "1rem" }}>
          {user?.balance ? <strong> Balance: {user?.balance} </strong> : null} &nbsp;
          {potentialRewards ? <strong> Potential Rewards: {potentialRewards} </strong> : null}
        </Typography>

      </Toolbar>

      {isFetching ? <LinearProgress color="secondary" /> : null}
    </AppBar>
  );
}