import { useEffect, useState } from 'react';
import { Grid, IconButton, Link, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useQuery } from 'react-query';
import SportsGateway from '../../apis/sports';
import { IPoll, IOption, IPollResponse } from '../../apis/generated/react';
import { useNavigate } from 'react-router';
import EventDetails from './EventDetails';
import PollOption from './PollOption';
import { calculateOptionReward } from '../util';
import ResponseChart from './ResponseChart';

export interface PollViewProps {
  onVote: (option: IOption, pollId: IPoll) => void
  onChange: (option: IOption, response: IPollResponse) => void
  onDeleteVote: (pollResponse: IPollResponse) => void
  userResponse?: IPollResponse,
  poll: IPoll
}

function hasPollProps(poll: IPoll): boolean {
  const { competition, options } = poll

  return competition?.competitors !== undefined &&
    competition?.competitors[0]?.entity?.objectiveID !== undefined &&
    options !== undefined && options[0]?.entity?.objectiveID !== undefined
}

export default function PollView({ poll, userResponse, onDeleteVote, onChange, onVote }: PollViewProps) {

  const competition = poll?.competition;
  const options = poll?.options;

  const homeTeamId = competition?.competitors[0].home ? competition?.competitors[0]?.entity?.objectiveID : competition?.competitors[1]?.entity?.objectiveID
  const [selectedOption, setSelectedOption] = useState<IOption>(null)
  const [expanded, setExpanded] = useState<boolean>(false)

  const { data } = useQuery([competition?.espnUID], () => SportsGateway.getEventDetails(competition?.espnUID),
    {
      enabled: competition !== undefined
    })

  const navigate = useNavigate()

  const setOption = (option: IOption) => {
    if (option && !competition.completed && moment().isBefore(competition.startTime)) {
      if (userResponse) {
        if (option.objectiveID === userResponse.vote.objectiveID) {
          onDeleteVote(userResponse)
        } else {
          onChange(option, userResponse)
        }
      } else {
        onVote(option, poll)
      }

    }
  }

  useEffect(() => {
    if (userResponse) {
      setSelectedOption(userResponse.vote)
    } else {
      setSelectedOption(null)
    }
  }, [userResponse])

  const theme = useTheme();

  let homeScore = 0;
  let awayScore = 0;

  let homeRecord = '';
  let awayRecord = '';

  let homeTeam = '';
  let awayTeam = '';

  if (data?.header) {
    let homeIdx = data.header.competitions[0].competitors[0].homeAway ? 0 : 1
    homeScore = Number(data.header.competitions[0].competitors[homeIdx].score)
    awayScore = Number(data.header.competitions[0].competitors[1 - homeIdx].score)
    homeRecord = data.header.competitions[0].competitors[homeIdx].record[0].summary
    awayRecord = data.header.competitions[0].competitors[1 - homeIdx].record[0].summary
    homeTeam = data.header.competitions[0].competitors[homeIdx].team.displayName
    awayTeam = data.header.competitions[0].competitors[1 - homeIdx].team.displayName
  }

  // return null if poll is undefined or does not have valid props
  if (!poll || !hasPollProps(poll)) return null

  const winningTeam = homeScore >= awayScore ? homeTeam : awayTeam
  const noVoteOutcome = winningTeam + ' Won'
  const homeOptionIdx = options[0]?.entity?.objectiveID === homeTeamId ? 0 : 1
  const homeOption = options[homeOptionIdx]
  const awayOption = options[1 - homeOptionIdx]
  const shouldRenderBar: Boolean = Boolean(homeOption?.voteCount + awayOption?.voteCount)

  return (
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      <Grid container direction="row" alignItems="center" justify="flex-start" item xs={12} spacing={2}>

        {/* Time Item */}
        <Grid item xl={1} xs={12}>
          <Link onClick={() => navigate(`/poll/${poll.objectiveID}`)}> {moment(competition.startTime).format("dd h:mm A")}
          </Link>
          <Typography color={userResponse?.score >= 0 ? 'primary' : 'error'}>
            {userResponse?.score !== undefined && userResponse?.score !== null ? `Score: ${userResponse?.score}` : ''}
          </Typography>
        </Grid>

        {/* Vote Item 1 */}
        <Grid item xl={5} xs={12}>
          <PollOption
            reward={calculateOptionReward(poll, homeOption, selectedOption)}
            option={homeOption}
            selected={homeOption.objectiveID == selectedOption?.objectiveID}
            record={homeRecord}
            score={homeScore}
            setOption={setOption} />
        </Grid>

        {/* Vote Item 2 */}
        <Grid item xl={5} xs={12}>
          <PollOption
            reward={calculateOptionReward(poll, awayOption, selectedOption)}
            option={awayOption}
            record={awayRecord}
            score={awayScore}
            selected={awayOption.objectiveID == selectedOption?.objectiveID}
            setOption={setOption} />
        </Grid>

        <Grid item xl={1} xs={12}>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
      </Grid>


      {/* Bar Chart Item */}
      {/* {shouldRenderBar ?
        <Grid item xs={12} >

          <HSBar
            showTextDown
            style={{ width: "100%" }}
            height={10}
            id="new_id"
            fontColor="rgb(50,20,100)"
            data={[
              {
                name: homeOption?.entity?.label,
                value: homeOption?.voteCount,
                description: `${homeOption?.voteCount} Votes`,
                color: theme.palette.primary.light
              },
              {
                name: awayOption?.entity?.label,
                value: awayOption?.voteCount,
                description: `${awayOption?.voteCount} Votes`,
                color: theme.palette.secondary.light
              }
            ]}
          // onClick={e => console.log(e.bar)}
          />
        </Grid> : null} */}

      {expanded && <Grid item xs={12}>
        <EventDetails eventUid={competition.espnUID} />
      </Grid>}

    </Grid>
  )
}