import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import { ICompetition, IGetFeedQuery, IPoll, IPollResponse, IPost, useGetFeedQuery } from "../../apis/generated/react";
import { gaphqlClientManager } from "../../graphql/clientManager";
import moment from 'moment';
import FeedItemTriage from "./FeedItemTriage";
import { IArticle } from "../../apis/generated/sdk";
import { Divider } from "@material-ui/core";
import FeedFilter from "./FeedFilter";
import { IFeedFilterParams, makeFeedFilter } from "../../graphql/helpers";

export interface IFeedFilterDisabled {
  dateRange?: boolean,
  userIDs?: boolean,
  teamIDs?: boolean,
  leagueIDs?: boolean,
  competitionIDs?: boolean
}

interface FeedProps {
  disabledFilters?: IFeedFilterDisabled
  defaultFilters?: IFeedFilterParams
}

function Feed({ disabledFilters = {}, defaultFilters = {} }: FeedProps) {
  const [feedFilter, setFeedFilter] = useState<IFeedFilterParams>(defaultFilters)
  const { data, status } = useGetFeedQuery(gaphqlClientManager.client, makeFeedFilter(feedFilter))
  console.log(makeFeedFilter(feedFilter));

  let posts: IPost[] = []
  let polls: IPoll[] = []
  let prs: IPollResponse[] = []
  let articles: IArticle[] = []

  if (status === 'success') {
    prs = data.queryPollResponse as IPollResponse[]
    polls = data.queryPoll as IPoll[]
    articles = data.queryArticle as IArticle[]
    posts = data.queryPost as any
  }

  let feedItems: (IPoll | IPollResponse | IArticle | IPost | IArticle)[] = [...polls, ...prs, ...articles, ...posts]
  feedItems.sort((a, b) => {
    let aTime = a.__typename === "Article" ? a.datePublished : a.__typename === "Poll" ? a.competition.startTime : a.dateCreated
    let bTime = b.__typename === "Article" ? b.datePublished : b.__typename === "Poll" ? b.competition.startTime : b.dateCreated
    return moment(bTime).diff(moment(aTime))
  })

  return <Grid direction="column" container spacing={1}>
    <FeedFilter feedFilter={feedFilter} setFeedFilter={setFeedFilter} disabledFilters={disabledFilters} />
    <br /> <br />
    {feedItems.map(item => <Grid item xs={12} key={item.objectiveID}> <FeedItemTriage key={item.objectiveID} item={item} /> <br /> <Divider /> </Grid>)}
  </Grid>;
}

export default Feed;
