import { Avatar, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import MessageIcon from '@material-ui/icons/Message';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import HelpIcon from '@material-ui/icons/Help';
import EventIcon from '@material-ui/icons/Event';
import PollIcon from '@material-ui/icons/Poll';
import GamesIcon from '@material-ui/icons/Games';
import { IPoll, IOption, IPollResponse } from '../apis/generated/react';
import moment from 'moment';


export function getAvatar(avatar?: string, type?: string, fontSizeVal: 'default' | 'inherit' | 'large' | 'small' = 'default', style: any = {marginRight: 10}) {
    if (avatar) return <Avatar src={avatar} style={style}></Avatar>
    else if (type === 'Article') { return <DescriptionIcon fontSize={fontSizeVal} titleAccess={type} style={style}></DescriptionIcon> }
    else if (type === 'Post') { return <MessageIcon fontSize={fontSizeVal} titleAccess={type} style={style}></MessageIcon> }
    else if (type === 'Team') { return <GroupIcon fontSize={fontSizeVal} titleAccess={type} style={style}></GroupIcon> }
    else if (type === 'Poll') { return <PollIcon fontSize={fontSizeVal} titleAccess={type} style={style}></PollIcon> }
    else if (type === 'Event') { return <EventIcon fontSize={fontSizeVal} titleAccess={type} style={style}></EventIcon> }
    else if (type === 'Competition') { return <GamesIcon fontSize={fontSizeVal} titleAccess={type} style={style}></GamesIcon> }
    else if (type === 'League') { return <GroupWorkIcon fontSize={fontSizeVal} titleAccess={type} style={style}></GroupWorkIcon> }
    else if (type === 'Season') { return <DateRangeIcon fontSize={fontSizeVal} titleAccess={type} style={style}></DateRangeIcon> }
    else if (type === 'Sport') { return <DirectionsRunIcon fontSize={fontSizeVal} titleAccess={type} style={style}></DirectionsRunIcon> }
    else { return <HelpIcon fontSize={fontSizeVal} titleAccess='Unknown' style={style}></HelpIcon> }
}


export function calculateOptionReward(poll: IPoll, option: IOption, selectedOption: IOption) {
    if (poll.balance) {
        let voteCount = option.voteCount;
        const userVoted = selectedOption?.objectiveID != undefined;
        const votedForThisOption = option.objectiveID === selectedOption?.objectiveID;

        if (poll.votingOpen && ((userVoted && !votedForThisOption) || !userVoted)) { voteCount += 1; } // Increment vote count if not voted for to reflect what new vote would add to option
        let reward = voteCount ? poll.balance / voteCount : poll.balance;
        const rewardString = reward && reward % 1 === 0 ? reward.toString() : reward.toFixed(1);
        return <Typography color={reward >= 0 ? 'primary' : 'error'}>{`${reward >= 0 ? '+' : ''}${rewardString}`}</Typography>
    }
    return <Typography></Typography>
}


export function calculatePotentialRewards(prs: IPollResponse[], date?: Date): number {
    return prs.reduce((pv, cv) => {
        if (date) {
            if (moment(cv.poll.competition.startTime).days() === moment(date).days()) {
                pv += !cv.poll.pollResolved && cv.poll.balance ? cv.poll.balance / cv.vote.voteCount : 0.0;
            }
        } else {
            pv += !cv.poll.pollResolved && cv.poll.balance ? cv.poll.balance / cv.vote.voteCount : 0.0;
        }
        return pv;
    }, 0.0)
}