import { Avatar, Grid } from "@material-ui/core";
import { Navigate, useNavigate } from "react-router";
import { useGetAvatarsQuery } from "../../apis/generated/react";
import { gaphqlClientManager } from "../../graphql/clientManager";

function TeamAvatars() {
    const { data, status } = useGetAvatarsQuery(gaphqlClientManager.client, {});
    const navigate = useNavigate();

    if (status === 'success')
        return <Grid container justify="center" alignItems="center" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            {data.queryTeam.map(team => <Grid item xs={4} md={2} key={team.objectiveID}>
                <Avatar src={team.avatar} style={{ height: 'auto', width: '50%' }} onClick={() => navigate(`/team/${team.objectiveID}`)} />
            </Grid>)
            }
        </ Grid >;
    return null;
}

export default TeamAvatars;
