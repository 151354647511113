import React, { useContext } from 'react'
import { useQueryClient } from 'react-query';
import { IPoll, useAddPollResponseMutation, useChangePollResponseMutation, useDeletePollResponseMutation } from '../../apis/generated/react';
import PollView from './PollView';
import { gaphqlClientManager } from '../../graphql/clientManager';
import { UserContext } from '../../providers/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';

const themes = {
  purple: ['#6D4B94', '#7C6497', '#6D4B943B'],
  red: ['#E23D3D', '#EF4545', '#FF28283B'],
  blue: ['#5674E0', '#5674E0', '#5674E03B'],
  black: ['#303030', '#303030', '#3030303B'],
  white: ['#ffffff', '#ffffff', '#ffffff3B'],
  cyan: ['#00BCDD', '#00BCDD', '#00BCDD3B']
}

export interface PollProps {
  poll: IPoll
}

export default function Poll({ poll }: PollProps) {

  const { loginWithRedirect } = useAuth0();
  const { user, userPollResponseMap } = useContext(UserContext);
  const submitResponse = useAddPollResponseMutation(gaphqlClientManager.client)
  const updateResponse = useChangePollResponseMutation(gaphqlClientManager.client)
  const deleteResponse = useDeletePollResponseMutation(gaphqlClientManager.client)
  const queryClient = useQueryClient()

  const invalidateQueries = () => {
    queryClient.invalidateQueries([
      "GetCompetitions",
      {}
    ])
    queryClient.invalidateQueries(["GetUsers", { "filter": { "email": { "eq": user.email } } }])
  }

  if (!poll) return null

  return (
    <PollView
      poll={poll}
      userResponse={userPollResponseMap[poll.objectiveID]}
      onDeleteVote={(pr) => deleteResponse.mutate({ pollResponseID: pr.objectiveID }, {
        onSuccess: invalidateQueries
      })}
      onChange={(option, response) => updateResponse.mutate({ pollResponseID: response.objectiveID, newOptionID: option.objectiveID }, {
        onSuccess: invalidateQueries
      })
      }
      onVote={(option, poll) => {
        if (user) {
          submitResponse.mutate({ pollID: poll.objectiveID, optionID: option.objectiveID, userID: user.objectiveID }, {
            onSuccess: invalidateQueries
          });
        } else {
          loginWithRedirect();
        }
      }} />
  )
}