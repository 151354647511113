import { IAddSportInput, IAddEventInput, IEvent, IArticle } from './generated/sdk'
import { get } from './request'
import { convertTime } from '../graphql/helpers';
import { EspnEvent } from './types/espn-event';
import { espnUIDToUrlPath } from './helpers';

function makeDateParam(d: Date) {
  return `${d.getFullYear()}${(d.getMonth() + 1).toString().padStart(2, "0")}${d.getDate().toString().padStart(2, "0")}`;
}

const baseUrl = `https://site.api.espn.com/apis/site/v2/`

const SportsGateway = {
  getSport: async (sport: string, league: string): Promise<IAddSportInput> => {
    const fullUrl = `${baseUrl}sports/${sport}/${league}/teams`;

    const response: any = await get<IAddSportInput[]>(fullUrl, {});
    return response.sports.map((sport: any) => {
      return {
        espnID: sport.id,
        espnUID: sport.uid,
        label: sport.name,
        leagues: sport.leagues.map((league) => {
          return {
            espnID: league.id,
            espnUID: league.uid,
            label: league.name,
            abbreviation: league.abbreviation,
            teams: league.teams.map((team) => {
              return {
                espnID: team.team.id,
                espnUID: team.team.uid,
                label: team.team.displayName,
                shortLabel: team.team.shortDisplayName,
                abbreviation: team.team.abbreviation,
                avatar: team.team.logos[0].href
              }
            })
          }
        })
      }
    })
  },

  getSchedule: async (sport: string, league: string, date: Date = null, limit: number = 100): Promise<IAddEventInput[]> => {
    let fullUrl = `${baseUrl}sports/${sport}/${league}/scoreboard?limit=${limit}`;
    if (date) {
      fullUrl = fullUrl + `&dates=${makeDateParam(date)}`;
    }

    const response: any = await get<IAddEventInput[]>(fullUrl, {});
    const leagueData = response.leagues.filter((lge) => {
      if (lge.slug === league) {
        return lge;
      }
    })[0];
    return response.events.map((event: any) => {
      return {
        espnID: event.id,
        espnUID: event.uid,
        label: event.name,
        shortLabel: event.shortName,
        league: { 
          espnID: leagueData.id,
          espnUID: leagueData.uid,
          label: leagueData.name,
          abbreviation: leagueData.abbreviation
        },
        startTime: convertTime(event.date),
        season: {
          year: leagueData.season.year,
          type: leagueData.season.type.type,
          espnID: leagueData.season.type.id,
          espnUID: `${leagueData.uid}~y:${leagueData.season.year}`,
          label: leagueData.season.type.name,
          abbreviation: leagueData.season.type.abbreviation,
          startDate: convertTime(leagueData.season.startDate),
          endDate: convertTime(leagueData.season.endDate),
          league: { 
            espnID: leagueData.id,
            espnUID: leagueData.uid,
            label: leagueData.name,
            abbreviation: leagueData.abbreviation
          }
        },
        competitions: event.competitions.map((competition) => {
          return {
            espnID: competition.id,
            espnUID: competition.uid,
            startTime: convertTime(competition.startDate),
            label: event.name,
            shortLabel: event.shortName,
            completed: competition.status.type.name === 'STATUS_FINAL',
            competitors: competition.competitors.map((competitor) => {
              return {
                entity: {
                  espnID: competitor.id,
                  espnUID: competitor.uid,
                  label: competitor.team.displayName,
                  avatar: competitor.team.logo,
                  league: { 
                    espnID: leagueData.id,
                    espnUID: leagueData.uid,
                    label: leagueData.name,
                    abbreviation: leagueData.abbreviation
                  }
                },
                home: competitor.homeAway === 'home'
              }
            })
          }
        })
      }
    });
  },

  getEvent: async (eventUID: string): Promise<IEvent> => {
    const path = espnUIDToUrlPath(eventUID);
    const eventID = eventUID.match(/e:(\d+)/)[1];
    const fullUrl = `${baseUrl}sports/${path}/summary?event=${eventID}`;

    const response: any = await get<IEvent>(fullUrl, {});
    const header = response.header;
    return {
      objectiveID: null,
      espnID: header.id,
      espnUID: header.uid,
      startTime: null,
      label: null,
      competitions: header.competitions.map((comp) => {
        return {
          espnID: comp.id,
          espnUID: comp.uid,
          startTime: convertTime(comp.date),
          label: null,
          completed: comp.status.type.completed || false,
          competitors: comp.competitors.map((cptr) => {
            return {
              entity: {
                espnID: cptr.id,
                espnUID: cptr.uid
              },
              winner: cptr.winner
            }
          })
        }
      })
    }
  },

  getEventDetails: async (eventUID: string) => {
    const path = espnUIDToUrlPath(eventUID);
    const eventID = eventUID.match(/e:(\d+)/)[1];
    const fullUrl = `${baseUrl}sports/${path}/summary?event=${eventID}`;

    const response: EspnEvent = await get<EspnEvent>(fullUrl, {});
    const header = response.header;
    const boxscore = response.boxscore;
    const odds = response.pickcenter;
    const series = response.seasonseries;
    const news = response.news;

    return {
      boxscore,
      odds,
      series,
      news,
      header
    }
  },

  getNews: async (sport: string, league: string, limit: number = 20): Promise<IArticle[]> => {
    const fullUrl = `${baseUrl}sports/${sport}/${league}/news?limit=${limit}`;

    const response: any = await get<IArticle[]>(fullUrl, {});
    return response.articles.map((article: any) => {
      const createDates = article.categories.map((category) => { return category.createDate ? convertTime(category.createDate) : null; });
      createDates.filter((a, b) => { return b < a});
      return {
        label: article.headline,
        link: article.links.web.href,
        description: article.description,
        avatar: article.images[0].url ? article.images[0].url : null,
        tags: article.categories.map((category) => {
          if (["league", "team"].includes(category.type) && category.uid) {
            return {
              objectiveID: category.uid,
              label: category.type
            }
          }
        }).filter((tag) => { if (tag) { return tag; }}),
        datePublished: createDates.length > 0 ? createDates[0] : null
      }
    })
  }
};

export default SportsGateway;
