import { Avatar, Button, CircularProgress, Divider, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IIEntityRef, useAddPostsMutation, useGetTagsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';
import { UserContext } from '../../providers/UserProvider';
import TagInput from './TagInput';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 10,
    },
}));
export default function AddPost() {
    const queryClient = useQueryClient()

    const addPost = useAddPostsMutation(gaphqlClientManager.client, {
        onSuccess: () => {
            queryClient.invalidateQueries(["GetFeed"])
        }
    })

    const classes = useStyles()

    const [postContent, setPostContent] = useState<string>('')
    const [tagIds, setTagIds] = useState<IIEntityRef[]>([])

    const { user } = useContext(UserContext)

    return (
        <Grid direction="row" container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={1}>
                <Avatar src={user?.avatar} style={{ width: '60%', height: "auto", margin: 'auto' }} />
            </Grid>
            <Grid item xs={11} md={5}>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="standard-basic" label="Create Post"
                    value={postContent}
                    onChange={(e) => setPostContent(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={5}>
                <TagInput onTagsChange={setTagIds} />
            </Grid>

            <Grid item xs={12} md={1}>
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (postContent) {
                            let posts = {
                                posts: [{
                                    dateCreated: new Date(),
                                    content: postContent,
                                    author: user,
                                    tags: tagIds
                                }]
                            }

                            addPost.mutate(posts, {
                                onSuccess: () => {
                                    setPostContent('')
                                    queryClient.invalidateQueries(["GetPosts", { "filter": {} }])
                                }
                            })
                        }
                    }}>
                    Post
            </Button>
            </Grid>



            {/*

            <TagInput onTagsChange={setTagIds} />

            <Divider />

            <Button disabled={postContent === ''}
                fullWidth
                onClick={() => {
                    let posts = {
                        posts: [{
                            dateCreated: new Date(),
                            content: postContent,
                            author: user,
                            tags: tagIds
                        }]
                    }

                    addPost.mutate(posts, {
                        onSuccess: () => {
                            setPostContent('')
                            queryClient.invalidateQueries(["GetPosts", { "filter": {} }])
                        }
                    })
                }}>
                Add Your Take +
            </Button> */}
        </Grid>
    );
}
