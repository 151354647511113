import React, { useContext } from "react";
import { IPost } from "../../../apis/generated/react";
import FeedItem from "./FeedItem";

interface PostFeedItemProps {
  post: IPost
}

function PostFeedItem({ post }: PostFeedItemProps) {
  return <FeedItem
    type={post.__typename}
    tags={post.tags.map((tag: any) => ({ label: tag.label, avatar: tag.avatar, link: `/${tag.__typename}/${tag.objectiveID}` }))}
    title={{ label: post.author.username, link: { type: 'internal', href: `/user/${post.author.objectiveID}` } }}
    item={<>
      {post.content}
    </>} dateTime={post.dateCreated} avatar={{ src: post.author.avatar, link: `/user/${post.author.objectiveID}` }} />
}

export default PostFeedItem;
