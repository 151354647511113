import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gaphqlClientManager } from '../../../graphql/clientManager';
import { ICompetition, useGetCompetitionsQuery } from '../../../apis/generated/react';
import moment from 'moment';
// import { ICompetition, IIEntityRef, ITeam, useGetTagsQuery } from '../../apis/generated/react';
// import { gaphqlClientManager } from '../../graphql/clientManager';

interface CompetitionFilterProps {
  setCompetitionIds: (competitionIds: string[]) => void
  competitionIds: string[]
}

export default function CompetitionFilter({ setCompetitionIds, competitionIds }: CompetitionFilterProps) {

  const [query, setQuery] = useState<string>('')

  const { data, status } = useGetCompetitionsQuery(gaphqlClientManager.client, {
    filter: { label: { regexp: `/.*${query}.*/i` } }
  })

  let competitions: ICompetition[] = []
  if (status === 'success') {
    competitions = data.queryCompetition as ICompetition[]
  }

  const handleChange = (competitions: (string | ICompetition)[]) => {
    const competitionIds: string[] = []

    competitions.forEach(competition => {
      if (typeof competition !== 'string') {
        competitionIds.push(competition.objectiveID)
      } else {
        competitionIds.push(competition)
      }
    })

    setCompetitionIds(competitionIds)
  }

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="tags-standard"
        options={competitions}
        onChange={(e, values) => handleChange(values)}
        getOptionLabel={(option) => `${option.label}: ${moment(option.startTime).calendar()}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Competition Filter"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      />
    </div>
  );
}