import './App.css';
import { Auth0Provider } from "@auth0/auth0-react";
import UserProvider from './providers/UserProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ApplicationLayout from './components/Layout/ApplicationLayout';
import Dashboard from './components/Dashboard';
import Landing from './components/Landing';
import DarkStateProvider from './providers/DarkStateProvider';
import { CONSTANTS } from './constants';
import ApplicationRouter from './components/ApplicationRouter';

function App() {

  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || CONSTANTS.AUTH0_DOMAIN
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID || CONSTANTS.AUTH0_CLIENTID

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
    >
      <UserProvider>
        <BrowserRouter>
          <DarkStateProvider>
            <ApplicationLayout>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/*" element={<ApplicationRouter />} />
                {/* <Route path="/dashboard" element={<Dashboard />}/> */}
                {/* <Route path="/dashboard/*" element={<Dashboard />}/> */}
              </Routes>
            </ApplicationLayout>
          </DarkStateProvider>
        </BrowserRouter>
      </UserProvider>
    </Auth0Provider>
  );
}

export default App;
