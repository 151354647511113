import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { Avatar, Box, Chip, createStyles, Link, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }),
);

interface FeedItemProps {
  item: React.ReactNode
  dateTime: string
  avatar: { src: string, link?: string }
  // avatar2?: { src: string, link: string }
  type: string
  title: { label: string, link: { type: 'external' | 'internal', href: string } }
  tags: { label: string, link: string, avatar?: string }[]
}

function FeedItem({ item, dateTime, avatar, title, tags, type }: FeedItemProps) {
  const navigate = useNavigate();
  const classes = useStyles();

  return <Grid direction="column" container spacing={1}>
    <Grid item xs={12} direction="row" container spacing={2}>
      <Grid item xs={1}>
        <Link onClick={() => { if (avatar.link) navigate(avatar.link) }}> <Avatar src={avatar.src} /></Link>
        {/* {avatar2 && <Link onClick={() => navigate(avatar2.link)}> <Avatar src={avatar2.src} /></Link>} */}
      </Grid>
      <Grid item>
        {type}
      </Grid>
      <Grid item>
        {title.link.type === "external" ?
          <Link href={title.link.href} target="_blank"> {title.label} </Link>
          : <Link onClick={() => navigate(title.link.href)}> {title.label} </Link>
        }
      </Grid>
      <Grid item>
        {moment(dateTime).fromNow()}
      </Grid>
    </Grid>
    <Grid item xs={12} direction="row" container>
      <Grid item xs={1}>
        {/* <Avatar /> */}
      </Grid>
      <Grid item xs={11}>
        <Box>
          {item}
        </Box>
      </Grid>
      {/* {item} */}
    </Grid>
    <Grid item xs={12} direction="row" container>
      <Grid item xs={1}>
        {/* <Avatar /> */}
      </Grid>
      <Grid item xs={11}>
        <div className={classes.chips}>
          {tags.map((tag, i) => <Chip key={i} avatar={<Avatar src={tag.avatar} />} label={tag.label} onClick={() => navigate(tag.link)} />)}
        </div>
      </Grid>
    </Grid>
  </Grid>;
}

export default FeedItem;
