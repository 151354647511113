import { Grid } from '@material-ui/core';
import { useGetPostsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';
import moment from 'moment';
import { useNavigate } from "react-router";
import Post from './PostView';

export default function PostsContainer() {

  const navigate = useNavigate()
  const { data, status } = useGetPostsQuery(gaphqlClientManager.client, {
    filter: {}
  })

  // const { data: feed } = useGetFeedQuery(gaphqlClientManager.client)

  let posts = [];
  if (status === 'success') {
    posts = data.queryPost;
  }

  posts = posts.sort((a, b) => moment(b.dateCreated).diff(moment(a.dateCreated)))

  return (

    <>
      <Grid direction="column" spacing={4}>
        {posts.map((post, i) => <Post post={post} />)}
      </Grid>
    </>
  );
}

