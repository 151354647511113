import { useLocation, useNavigate } from "react-router";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Leaderboard from "../Leaderboard";
import { useAuth0 } from "@auth0/auth0-react";
import Hero from "./Hero";
import SplitContainer from "./SplitContainer";
import TextContainer from "./TextContainer";
import ContainerWithActionButton from "./ContainerWithActionButton";
import FeaturedPolls from "./FeaturedPolls";
import Tokens from '../../assets/coins.svg';
import Discord from '../../assets/discord.png';
import TeamAvatars from "./TeamAvatars";
import { SiDiscord } from "react-icons/si"
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Landing() {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0()
  const navigate = useNavigate()
  const query = useQuery();
  const source = query.get("source");
  const referrer = query.get("ref");
  const note = query.get("note");

  useEffect(() => {
    if (source || referrer) {
      navigate("/");
    }
  }, [source, referrer])

  return <>
    {/* Hero unit */}
    <Hero />

    {/* How Does it Work? */}
    <SplitContainer
      left={<TextContainer
        title={"How Does it Work?"}
        description={"Objective Sports enables people from across the world to earn tokens for sports predictions, create and post analysis, and banter with other sports fanatics."}
      />}
      right={<ContainerWithActionButton
        main={<Leaderboard />}
        button={<Button
          onClick={() => loginWithRedirect()}
          color="primary"
          variant="contained">
          Create Account
        </Button>} />}
    />

    {/* How do I earn tokens? */}
    <SplitContainer
      left={<TextContainer
        title={"How do I earn tokens?"}
        description={"Each poll is seeded with 10 tokens. Those tokens will be split among the analysts with the correct prediction."}
      />}
      right={<ContainerWithActionButton
        main={<FeaturedPolls />}
        button={<Button
          onClick={() => loginWithRedirect()}
          color="primary"
          variant="contained">
          Create Account
        </Button>} />}
    />

    {/* What are tokens? */}
    <SplitContainer
      left={<TextContainer
        title={"What are Objective Tokens?"}
        description={`Objective is in the process of developing a crypto-based token for sports applications to make wagering and earning & rewarding for analysis easier and more decentralized.
        To grow the community Objective is giving token “vouchers” for first adopters who will be able to exchange for real tokens when they are ready.`}
      />}
      right={<ContainerWithActionButton
        main={<img src={Tokens} />}
        button={<Button
          onClick={() => loginWithRedirect()}
          color="primary"
          variant="contained">
          Create Account
        </Button>} />}
    />

    {/* What are tokens? */}
    <SplitContainer
      left={<TextContainer
        title={"Objective Analysis"}
        description={`Objective strives to provide the best possible environment for sports analysis. Keep up to date with the latest news for your teams, make posts to debate and give & receive tips from other analysts. Look for analysts who make good content and even better predictions!`}
      />}
      right={<TeamAvatars />}
    />

    {/* What are tokens? */}
    <SplitContainer
      left={<TextContainer
        title={"Help Us Grow and Improve"}
        description={`Have ideas to improve Objective Sports? Notice any bugs? Want to get involved in building the platform? Hop on over to our Discord and let us know what you have in mind!`}
      />}
      right={<ContainerWithActionButton
        main={<SiDiscord style={{ fontSize: "20rem" }} />}
        button={<Button
          variant="contained"
          onClick={() => window.open("https://discord.gg/XKpRfJcs", "_blank")}
          style={{ fontSize: '1.5rem' }} color="primary">
          Join The Discord
        </Button>
        }
      />} />
  </>;
}

export default Landing;
