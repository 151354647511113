import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import moment from 'moment';
import { Button, MenuItem, Select } from '@material-ui/core';
import { ILeague } from '../../apis/generated/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    }
  }),
);


interface LeagueSelectProps {
  league: string
  leagues: ILeague[]
  setLeague: (league: string) => void
}

export default function LeagueSelect({ league, leagues, setLeague }: LeagueSelectProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Select
        fullWidth
        variant="outlined"
        value={league}
        onChange={(e) => setLeague(e.target.value as string)}
      >

        {leagues.map((d, i) => (
          <MenuItem value={d.objectiveID}> {d.label}</MenuItem>
        ))}


      </Select>

    </div>
  );
}