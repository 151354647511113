import { ListItem, ListItemText, Typography, List, Divider, Chip, Grid, Avatar, makeStyles, Link } from '@material-ui/core';
import React from 'react';
import { IPost, useGetPostsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';
import moment from 'moment';
import AddPost from './AddPost';
import { getAvatar } from '../util';
import { useNavigate } from "react-router";

interface PostProps {
    post: IPost
}

const useStyles = makeStyles((theme) => ({
    post: {
        paddingTop: theme.spacing(4),
    },
}));

export default function Post({ post }: PostProps) {

    const classes = useStyles();
    const navigate = useNavigate()
    // const { data, status } = useGetPostsQuery(gaphqlClientManager.client, {
    //     filter: {}
    // })

    // // const { data: feed } = useGetFeedQuery(gaphqlClientManager.client)

    // let posts = [];
    // if (status === 'success') {
    //     posts = data.queryPost;
    // }

    // posts = posts.sort((a, b) => moment(b.dateCreated).diff(moment(a.dateCreated)))

    if (!post) return null

    return (
        <>
            <br />
            <Grid container direction="row" className={classes.post} spacing={3} alignItems="center">
                <Grid item xs={1}>
                    <Link onClick={() => navigate(`/user/${post?.author?.objectiveID}`)}>
                        <Avatar src={post?.author?.avatar} style={{ width: "80%", height: "auto", float: "right" }} />
                    </Link>
                </Grid>

                <Grid item xs={2}>
                    <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: "1.2rem" }}
                    >
                        <Link onClick={() => navigate(`/user/${post?.author?.objectiveID}`)}>
                            {post?.author?.username}
                        </Link>
                        &nbsp;
                        <strong>{post?.author?.balance}</strong>
                    </Typography>
                </Grid>

                <Grid item>
                    &nbsp;

                    <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                    >
                        {post.content}

                    </Typography>
                </Grid>



                <Grid item >
                    &nbsp;
                    {post?.tags?.map((tag: any, i) => {
                    if (tag.objectiveID) {
                        return <Chip key={i} avatar={getAvatar(tag.avatar, tag.type)} label={tag.label} onClick={() => navigate(`/${tag.__typename.toLowerCase()}/${tag.objectiveID}`)} />
                    }
                })}

                </Grid>
            </Grid>

            <Divider variant="fullWidth" />

        </>
        // <ListItem key={post.objectiveID} alignItems="flex-start">
        //     <ListItemText
        // primary={post?.author?.username}
        //         secondary={
        //             <React.Fragment>
        // <Typography
        //     component="span"
        //     variant="body2"
        //     color="textPrimary"
        // >
        //     {post.content}

        //     <br />
        //     {post?.tags?.map((tag: any, i) => {
        //         if (tag.objectiveID) {
        //             return <Chip key={i} avatar={getAvatar(tag.avatar, tag.type)} label={tag.label} onClick={() => navigate(`/${tag.__typename.toLowerCase()}/${tag.objectiveID}`)} />
        //         }
        //     })}
        // </Typography>

        //                 <br />

        //                 {moment(post.dateCreated).format("dddd, h:mm A")}

        //             </React.Fragment>
        //         }
        //     />

        // </ListItem>
    );
}

