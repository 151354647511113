import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LANDING_CONFIG } from "./LandingData";
import ObjectiveTextLoop from "./TextLoop";
import Leaderboard from "../Leaderboard";
import { Divider } from "@material-ui/core";
import { UserContext } from "../../providers/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetLandingStatsQuery } from "../../apis/generated/react";
import { gaphqlClientManager } from "../../graphql/clientManager";
import IconStat from "./IconStat";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import PollIcon from '@material-ui/icons/Poll';
import { BsTrophy } from 'react-icons/bs';
import { GiToken } from 'react-icons/gi';
import { FaVoteYea } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

function Hero() {
    const classes = useStyles();
    const { user } = useContext(UserContext)
    const { loginWithRedirect } = useAuth0()

    const navigate = useNavigate()

    const openPreview = () => {
        loginWithRedirect()
    };

    const { data } = useGetLandingStatsQuery(gaphqlClientManager.client, {});

    return <div className={classes.heroContent}>
        <Container maxWidth="xl">
            <Grid direction="row" container spacing={5}>
                <Grid item xs={12} direction="row" container alignItems="center">
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h2" align="center" gutterBottom>
                            <strong>{LANDING_CONFIG.heading}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h3" variant="h4" align="center" gutterBottom>
                            {LANDING_CONFIG.subHeading}
                        </Typography>
                    </Grid>
                </Grid>

                {data && <Grid item xs={12} container direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={6}>
                        <IconStat
                            amount={Math.round(data?.tokensRewarded?.amountSum)?.toString()}
                            label="Tokens Rewarded"
                            icon={<BsTrophy style={{ fontSize: '3rem' }} />}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <IconStat
                            amount={Math.round(data?.openPolls?.count)?.toString()}
                            label="Open Polls"
                            icon={<PollIcon style={{ fontSize: '3rem' }} />}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <IconStat
                            amount={Math.round(data.tokensMinted.amountSum - data.tokensRewarded.amountSum).toString()}
                            label="Tokens Up for Grabs"
                            icon={<GiToken style={{ fontSize: '3rem' }} />}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <IconStat
                            amount={data.polls.totalVotesSum.toString()}
                            label="Votes"
                            icon={<FaVoteYea style={{ fontSize: '3rem' }} />}
                        />
                    </Grid>

                    {user ? <Button variant="contained" color="primary" onClick={() => navigate("/events")}>
                        <Typography style={{ fontSize: '1.8rem' }} >Start Voting Now</Typography>
                    </Button> : <Button
                        onClick={() => loginWithRedirect()}
                        color="primary"
                        variant="contained">
                        <Typography style={{ fontSize: '1.5rem' }} > Create Account </Typography>
                    </Button>}
                    {/* <a href="https://discord.gg/XKpRfJcs" target="_blank"> Join The Discord  </a> */}
                </Grid>}
            </Grid>
        </Container>
    </div>;
}

export default Hero;
