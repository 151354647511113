import { Navigate, Route, Routes } from 'react-router-dom';
import Analysts from '../Analysts';
import EventPollContainer from '../EventPolls';
import MaterialUIContainer from '../Layout/MaterialUIContainer';
import LeagueContainer from '../League';
import PollContainer from '../Poll/PollConatiner';
import PostsContainer from '../Posts/PostsContainer';
import TeamContainer from '../Team';
import UserContainer from '../User';
import FeedContainer from '../Feed';
import AddPost from '../Posts/AddPost';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';

function ApplicationRouter() {
  const { user } = useContext(UserContext);
  return (
    <>
      {user && <MaterialUIContainer height={200}>
        <AddPost />
      </MaterialUIContainer>}

      <MaterialUIContainer height={1000}>
        <Routes>
          <Route path="/user/*" element={<UserContainer />} />
          <Route path="/team/*" element={<TeamContainer />} />
          <Route path="/poll/*" element={<PollContainer />} />
          <Route path="/league/*" element={<LeagueContainer />} />
          <Route path="/analysts" element={<Analysts />} />
          <Route path="/analysts/*" element={<Analysts />} />
          <Route path="/feed" element={<FeedContainer />} />
          <Route path="/takes" element={<PostsContainer />} />
          <Route path="/events" element={<EventPollContainer />} />
        </Routes>
      </MaterialUIContainer>
    </>
  )
}

export default ApplicationRouter;