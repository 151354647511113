import { useParams } from 'react-router';
import { useGetPollsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';

export default function PollContainer() {

    const params = useParams()
    const id = params["*"]
    const { data, status } = useGetPollsQuery(gaphqlClientManager.client, {
        filter: { objectiveID: [id] }
    })

    return (<>
        <h1> These are not the drones you're looking for. </h1>
        {JSON.stringify(data)}
    </>
    );
}