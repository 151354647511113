import { GraphQLClient } from 'graphql-request';
import { CONSTANTS } from '../constants';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || CONSTANTS.GRAPHQL_ENDPOINT;
const DGRAPH_CLIENT_KEY = process.env.REACT_APP_DGRAPH_CLIENT_KEY || CONSTANTS.DGRAPH_CLIENT_KEY;

function makeClient(endpoint: string, token?: string) {
    return new GraphQLClient(
        endpoint,
        { headers: token ? { "Authorization": token, "Dg-Auth": DGRAPH_CLIENT_KEY } : { "Dg-Auth": DGRAPH_CLIENT_KEY } }
    )
}

class GraphqlClientManager {
    token: string;
    endpoint: string;
    client: GraphQLClient;

    constructor (token: string, endpoint: string) {
        this.endpoint = endpoint;
        this.resetToken(token);
    }

    resetToken(token?: string) {
        this.token = token;
        this.client = makeClient(this.endpoint, this.token);
    }
}

export const gaphqlClientManager = new GraphqlClientManager(
    null,
    GRAPHQL_ENDPOINT
);

export { GraphqlClientManager };
