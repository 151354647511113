import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IIEntityRef, useGetTagsQuery } from '../../apis/generated/react';
import { gaphqlClientManager } from '../../graphql/clientManager';
import { getAvatar } from '../util';

interface TagInputProps {
  onTagsChange: (tagIds: IIEntityRef[]) => void
}

interface TagOption { title: string, id: string, type: string, avatar: string }

export default function TagInput({ onTagsChange }: TagInputProps) {

  const [query, setQuery] = useState<string>('')

  const { data, status } = useGetTagsQuery(gaphqlClientManager.client, {
    query: `/.*${query}.*/i`
  })

  let tags: TagOption[] = []
  let tagMap: { [id: string]: IIEntityRef } = {}
  if (status === 'success') {
    data.queryIEntity.forEach(val => {
      tags.push({ title: val.label, id: val.objectiveID, type: val.__typename, avatar: val.avatar })
      tagMap[val.objectiveID] = { objectiveID: val.objectiveID }
    })
  }


  const handleTagChange = (tags: (string | { title: string, id: string, type: string })[]) => {
    const tagIds: IIEntityRef[] = []

    tags.forEach(tag => {
      if (typeof tag !== 'string') {
        if (tagMap[tag.id]) {
          tagIds.push(tagMap[tag.id])
        }
      }
    })

    onTagsChange(tagIds)
  }

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="tags-standard"
        options={tags}
        onChange={(e, values) => handleTagChange(values)}
        getOptionLabel={(option) => `${option.type}: ${option.title}`}
        renderOption={(option) => (
          <React.Fragment>
            { getAvatar(option.avatar, option.type, 'large')}
            {`  ${option.title}`}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tag Your Take"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      />
    </div>
  );
}