import { Grid, List, ListItemText, ListItem, Avatar, Typography, Divider } from '@material-ui/core';
import React from 'react';
import { IUser } from "../../apis/generated/sdk";
import Scorebar from './Scorebar';
import moment from 'moment'
import Feed from '../Feed';
interface AnalystProps {
    user: IUser
    showScore: boolean
}

export default function Analyst({ user, showScore }: AnalystProps) {
    console.log(user);
    return (
        <Grid direction="column" container spacing={3}>
            <Grid item container direction="row" xs={12} alignItems="center" spacing={3}>
                <Grid item>
                    <Avatar src={user.avatar} />
                </Grid>
                <Grid item>
                    <Typography variant="h4"> {user?.username} </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {showScore && <Scorebar user={user} />}
            </Grid>
            <Feed
                disabledFilters={{ userIDs: true }}
                defaultFilters={{ userIDs: [user?.objectiveID] }}
            />
            {/* <List>
                {user?.pollResponses.map(pr => <ListItem key={pr.objectiveID}>
                    <ListItemText primary={pr?.poll?.label} secondary={pr?.vote?.entity?.label} />
                    {moment(pr.dateCreated).calendar()}
                </ListItem>)}
            </List> */}
        </Grid>
    );
}