import React, { useContext } from 'react'
import { useQueryClient } from 'react-query';
import { IPoll, useAddPollResponseMutation, useChangePollResponseMutation, useDeletePollResponseMutation, useGetPollsQuery } from '../../apis/generated/react';
import PollView from './PollView';
import { gaphqlClientManager } from '../../graphql/clientManager';
import { UserContext } from '../../providers/UserProvider';
import { ICompetition } from '../../apis/generated/sdk';
import Poll from '.';

export interface CompetitionPollProps {
    competition: ICompetition
}

export default function CompetitionPoll({ competition }: CompetitionPollProps) {

    const { data, status } = useGetPollsQuery(gaphqlClientManager.client, {
        filter: { objectiveID: competition.polls.map(poll => poll.objectiveID) }
    })

    let poll: IPoll = null;
    if (status !== 'success') {
        return null
    } else {
        if (data.queryPoll.length > 0) {
            poll = data.queryPoll[0] as IPoll;
        }
    }

    return (
        <Poll poll={poll} />
    )
}