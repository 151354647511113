import { Grid } from '@material-ui/core';
import { IUser } from '../../apis/generated/react';
import MaterialUIContainer from '../Layout/MaterialUIContainer';

interface ScorebarProps {
  user: IUser
}

export default function Scorebar({ user }: ScorebarProps) {
  return (
    <Grid direction="row" spacing={3} justify="center" container>
      <Grid item xs={2}>
        <strong> Score </strong>
        <br />
        {user?.balance}
      </Grid>
      <Grid item xs={2}>
        <strong> % Correct </strong>
        <br />
        {user.totalVotes != 0 ? (100 * user?.correctVotes / user?.totalVotes).toFixed(0) + "%" : ""}
      </Grid>
      <Grid item xs={2}>
        <strong> Total Correct </strong>
        <br />
        {user?.correctVotes}
      </Grid>

      <Grid item xs={2}>
        <strong> Total Votes </strong>
        <br />
        {user?.totalVotes}
      </Grid>
    </Grid>
  );
}