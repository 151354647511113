import Grid from "@material-ui/core/Grid";
import { DateRange } from "materialui-daterange-picker";
import { IFeedFilterParams } from "../../graphql/helpers";
import UserFilter from "./Filters/UserFilter";
import TeamFilter from "./Filters/TeamFilter";
import LeagueFilter from "./Filters/LeagueFilter";
import CompetitionFilter from "./Filters/CompetitionFilter";
import DateRangeFilter from "./Filters/DateRangeFilter";
import { IFeedFilterDisabled } from "./Feed";
import React, { useState } from "react";
import Collapse from '@material-ui/core/Collapse';
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface FeedFilterProps {
    feedFilter: IFeedFilterParams
    setFeedFilter: (filter: IFeedFilterParams) => void
    disabledFilters?: IFeedFilterDisabled
}

function FeedFilter({ feedFilter, setFeedFilter, disabledFilters = {} }: FeedFilterProps) {

    const [open, setOpen] = useState(false);

    const handleDateChange = (dr: DateRange) => {
        const newFilter = { ...feedFilter }

        newFilter.dateRange = { min: dr.startDate, max: dr.endDate }
        setFeedFilter(newFilter)
    }

    const handleUserChange = (users: string[]) => {
        const newFilter = { ...feedFilter }
        newFilter.userIDs = users
        setFeedFilter(newFilter)
    }

    const handleTeamChange = (teams: string[]) => {
        const newFilter = { ...feedFilter }
        newFilter.teamIDs = teams
        setFeedFilter(newFilter)
    }

    const handleLeagueChange = (leagues: string[]) => {
        const newFilter = { ...feedFilter }
        newFilter.leagueIDs = leagues
        setFeedFilter(newFilter)
    }

    const handleCompChange = (comps: string[]) => {
        const newFilter = { ...feedFilter }
        newFilter.competitionIDs = comps
        setFeedFilter(newFilter)
    }

    return (
        <>
            {!open && <Button fullWidth onClick={() => setOpen(true)} variant="outlined"> Filter Feed </Button>}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid direction="column" container spacing={2} justify="flex-end">
                    <Grid item xs={12}>
                        <Button fullWidth onClick={() => setOpen(false)} variant="outlined"> Close Filter </Button>                        </Grid>


                    {!disabledFilters?.userIDs &&
                        <Grid item xs={12}>
                            <UserFilter setUserIds={handleUserChange} userIds={feedFilter.userIDs} />
                        </Grid>
                    }

                    {!disabledFilters?.teamIDs &&
                        <Grid item xs={12}>
                            <TeamFilter setTeamIds={handleTeamChange} teamIds={feedFilter.teamIDs} />
                        </Grid>
                    }

                    {!disabledFilters?.leagueIDs &&
                        <Grid item xs={12}>
                            <LeagueFilter setLeagueIDs={handleLeagueChange} leagueIDs={feedFilter.leagueIDs} />
                        </Grid>
                    }

                    {!disabledFilters?.competitionIDs &&
                        <Grid item xs={12}>
                            <CompetitionFilter setCompetitionIds={handleCompChange} competitionIds={feedFilter.competitionIDs} />
                        </Grid>
                    }
                    {!disabledFilters?.dateRange &&
                        <Grid item xs={12}>
                            <DateRangeFilter dateRange={{}} setDateRange={handleDateChange} />
                        </Grid>
                    }
                </Grid>
            </Collapse>

        </>)

}

export default FeedFilter;
